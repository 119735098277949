import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import { useDispatch, useSelector } from "react-redux";

import supabase from "../../../../supabase";
import {
  useSetupWebhookMutation,
  useValidateTokenMutation,
} from "../../../../store/api";
import { setOnboarding } from "../../../../store/slices/onboardingSlice";

import { Title } from "../../../../components/Title";
import { Input } from "../../../../components/Input";
import { Button } from "../../../../components/Button";
import { Loader } from "../../../../components/Loader";

import styles from "./Telegram.module.scss";

export const Telegram = ({ channelData, fetchChannels, assistantId }) => {
  const channel = channelData[0];

  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const step = useSelector((state) => state.onboarding.step);

  const [token, setToken] = useState("");
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [isCheck, setIsCheck] = useState(false);
  const [savedToken, setSavedToken] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [botUsername, setBotUsername] = useState("");

  const [validateToken] = useValidateTokenMutation();
  const [setupWebhook] = useSetupWebhookMutation();

  const handleChange = async () => {
    try {
      setIsLoading(true);
      if (!isCheck) {
        await setupChannelWebhook("Telegram");
      } else {
        const { error } = await supabase
          .from("channels")
          .update({ is_on: false })
          .match({
            account_id: user.account_id,
            communication_channel: "Telegram",
          });
        if (error) {
          throw new Error("Failed to turn off channel");
        }
      }
      setIsCheck(!isCheck);
    } catch (error) {
      setErrorText(error.message);
    } finally {
      await fetchChannels();
      setIsLoading(false);
    }
  };

  const validateTelegramToken = (token) => {
    const parts = token.split(":");
    if (parts.length !== 2) {
      return false;
    }

    if (!/^\d+$/.test(parts[0]) || parts[0].length !== 10) {
      return false;
    }

    if (!/^[A-Za-z0-9_-]+$/.test(parts[1]) || parts[1].length !== 35) {
      return false;
    }

    return true;
  };

  const handleTelegramChange = (e) => {
    const token = e.target.value;
    if (!validateTelegramToken(token)) {
      setIsError(true);
      setErrorText("Invalid token");
    } else {
      setIsError(false);
      setErrorText("");
    }
    if (!token) {
      setIsError(false);
      setErrorText("");
    }
    setToken(token);
  };

  const validateAndSetToken = async () => {
    const { data, error } = await validateToken({
      platform: "Telegram",
      token: token,
    });
    if (error) {
      throw new Error("Failed to validate token");
    }
    if (data.status === "error") {
      throw new Error("Invalid access token");
    }
  };

  const setupChannelWebhook = async (channel) => {
    const parts = token.split(":");
    const pageId = parts[0];
    const { error } = await setupWebhook({
      channel: channel,
      account_id: user.account_id,
      page_id: pageId,
    });
    if (error) {
      throw new Error("Failed to setup webhook");
    }
  };

  const handleToken = async () => {
    try {
      setIsError(false);
      setErrorText("");
      setIsLoading(true);
      await validateAndSetToken();

      // Fetch bot details using getMe API
      await fetchBotDetails(token);

      if (!savedToken) {
        // split access token by : and get page id
        const parts = token.split(":");
        const pageId = parts[0];
        const { error } = await supabase.from("channels").upsert([
          {
            account_id: user.account_id,
            assistant_id: assistantId,
            access_token: token,
            page_id: pageId,
            is_on: false,
            communication_channel: "Telegram",
            metadata: { username: botUsername },
          },
        ]);
        if (error) {
          throw new Error("Failed to insert new channel");
        } else {
          if (step === 4) {
            const { error } = await supabase
              .from("onboardings")
              .update({ step: 5 })
              .match({ account_id: user.account_id });
            if (error) {
              throw new Error("Failed to update data");
            }
            dispatch(setOnboarding(5));
          }
        }
        setSavedToken(token);
      } else {
        const { error } = await supabase
          .from("channels")
          .update({
            access_token: token,
            is_on: false,
            metadata: { username: botUsername },
          })
          .match({
            account_id: user.account_id,
            communication_channel: "Telegram",
          });
        if (error) {
          throw new Error("Failed to update channel");
        }
      }
      await fetchChannels();
    } catch (error) {
      setErrorText(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsCheck(channel?.is_on ?? false);
    setToken(channel?.access_token ?? "");
    setSavedToken(channel?.access_token ?? "");
    setBotUsername(channel?.metadata?.username ?? "");
  }, [channel]);

  useEffect(() => {
    if (token !== savedToken) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [token, savedToken]);

  const fetchBotDetails = async (accessToken) => {
    try {
      const botDetailsResponse = await fetch(
        `https://api.telegram.org/bot${accessToken}/getMe`
      );
      const botDetails = await botDetailsResponse.json();
      if (!botDetails.ok) {
        throw new Error("Failed to get bot details");
      }
      setBotUsername(botDetails.result.username);
    } catch (error) {
      setErrorText(error.message);
    }
  };

  // Fetch bot details if the saved token exists but botUsername is not set
  useEffect(() => {
    if (savedToken && !botUsername) {
      fetchBotDetails(savedToken);
    }
  }, [savedToken, botUsername]);

  // Add a handler for revoke
  const handleRevoke = async () => {
    try {
      setIsLoading(true);
      const { error } = await supabase
        .from("channels")
        .delete()
        .match({
          account_id: user.account_id,
          communication_channel: "Telegram",
        });
      if (error) {
        throw new Error("Failed to delete channel");
      }
      setToken("");
      setSavedToken("");
      setBotUsername("");
      setIsCheck(false);
      await fetchChannels();
    } catch (error) {
      setErrorText(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Title title={"Telegram"} />

      <div className={styles.enableBot}>
        <Switch
          disabled={savedToken === ""}
          onChange={handleChange}
          checked={isCheck}
          uncheckedIcon={false}
          checkedIcon={false}
          height={27}
          offColor={"#E3E6EE"}
          onColor={"#3588E9"}
        />
        <div className={styles.enableBotText}>
          Connect assistant to this channel
        </div>
      </div>
      <div className={styles.connectionBot}>
        {isLoading ? (
          <div className={styles.loader}>
            <Loader height={40} width={40} />
          </div>
        ) : (
          <>
            <div className={styles.connectionBotText}>
              Create telegram bot using{" "}
              <a
                target="_blank"
                href={"https://t.me/BotFather"}
                rel="noreferrer"
              >
                @BotFather
              </a>{" "}
              and paste your access token.
            </div>
            <div className={styles.token}>Token</div>
            <Input
              typeInput={"connected"}
              placeholder={"1234567890:AAbb...Zz"}
              value={token}
              onChange={handleTelegramChange}
              error={isError}
              errorText={errorText}
            />
            {botUsername && (
              <div className={styles.botUsername}>
                <strong>Bot Username:</strong>{" "}
                <a
                  href={`https://t.me/${botUsername}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  @{botUsername}
                </a>
              </div>
            )}

            {token !== "" && (
              <div className={styles.saveChanges}>
                <Button
                  onClick={handleToken}
                  className={`${styles.saveOptimize} ${
                    isError || isDisabled ? styles.disabled : ""
                  }`}
                  title={"SAVE CHANGES"}
                  disabled={isError || isDisabled}
                />
                {savedToken && (
                  <Button
                    onClick={handleRevoke}
                    className={styles.revokeButton}
                    title={"REVOKE"}
                  />
                )}
              </div>
            )}
          </>
        )}
      </div>
    </>
  );
};
