import { createContext, useContext, useState } from "react";

const Context = createContext();

export const useChatContext = () => useContext(Context);

const ChatContextProvider = ({ children }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [isNeedUpdateChatList, setIsNeedUpdateChatList] = useState(false);
  const contextObj = {
    activeTab,
    setActiveTab,
    isNeedUpdateChatList,
    setIsNeedUpdateChatList,
  };
  return <Context.Provider value={contextObj}>{children}</Context.Provider>;
};

export default ChatContextProvider;
