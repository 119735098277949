export const GoogleSheets = ({width, height}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 94 128" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M84.8183 128H9.18184C4.38184 128 0.45459 124.073 0.45459 119.273V8.72725C0.45459 3.92725 4.38184 0 9.18184 0H61.5456L93.5456 32V119.273C93.5456 124.073 89.6183 128 84.8183 128Z"
                fill="#00AD3C"/>
            <path d="M61.5457 0L93.5457 32H61.5457V0Z" fill="#00831E"/>
            <path
                d="M17.9092 50.182V90.9093H76.0909V50.182H17.9092ZM43.3639 83.6363H25.1822V74.182H43.3639V83.6363ZM43.3639 66.9093H25.1822V57.4548H43.3639V66.9093ZM68.8184 83.6363H50.6367V74.182H68.8184V83.6363ZM68.8184 66.9093H50.6367V57.4548H68.8184V66.9093Z"
                fill="#FBFFFF"/>
        </svg>
    );
};
