export const Telegram = ({width, height}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_36_1639)">
                <path
                    d="M25 50C38.8071 50 50 38.8071 50 25C50 11.1929 38.8071 0 25 0C11.1929 0 0 11.1929 0 25C0 38.8071 11.1929 50 25 50Z"
                    fill="#039BE5"/>
                <path
                    d="M11.4394 24.4583L35.5436 15.1646C36.6623 14.7604 37.6394 15.4375 37.2769 17.1292L37.279 17.1271L33.1748 36.4625C32.8706 37.8333 32.0561 38.1667 30.9165 37.5208L24.6665 32.9146L21.6519 35.8188C21.3186 36.1521 21.0373 36.4333 20.3915 36.4333L20.8352 30.0729L32.4186 19.6083C32.9227 19.1646 32.3061 18.9146 31.6415 19.3563L17.3269 28.3688L11.1561 26.4438C9.81648 26.0188 9.78731 25.1042 11.4394 24.4583Z"
                    fill="white"/>
            </g>
            <defs>
                <clipPath id="clip0_36_1639">
                    <rect width="50" height="50" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};
