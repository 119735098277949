import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const followUpsApi = createApi({
  reducerPath: "followUpsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    credentials: "include",
  }),
  tagTypes: ["FollowUps"],
  endpoints: (builder) => ({
    getAllFollowUps: builder.query({
      query: ({ userID }) => ({
        url: `/follow-up?account=${userID}`,
        method: "GET",
        credentials: "include",
      }),
    }),
    addFollowUp: builder.mutation({
      query: (body) => ({
        url: "/follow-up",
        method: "PUT",
        credentials: "include",
        body,
      }),
    }),
    deleteFollowUp: builder.mutation({
      query: ({ id, account_id }) => ({
        url: "follow-up",
        method: "DELETE",
        body: { id, account_id },
      }),
    }),
    publishFollowUp: builder.mutation({
      query: (publishedFollowUp) => ({
        url: "follow-up",
        method: "POST",
        body: publishedFollowUp,
      }),
      invalidatesTags: ["FollowUps"],
    }),
  }),
});

export const {
  useGetAllFollowUpsQuery,
  useAddFollowUpMutation,
  useDeleteFollowUpMutation,
  usePublishFollowUpMutation,
} = followUpsApi;
