import React from 'react';
import {TailSpin} from 'react-loader-spinner';

export const Loader = ({width, height}) => {
    return (
        <TailSpin
            height={height}
            width={width}
            color={'#4F52FF'}
            ariaLabel={'tail-spin-loading'}
            radius={'1'}
            visible={true}
        />
    );
};

