import { useState, useEffect } from "react";
import Popup from "reactjs-popup";

import styles from "./AddKnowledgeBaseName.scss";
import "../../../assets/scss/modal.scss";

export const AddKnowledgeBaseName = ({
  isOpen = false,
  setIsOpen,
  saveKnowledgeBase,
} = {}) => {
  const MAXIMUM_LINE_LENGTH = 20;

  const [value, setValue] = useState("");
  const [strLen, setStrLen] = useState(MAXIMUM_LINE_LENGTH);

  const closeModal = () => {
    setIsOpen(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    saveKnowledgeBase(value);
  };

  const onInput = (e) => {
    const inputValue = e.target.value;
    const asciiValue = inputValue.replace(/[^\x00-\x7F]/g, "");
    const valueWithHyphens = asciiValue.replace(/\s+/g, "-");

    if (valueWithHyphens.length <= MAXIMUM_LINE_LENGTH) {
      setValue(valueWithHyphens.toLowerCase());
    }
  };

  useEffect(() => {
    if (isOpen) return;
    setValue("");
  }, [isOpen]);

  useEffect(() => {
    setStrLen(MAXIMUM_LINE_LENGTH - value.length);
  }, [value]);

  const contentStyle = {
    padding: 0,
    width: "fit-content",
    borderRadius: "1rem",
    border: "none",
  };
  return (
    <Popup
      open={isOpen}
      closeOnDocumentClick
      onClose={closeModal}
      {...{
        contentStyle,
      }}
    >
      <div className="modal">
        <form onSubmit={onSubmit}>
        <h2 className="modalTitle">Create Knowledge Base</h2>
        <div className="wrapperModalInput">
          <input
            type="text"
            className="modalInput"
            value={value}
            onChange={onInput}
          />
          <div className="modalInputStrLen">{strLen}</div>
        </div>
        <button type="submit" className="btn">
          Continue
        </button>
        </form>
      </div>
    </Popup>
  );
};
