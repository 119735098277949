import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import supabase from "../../supabase";

export const fetchChats = createAsyncThunk(
  "chats/fetchChats",
  async (userId, { rejectWithValue }) => {
    try {
      const { data, error } = await supabase
        .from("chats")
        .select("*")
        .eq("account_id", userId);

      if (error) throw error;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

// Set up a listener
export const listenToChatChanges = (userId) => (dispatch) => {
  const subscription = supabase
    .channel("public:chats")
    .on(
      "postgres_changes",
      {
        event: "*",
        schema: "public",
        table: "chats",
        filter: `account_id=eq.${userId}`,
      },
      () => {
        dispatch(fetchChats(userId));
      }
    )
    .subscribe();

  return () => {
    supabase.removeChannel(subscription);
  };
};

const chatsSlice = createSlice({
  name: "chats",
  initialState: {
    chats: [],
    status: "idle",
    error: null,
  },
  reducers: {
    setChats: (state, action) => {
      state.chats = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchChats.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchChats.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.chats = action.payload;
      })
      .addCase(fetchChats.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { setChats } = chatsSlice.actions;
export default chatsSlice.reducer;
