export const STATISTICS_CARD_TITLE = {
  clicks: "Clicks",
  earned: "Earned",
  invited: "Invited",
  referral_top_ups: "Referral partner purchases",
  tokens_left: "",
  tokens_sent: "",
};

export const STATISTICS_CARD_DESCRIPTIONS = {
  clicks: "Number of clicks on your link",
  earned: "The amount you have earned through the referral program",
  invited: "Number of people you have invited",
  referral_top_ups: "Amount receive from your partners",
  tokens_left: "Messages available in your account right now",
  tokens_sent: "The number of messages you sent",
};

export const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

export const IMAGE_URL_REGEX = /(https?:\/\/[^\s]+\.(jpg|jpeg|png|gif))/gi;
