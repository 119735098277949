import { useEffect } from "react";
import { pollForWaitCode } from "../../../../service/telegramService";

export const useWaitCodePolling = (polling, params, onWaitCodeResult) => {
  useEffect(() => {
    let interval;
    if (polling) {
      interval = setInterval(async () => {
        try {
          const data = await pollForWaitCode(
            params.userId,
            params.assistantId,
            params.phone,
            params.password,
            params.tgAccountName
          );

          if (data && data.length > 0) {
            const channel = data[0];

            if (channel.metadata.wait_code === true) {
              // 2FA required
              onWaitCodeResult(true, channel);

              clearInterval(interval);
            } else if (channel.metadata.wait_code === false) {
              // 2FA is not required, we start querying the authorization status
              onWaitCodeResult(false, channel);
              clearInterval(interval);
            }
          }
        } catch (error) {
          console.error("Error polling for wait_code:", error);
          clearInterval(interval);
          onWaitCodeResult("error");
        }
      }, 5000);
    }
    return () => clearInterval(interval);
  }, [polling, params, onWaitCodeResult]);
};
