import React, { useState } from "react";
import Popup from "reactjs-popup";
import { Button } from "../../Button";
import { contentStyle } from "../../../constants/popups";
import styles from "./AltegioLoginPopup.module.css";
import { Input } from "../../Input";

export const AltegioLoginPopup = ({
  isPopupOpen,
  setIsPopupOpen,
  login,
  setLogin,
  password,
  setPassword,
  handleLoginSubmit,
}) => {
  const [isSubmited, setIsSubmited] = useState(false);

  const handleSubmit = async () => {
    setIsSubmited(true);
    await handleLoginSubmit();
    setIsSubmited(false);
  };

  return (
    <Popup
      open={isPopupOpen}
      onClose={() => setIsPopupOpen(false)}
      {...{ contentStyle }}
    >
      <div className="modal">
        <h2 className="modalTitle">Login to Altegio</h2>
        <div className={styles.inputWrappper}>
          <Input
            type="text"
            placeholder="Login"
            value={login}
            className="modalInput"
            onChange={(e) => setLogin(e.target.value)}
          />
          <Input
            type="password"
            placeholder="Password"
            value={password}
            className="modalInput"
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="btns">
          <Button
            title="Close"
            className="modalBtn"
            onClick={() => setIsPopupOpen(false)}
          />
          <Button
            title="Submit"
            className="modalBtn saveBtn"
            onClick={handleSubmit}
            disabled={isSubmited}
          />
        </div>
      </div>
    </Popup>
  );
};
