import React from "react";

import { Close } from "../../assets/icons";

import styles from "./Modal.module.scss";
import classNames from "classnames";

export const Modal = ({
  isShowModal,
  setIsShowModal,
  title,
  description,
  descriptionSegments,
  actions,
  contentClassName,
  modalClassName,
  children,
}) => {
  if (!isShowModal) return null;

  const closeModal = () => {
    setIsShowModal(false);
  };

  return (
    <div className={classNames(styles.modalOpen, modalClassName)}>
      <div className={classNames(styles.modalContent, contentClassName)}>
        <div className={styles.modalHeader}>
          <h1 style={{ marginBottom: "10px" }}>{title}</h1>
          <div onClick={closeModal}>
            <Close width={20} height={20} />
          </div>
        </div>
        <p>
          {descriptionSegments &&
            descriptionSegments.map((segment, index) => (
              <React.Fragment key={index}>
                {segment}
                <br />
              </React.Fragment>
            ))}
        </p>
        {description ? <p>{description}</p> : null}
        {children}
        {actions}
      </div>
    </div>
  );
};
