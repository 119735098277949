export const GoogleSheetsLink = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 42 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M41.3121 12.389L29.2219 0.298925C29.0305 0.10754 28.771 1.37716e-05 28.5003 0L5.34833 0C2.61386 0 0.38916 2.2248 0.38916 4.95937V45.0407C0.38916 47.7752 2.61386 50 5.34843 50H36.6518C39.3863 50 41.6111 47.7753 41.6111 45.0407V13.1106C41.611 12.84 41.5034 12.5805 41.3121 12.389ZM29.5209 3.48417H29.521L38.1271 12.0902H32.4394C31.6597 12.0902 30.9267 11.7866 30.3755 11.2354C29.8245 10.6844 29.5208 9.95145 29.5209 9.17186V3.48417ZM39.5702 45.0406C39.5701 46.6499 38.261 47.9591 36.6518 47.9591H5.34833C3.73906 47.9591 2.42988 46.6499 2.42988 45.0406V4.95937C2.42998 3.35009 3.73915 2.04082 5.34833 2.04082H27.4802V9.17176C27.48 10.4965 27.9958 11.7419 28.9326 12.6785C29.8692 13.6152 31.1147 14.131 32.4395 14.131H39.5702V45.0406Z"
        fill="#384157"
      />
      <path
        d="M32.8033 20.4149H9.1936C8.63003 20.4149 8.17319 20.8718 8.17319 21.4353V35.3457C8.17319 35.9093 8.63003 36.3661 9.1936 36.3661H32.8033C33.3668 36.3661 33.8237 35.9093 33.8237 35.3457V21.4353C33.8237 20.8719 33.3667 20.4149 32.8033 20.4149ZM19.978 34.3254H10.2139V31.7304H19.978V34.3254ZM19.978 29.6896H10.2139V27.0914H19.978V29.6896ZM19.978 25.0506H10.2139V22.4556H19.978V25.0506ZM31.7829 34.3254H22.0188V31.7304H31.7829V34.3254ZM31.7829 29.6896H22.0188V27.0914H31.7829V29.6896ZM31.7829 25.0506H22.0188V22.4556H31.7829V25.0506ZM9.65034 38.3652H9.19351C8.62993 38.3652 8.1731 38.822 8.1731 39.3856C8.1731 39.9492 8.62993 40.406 9.19351 40.406H9.65034C10.2139 40.406 10.6708 39.9492 10.6708 39.3856C10.6708 38.8221 10.2139 38.3652 9.65034 38.3652ZM22.8627 38.3652H12.9514C12.3878 38.3652 11.931 38.822 11.931 39.3856C11.931 39.9492 12.3878 40.406 12.9514 40.406H22.8627C23.4263 40.406 23.8832 39.9492 23.8832 39.3856C23.8832 38.8221 23.4263 38.3652 22.8627 38.3652Z"
        fill="#384157"
      />
    </svg>
  );
};
