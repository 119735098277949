import React, { useEffect } from "react";
import styles from "./Chats.module.scss";
import { useSelector } from "react-redux";
import DesktopChatV2 from "./DesktopChat-V2";
import { selectUser } from "../../store/slices/authSlice";
// import { DesktopChat } from "./DesktopChat";

export const ChatsPage = () => {
  const user = useSelector(selectUser);

  return (
    <div className={styles.container}>
      <DesktopChatV2 />
      {/* <DesktopChat /> */}
    </div>
  );
};
