import {
  Viber,
  Instagram,
  Messenger,
  OLX as OlxIcon,
  Telegram,
} from "../../../../../../assets/icons";
import { VIBER, INSTAGRAM, FACEBOOK, OLX, TELEGRAM } from "../../const";

import styles from "./SocialLinks.module.scss";
import Switch from "react-switch";
import { Input } from "../../../../../../components";
import useCMSWidgetDataContext from "../../useCMSWidgetDataContext";

const SOCIAL_LINKS = [
  {
    name: VIBER,
    Icon: Viber,
  },
  {
    name: INSTAGRAM,
    Icon: Instagram,
  },
  {
    name: TELEGRAM,
    Icon: Telegram,
  },
  {
    name: OLX,
    Icon: OlxIcon,
  },
  {
    name: FACEBOOK,
    Icon: Messenger,
  },
];

const SocialLinks = () => {
  const { linksUrls, setLinksUrls, isDownloaded } = useCMSWidgetDataContext();

  const handleChange = (name) => (e) => {
    setLinksUrls({ ...linksUrls, [name]: e.target.value });
  };

  return SOCIAL_LINKS.map(({ name, Icon }) => {
    const linkValue = linksUrls?.[name] || "";

    return (
      <div key={name} className={styles.row}>
        <div className={styles.iconContainer}>
          <Icon />
        </div>
        <Switch
          disabled={true}
          onChange={() => {}}
          checked={!!linkValue}
          uncheckedIcon={false}
          checkedIcon={false}
          height={27}
          offColor={"#E3E6EE"}
          onColor={"#3588E9"}
        />
        <Input
          placeholder="Insert link"
          customClass={styles.input}
          value={linkValue}
          onChange={handleChange(name)}
          disabled={isDownloaded}
        />
      </div>
    );
  });
};

export default SocialLinks;
