import React from 'react';

import styles from './Title.module.scss'

export const Title = ({title, className}) => {

    return (
        <div className={className ? className : styles.title}>
            {title}
        </div>
    );
};

