import AuthModal from "./AuthModal";
import useAuthConnectionData from "./useAuthConnectionData";
import { Button, Typography } from "../../../../../../components";
import { useSubscription } from "../../../../../SubscriptionPage/useSubscription";
import styles from "./AuthConnection.module.scss";
import { useSelector } from "react-redux";
import { selectUser } from "../../../../../../store/slices/authSlice";
import { normalizeSubscriptionPlan } from "../../../../../../helper";

const AuthConnection = () => {
  const {
    selectedIntegrationType,
    isModalOpen,
    setIsModalOpen,
    handleUserConsent,
    showFilePicker,
    handleOpenPicker,
    isAssistantConnected,
    sheetIdUrl,
    revokeIntegrationForSpreadsheetOrders,
    isConnecting,
  } = useAuthConnectionData();

  const user = useSelector(selectUser);
  const { subscriptionPlan } = useSubscription(user);
  const normalizedPlan = normalizeSubscriptionPlan(subscriptionPlan);

  const isConnectDisabled = normalizedPlan === null || isConnecting;

  let titleText = "";
  let buttonText = null;

  if (!isAssistantConnected) {
    // No integration at all
    titleText = "Connect your account to Spreadsheet Orders";
    buttonText = "Connect";
  } else {
    // Integration created
    if (!sheetIdUrl) {
      // Integration is available, but file is not selected
      titleText = "You can finish connection by selecting a file";
      // Show btn "Open file picker"
    } else {
      // Full integration (file selected)
      titleText = "Revoke your account from Spreadsheet Orders";
      buttonText = "Revoke";
    }
  }

  const handleButtonClick = async () => {
    if (buttonText === "Connect") {
      setIsModalOpen(true);
    } else if (buttonText === "Revoke") {
      await revokeIntegrationForSpreadsheetOrders();
    }
  };

  return (
    <div>
      {sheetIdUrl && (
        <Typography variant="caption2" className={styles.editSheet}>
          You can edit your spreadsheet{" "}
          <a href={sheetIdUrl} target="_blank" rel="noreferrer">
            here
          </a>
        </Typography>
      )}

      <AuthModal
        isOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        handleUserConsent={handleUserConsent}
      />

      <Typography variant="caption2">{titleText}</Typography>

      {buttonText && (
        <Button
          variant="contained"
          title={buttonText}
          onClick={handleButtonClick}
          className={styles.button}
          disabled={isConnectDisabled}
        />
      )}

      {showFilePicker && !sheetIdUrl && (
        <>
          <Button
            variant="contained"
            title="Open file picker"
            onClick={handleOpenPicker}
            className={styles.button}
          />
        </>
      )}
    </div>
  );
};

export default AuthConnection;
