import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import supabase from "../../../../supabase";
import { useRemoveChannelMutation } from "../../../../store/api";
import { useSetupWebhookMutation } from "../../../../store/api";
import { setOnboarding } from "../../../../store/slices/onboardingSlice";
import { Typography } from "../../../../components";
import { Loader } from "../../../../components/Loader";
import Switch from "react-switch";
import styles from "./WhatsApp.module.scss";
import { Title } from "../../../../components/Title";
import { Modal } from "../../../../components/Modal";
import { Button } from "../../../../components/Button";

import { NoConnection } from "../../../../components/NoConnection";

export const WhatsApp = ({ channelData, fetchChannels, assistantId }) => {
  const channel = channelData[0];

  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const step = useSelector((state) => state.onboarding.step);

  const [isCheck, setIsCheck] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorDescription, setErrorDescription] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [removeChannel] = useRemoveChannelMutation();
  const [setupWebhook] = useSetupWebhookMutation();

  const handleChange = async () => {
    try {
      setIsLoading(true);
      if (!isCheck) {
        await setupChannelWebhook("WhatsApp");
      } else {
        const { error } = await supabase
          .from("channels")
          .update({ is_on: false })
          .match({
            account_id: user.account_id,
            communication_channel: "WhatsApp",
            assistant_id: assistantId,
          });
        if (error) {
          throw new Error("Failed to turn off channel");
        }
      }
      setIsCheck(!isCheck);
    } catch (error) {
      setErrorDescription(error.message);
      setIsModalVisible(true);
    } finally {
      await fetchChannels();
      setIsLoading(false);
    }
  };

  const openWhatsAppAuth = () => {
    const state = `${user.account_id}|${assistantId}`;
    const redirectUri = encodeURIComponent(
      `${process.env.REACT_APP_DOMAIN}/oauth/whatsapp`
    );
    const clientId = process.env.REACT_APP_FACEBOOK_CLIENT_ID;
    const scope = encodeURIComponent(
      "whatsapp_business_management whatsapp_business_messaging"
    );

    window.location.href = `https://www.facebook.com/v17.0/dialog/oauth?client_id=${clientId}&display=popup&redirect_uri=${redirectUri}&response_type=code&config_id=894487512788549&state=${encodeURIComponent(
      state
    )}&scope=${scope}&response_type=code`;
  };

  const setupChannelWebhook = async (channelName) => {
    const { error } = await setupWebhook({
      channel: channelName,
      account_id: user.account_id,
      assistant_id: assistantId,
      page_id: channel.page_id,
    });
    if (error) {
      throw new Error("Failed to setup webhook");
    }
  };

  const revokeToken = async () => {
    try {
      setIsLoading(true);
      const { error: supabaseError } = await supabase
        .from("channels")
        .delete()
        .eq("account_id", user.account_id)
        .eq("communication_channel", "WhatsApp")
        .eq("assistant_id", assistantId);

      if (supabaseError) {
        throw new Error("Failed to remove channel");
      }
      await fetchChannels();
    } catch (error) {
      setErrorDescription(error.message);
      setIsModalVisible(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsCheck(channel?.is_on ?? false);
  }, [channel]);

  return (
    <>
      <Title title={"WhatsApp"} />

      <div className={styles.enableBot}>
        <Switch
          disabled={!channel?.access_token}
          onChange={handleChange}
          checked={isCheck}
          uncheckedIcon={false}
          checkedIcon={false}
          height={27}
          offColor={"#E3E6EE"}
          onColor={"#3588E9"}
        />
        <div className={styles.enableBotText}>
          Connect assistant to this channel
        </div>
      </div>
      <div className={styles.connectionBot}>
        {isLoading ? (
          <div className={styles.loaderContainer}>
            <Loader height={40} width={40} />
          </div>
        ) : (
          <>
            {channel?.access_token && (
              <p className={styles.pageDescription}>
                Phone Number ID: {channel.phone_number_id}
              </p>
            )}
            {channel?.access_token ? (
              <Button
                onClick={revokeToken}
                className={styles.saveOptimize}
                title={"REVOKE TOKEN"}
              />
            ) : (
              <Button
                onClick={openWhatsAppAuth}
                className={styles.saveOptimize}
                title={"CONNECT WHATSAPP"}
              />
            )}
          </>
        )}
      </div>

      <Modal
        title={"Error"}
        description={errorDescription}
        setIsShowModal={setIsModalVisible}
        isShowModal={isModalVisible}
      />
    </>
  );
};
