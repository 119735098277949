import React, { useState, useEffect } from "react";
import styles from "./ClockFace.module.scss";

const ClockFace = ({ timeType, onChange }) => {
  const [numbers, setNumbers] = useState(timeType);
  const [startTouch, setStartTouch] = useState(null);
  const [mouseStartY, setMouseStartY] = useState(null);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [scrollAccumulator, setScrollAccumulator] = useState(0);
  const scrollThreshold = 50;
  const mouseThreshold = 10;

  useEffect(() => {
    setNumbers(timeType);
  }, [timeType]);

  useEffect(() => {
    onChange(numbers[1]);
  }, [numbers, onChange]);

  const shiftNumbers = (direction) => {
    const newNumbers = [...numbers];
    if (direction === "up") {
      newNumbers.push(newNumbers.shift());
    } else if (direction === "down") {
      newNumbers.unshift(newNumbers.pop());
    }
    setNumbers(newNumbers);
  };

  const handleTouchStart = (e) => {
    const touch = e.touches[0];
    setStartTouch(touch.clientY);
  };

  const handleTouchMove = (e) => {
    if (!startTouch) return;
    const touch = e.touches[0];
    const deltaY = touch.clientY - startTouch;

    setScrollAccumulator((prevAccumulator) => {
      const newAccumulator = prevAccumulator + Math.abs(deltaY);
      if (newAccumulator >= scrollThreshold) {
        shiftNumbers(deltaY > 0 ? "down" : "up");
        setStartTouch(touch.clientY);
        return 0;
      }
      return newAccumulator;
    });
  };

  const handleTouchEnd = () => {
    setStartTouch(null);
    setScrollAccumulator(0);
  };

  const handleMouseMove = (e) => {
    if (mouseStartY === null) return;

    const deltaY = e.clientY - mouseStartY;

    setScrollAccumulator((prevAccumulator) => {
      const newAccumulator = prevAccumulator + Math.abs(deltaY);
      if (newAccumulator >= mouseThreshold) {
        shiftNumbers(deltaY > 0 ? "down" : "up");
        setMouseStartY(e.clientY);
        return 0;
      }
      return newAccumulator;
    });
  };

  const handleMouseUp = () => {
    setIsMouseDown(false);
    setMouseStartY(null);
    setScrollAccumulator(0);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  const handleMouseDown = (e) => {
    if (e.button === 0) {
      // Лише для лівої кнопки миші
      setMouseStartY(e.clientY);
      setIsMouseDown(true);
      document.addEventListener("mouseup", handleMouseUp);
    }
  };

  const handleMouseWheel = (e) => {
    const direction = e.deltaY > 0 ? "up" : "down";
    setScrollAccumulator((prevAccumulator) => {
      const newAccumulator = prevAccumulator + Math.abs(e.deltaY);
      if (newAccumulator >= scrollThreshold) {
        shiftNumbers(direction);
        return 0;
      }
      return newAccumulator;
    });
  };

  return (
    <div className={styles.clockBackground}>
      <div
        className={styles.clockShadow}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        onWheel={handleMouseWheel}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        style={{
          height: "100px",
          width: "50px",
          border: "1px solid black",
          overflow: "hidden",
        }}
      >
        <ul className={styles.clockNumbers}>
          {numbers.map((num, index) => (
            <li key={index} className={styles.numberItem}>
              {num}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ClockFace;
