import React from 'react';

import {Title} from "../Title";
import Conversation from '../../assets/img/NoConversation.png'

import styles from './NoConversation.module.scss'

export const NoConversation = ({title, description}) => {

    return (
        <div className={styles.container}>
            <div className={styles.wrapper}>
                <div className={styles.textContainer}>
                    <div>
                        <Title title={title}/>
                    </div>
                    <div className={styles.description}>{description}</div>
                </div>
                <img src={Conversation} alt=""/>
            </div>
        </div>
    );
};
