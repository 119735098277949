import { useMemo } from "react";
import { Handle, Position } from "reactflow";
import styles from "./StartNode.module.scss";
import { NodeHeader } from "../NodeHeader/NodeHeader";
import { NodeBody } from "../NodeBody/NodeBody";
import { NODE_TYPES } from "../../../const";
import { FaRegClock } from "react-icons/fa";
import { MdOutlineEdit } from "react-icons/md";
import classNames from "classnames";
import { useFollowUpPageContext } from "../../../../../context/useFollowUpPageContext";
import { Typography } from "../../../../../../../components/Typography/Typography";

export function StartNode({ id, data }) {
  const { setIsEditStartNodeModalOpen, nodes } = useFollowUpPageContext();

  const startNodeSilence = useMemo(
    () =>
      nodes.find((node) => node.type === NODE_TYPES.start)?.conditions?.[0]
        ?.silence,
    [nodes]
  );

  const startNodeSilenceContent = useMemo(() => {
    if (startNodeSilence) {
      return `${startNodeSilence.hours}h ${startNodeSilence.minutes}m ${startNodeSilence.seconds}s`;
    }
    return null;
  }, [startNodeSilence]);

  return (
    <div className={styles.container}>
      <NodeHeader name={data.label} type={NODE_TYPES.start} id={id} />
      <Handle
        type="source"
        position={Position.Bottom}
        className={styles.handle}
      />
      <NodeBody nodeType="start">{data.prompt}</NodeBody>
      <div className={styles.footer}>
        <Typography variant="caption">
          + Targeting ( {startNodeSilenceContent}
        </Typography>
        <FaRegClock size={12} className={styles.startConditionIcon} /> )
        <button
          className={classNames("nodrag nopan", styles.editButton)}
          onClick={() => {
            setIsEditStartNodeModalOpen(true);
          }}
        >
          <MdOutlineEdit size={20} />
        </button>
      </div>
    </div>
  );
}
