import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";
import supabase from "../../supabase";
import { MdToHTML } from "../../components/MdToHTML";
import { formatDate, getChannelIcon } from "../../utils";

import { FaBookOpen } from "react-icons/fa";
import { BsFillCheckSquareFill } from "react-icons/bs";

import userAvatar from "../../assets/img/user.png";
import { Send, Viber, Manager, Logo } from "../../assets/icons";
import { processMessageContent } from "../ChatsPage/DesktopChat-V2/helpers";
import styles from "./UserChatPage.module.scss";
import { Modal } from "../../components/Modal";
import MessageImage from "../../components/Bot/MessageImage/MessageImage";

export const UserChatPage = () => {
  const location = useLocation();

  const { chat } = location.state;
  const user = useSelector((state) => state.auth.user);

  const [messages, setMessages] = useState([]);
  const [name, setName] = useState("");
  const [connection, setConnection] = useState("");
  const [avatarUrl, setAvatarUrl] = useState("");
  const [operatorMode, setOperatorMode] = useState(false);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [errorText, setErrorText] = useState("");

  const [specMessageData, setSpecMessageData] = useState(null);
  const [knowledgeHints, setKnowledgeHints] = useState([]);
  const [isSending, setIsSending] = useState(false);

  const fetchData = async (userIndex) => {
    try {
      const chatUrl = chat.chat_history_url;
      if (!chatUrl) {
        console.error(`Invalid chat URL for userIndex ${userIndex}`);
        return;
      }

      const response = await axios.get(chatUrl);

      const lines = response.data.split("\n");
      const messages = lines
        .filter((line) => line.trim() !== "")
        .map((line) => JSON.parse(line))
        .filter((message) => {
          return message.role !== "function" && !message.function_call;
        });

      setMessages(messages);
      setName(chat?.client_name);
      setConnection(chat?.communication_channel);
      setAvatarUrl(chat?.client_avatar_url);
      setOperatorMode(chat?.needs_operator);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchSpecialMessages = async () => {
    try {
      const { data, status } = await supabase
        .from("knowledge_hints")
        .select("*")
        .eq("account_id", user.account_id);

      if (data && status === 200) {
        setKnowledgeHints(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchSpecialMessages();
  }, []);

  useEffect(() => {
    fetchData();
  }, [chat]);

  const handleClickSpecMessageIcon = (messageId) => {
    //  setStopInterval(true);
    setSpecMessageData(null);

    const updateMessages = messages.map((msg) => {
      if (msg.id === messageId) {
        if (msg?.knowledge) {
          //  setStopInterval(false);

          return {
            ...msg,
            knowledge: false,
          };
        } else {
          return {
            ...msg,
            knowledge: true,
          };
        }
      } else {
        return {
          ...msg,
          knowledge: false,
        };
      }
    });

    setMessages(updateMessages);
  };

  const handleChangeSpecMessageTextarea = (msgId, value) => {
    setSpecMessageData({
      message_id: msgId,
      knowledge: value.target.value.trim(),
      chat_id: chat.id,
    });
  };

  const sendSpecMessageData = async () => {
    try {
      setIsSending(true);

      const data = await axios.post(
        `${process.env.REACT_APP_API_URL}/improve-knowledge?account=${user.account_id}`,
        specMessageData
      );

      if (data.status === 200) {
        const updateMessages = messages.map((msg) => {
          return {
            ...msg,
            knowledge: false,
          };
        });

        setMessages(updateMessages);
        setSpecMessageData(null);
        fetchSpecialMessages();
        fetchData();
      }
    } catch (error) {
      setErrorText(error.message);
      setIsModalVisible(true);
    } finally {
      setIsSending(false);
    }
  };

  const handleClickSpecMessageSave = () => {
    if (!specMessageData || specMessageData?.knowledge?.length === 0) {
      setErrorText("Please enter text, the field must NOT be empty");
      setIsModalVisible(true);
      return;
    }

    sendSpecMessageData();
  };

  return (
    <div className={styles.chat}>
      <div className={styles.userChatHeader}>
        <div className={styles.headerContainer}>
          <div className={styles.clientInfo}>
            {avatarUrl ? (
              <img src={avatarUrl} alt={"avatar"} />
            ) : (
              <img src={userAvatar} alt={"avatar"} />
            )}
            <div className={styles.clientData}>
              <div className={styles.clientName}>{name}</div>
              {getChannelIcon(connection)}
            </div>
            {operatorMode && (
              <div style={{ marginLeft: "6px", fontSize: "1.25rem" }}>
                (Handled by operator)
              </div>
            )}
          </div>
        </div>
        <div className={styles.chatList}>
          {messages &&
            messages.map((message, index) => {
              const { content, images, instagramStoryLink } =
                processMessageContent(message);

              const manageStr = "Manager: ";
              const viberStr = "Viber: ";
              const isManagerStr = content.includes(manageStr);
              const isViberStr = content.includes(viberStr);

              const newContent = isManagerStr
                ? content.substring(manageStr.length).trim()
                : isViberStr
                ? content.substring(viberStr.length).trim()
                : content;

              return (
                <div className={styles.messageContainer} key={index}>
                  <div className={`${styles.message} ${styles[message.role]}`}>
                    {isManagerStr && (
                      <div className={styles.sentVia}>
                        <Manager />
                      </div>
                    )}

                    {isViberStr && (
                      <div className={styles.sentVia}>
                        <Viber />
                      </div>
                    )}

                    {/* {newContent && <p>{newContent}</p>} */}
                    {newContent && <MdToHTML content={newContent} />}
                    {instagramStoryLink && (
                      <iframe
                        alt=""
                        src={instagramStoryLink}
                        style={{ maxHeight: "300px", maxWidth: "415px" }}
                        frameborder="0"
                        rel="instagram story"
                      ></iframe>
                    )}
                    <MessageImage message={message.content} />

                    <FaBookOpen
                      size={24}
                      className={styles.specMessageIcon}
                      title="Click to improve answer"
                      onClick={() => handleClickSpecMessageIcon(message.id)}
                    />
                  </div>
                  <div
                    className={`${styles.messageDate} ${styles[message.role]}`}
                  >
                    {formatDate(message.created_at)}
                  </div>

                  {message?.knowledge_hints &&
                    message?.knowledge_hints.length > 0 &&
                    message.knowledge_hints.map((knowledgeItem, ind) => {
                      const knowledgeMsg = knowledgeHints.find(
                        (el) => el.id === knowledgeItem
                      );

                      return (
                        <div key={ind}>
                          <div className={styles.specialMsg}>
                            {knowledgeMsg?.knowledge}
                          </div>
                          <div
                            className={`${styles.messageDate} ${
                              styles[message.role]
                            }`}
                          >
                            {formatDate(knowledgeMsg?.created_at)}
                          </div>
                        </div>
                      );
                    })}

                  {message.knowledge &&
                    (isSending ? (
                      <div className={styles.containerTyping}>
                        <div className={styles.botResponse}>
                          <Logo />
                          <p>Saving...</p>
                        </div>
                      </div>
                    ) : (
                      <div className={styles.specialMsgBox}>
                        <textarea
                          onChange={(value) =>
                            handleChangeSpecMessageTextarea(message.id, value)
                          }
                          className={styles.specialMsgTextarea}
                          placeholder="Input useful context knowledge, it will be used next time client asks something similar to the above question."
                        />

                        <BsFillCheckSquareFill
                          size={26}
                          title="Save changes"
                          className={styles.specMessageIconSave}
                          onClick={handleClickSpecMessageSave}
                        />
                      </div>
                    ))}
                </div>
              );
            })}
        </div>
      </div>
      <Modal
        title="Error"
        description={errorText}
        setIsShowModal={setIsModalVisible}
        isShowModal={isModalVisible}
      />
    </div>
  );
};
