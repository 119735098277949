import { IoPlayOutline } from "react-icons/io5";
import { BiMessageAltDetail } from "react-icons/bi";
import { FiTrash2 } from "react-icons/fi";
import { MdOutlineEdit } from "react-icons/md";
import { Typography } from "../../../../../../../components/Typography/Typography";
import { useFollowUpPageContext } from "../../../../../context/useFollowUpPageContext";

import styles from "./NodeHeader.module.scss";
import { NODE_TYPES } from "../../../const";

export function NodeHeader({ name, type = NODE_TYPES.branch, id }) {
  const { setIsEditNodeModalOpen, setSelectedNodeId, deleteNode } =
    useFollowUpPageContext();

  return (
    <>
      <div className={styles.container}>
        <div className={styles.nameContainer}>
          {type === NODE_TYPES.branch ? (
            <BiMessageAltDetail size={16} />
          ) : (
            <IoPlayOutline size={16} />
          )}
          <Typography variant="caption">{name}</Typography>
        </div>
        <div className={styles.actionsContainer}>
          <button
            onClick={() => {
              setIsEditNodeModalOpen(true);
              setSelectedNodeId(id);
            }}
          >
            <MdOutlineEdit size={16} className="nodrag nopan" />
          </button>
          {type === NODE_TYPES.branch ? (
            <button
              onClick={() => {
                deleteNode(id);
              }}
            >
              <FiTrash2 size={16} className="nodrag nopan" />
            </button>
          ) : null}
        </div>
      </div>
    </>
  );
}
