import React, { useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";

import { Input } from "../../../../components/Input";
import { getData } from "../../../../service/supabase.js";

import styles from "../../AIConfiguration.module.scss";

export const AssistantToolkit = ({
  userId,
  knowledgeBase,
  setKnowledgeBase,
} = {}) => {
  const [assistantToolkit, setAssistantToolkit] = useState([]);
  const [selectedToolkit, setSelectedToolkit] = useState(null);

  const getAssistantToolkit = async () => {
    try {
      const { data } = await getData("integrations", "*", "account_id", userId);

      if (!data) return;

      const items = data
        ?.filter(({ is_knowledge_base }) => is_knowledge_base)
        .map(({ knowledge_base }) => knowledge_base);
      const result = [...new Set(items)].map((el) => {
        const label = Number.isFinite(el) ? `Knowledge base №${el}` : el;
        return {
          value: el,
          label,
        };
      });
      setAssistantToolkit(result);
    } catch ({ message }) {
      toast.error(message);
    }
  };

  useEffect(() => {
    if (!userId) return;
    getAssistantToolkit();
  }, [userId]);

  useEffect(() => {
    if (!knowledgeBase || !assistantToolkit.length) return;
    const result = assistantToolkit?.find((el) => el?.value === knowledgeBase);
    setSelectedToolkit(result);
  }, [knowledgeBase, assistantToolkit]);

  return (
    <>
      <section>
        <h2 className={styles.titleInputs}>Assistant toolkit</h2>
        <Input
          typeInput={"select"}
          placeholder={"Select knowledge base"}
          options={assistantToolkit}
          inputTitle={"Knowledge base"}
          value={selectedToolkit}
          onChange={({ value }) => setKnowledgeBase(value)}
        />
      </section>
    </>
  );
};
