import styles from "./NodeBody.module.scss";
import { Typography } from "../../../../../../../components";
import classNames from "classnames";

export function NodeBody({ children, nodeType = "branch" }) {
  return (
    <div
      className={classNames(
        styles.container,
        nodeType !== "branch" ? styles.containerStartNode : ""
      )}
    >
      <Typography variant="body2">{children}</Typography>
    </div>
  );
}
