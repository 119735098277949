import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import supabase from "../../supabase";

export const fetchChannels = createAsyncThunk(
  "channels/fetchChannels",
  async (userId, { rejectWithValue }) => {
    try {
      const { data, error } = await supabase
        .from("channels")
        .select("*")
        .eq("account_id", userId);

      if (error) throw error;
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const listenToChannelChanges = (userId) => (dispatch) => {
  const subscription = supabase
    .channel("public:channels")
    .on(
      "postgres_changes",
      {
        event: "*",
        schema: "public",
        table: "channels",
        filter: `account_id=eq.${userId}`,
      },
      () => {
        dispatch(fetchChannels(userId));
      }
    )
    .subscribe();

  return () => {
    supabase.removeChannel(subscription);
  };
};

const channelsSlice = createSlice({
  name: "channels",
  initialState: {
    channels: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchChannels.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchChannels.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.channels = action.payload;
      })
      .addCase(fetchChannels.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default channelsSlice.reducer;