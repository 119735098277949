import React, { useState, useRef, useEffect } from "react";
import styles from "./CustomSelectFilter.module.scss";
import { IoIosArrowDown } from "react-icons/io";

const CustomFollowUpDropdown = ({
  options,
  placeholder,
  onChange,
  disabled = false,
  value: propsValue = null,
}) => {
  const [selectedOption, setSelectedOption] = useState(placeholder);
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    onChange?.(option);
    setIsOpen(false);
  };

  const toggleDropdown = () => {
    if (disabled) return;
    setIsOpen(!isOpen);
  };

  const closeDropdown = (e) => {
    if (selectRef.current && !selectRef.current.contains(e.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", closeDropdown);
    return () => {
      document.removeEventListener("mousedown", closeDropdown);
    };
  }, []);

  return (
    <div className={styles.customSelect} ref={selectRef}>
      <div
        className={`${styles.selectSelected} ${
          isOpen ? styles.selectArrowActive : ""
        } ${disabled ? styles.disabled : ""}`}
        onClick={toggleDropdown}
      >
        {propsValue ?? selectedOption}
        {!disabled ? <IoIosArrowDown className={styles.selectedArrow} /> : null}
      </div>
      {isOpen && (
        <div className={styles.selectItems}>
          {options.map((option, index) => (
            <div key={index} onClick={() => handleOptionClick(option)}>
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CustomFollowUpDropdown;
