import styles from "./Integration.module.scss";
import { Loader } from "../Loader";
import React, { useCallback, useMemo, useState } from "react";
import { useEffect } from "react";
import { Modal } from "../Modal";
import { Button } from "../Button";
import { Input } from "../Input";
import {
  PLUGIN_NAME_REGEX,
  SITE_URL_REGEX,
} from "../../constants/validationScheme";
import supabase from "../../supabase";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
  WORDPRESS_CMS,
  OPENCART_CMS,
} from "../../constants/knowledgeBaseItems";
import classNames from "classnames";
import { TOOL_TYPE } from "../../constants/integrations";
import {
  useValidateIntegrationAssetsMutation,
  useRevokeIntegrationMutation,
} from "../../store/api";

export const IntegrationWithKeys = (props) => {
  const {
    id,
    title,
    icon,
    description,
    connected,
    fetchIntegrations,
    isLoadingRefreshData,
    children,
    knowledgeBase,
    isAnyCMSConnected,
    integrationInProgressId,
    setIntegrationInProgressId,
    docLink,
    accessToken: storedAccessToken,
    refreshToken: storedRefreshToken,
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [domain, setDomain] = useState("");
  const [isDomainValid, setIsDomainValid] = useState(true);
  const [pluginName, setPluginName] = useState("feedv1");
  const [isPluginNameValid, setIsPluginNameValid] = useState(true);
  const [validateIntegrationAssets] = useValidateIntegrationAssetsMutation();
  const [inventoryName, setInventoryName] = useState("");
  const [accessToken, setAccessToken] = useState("");
  const [refreshToken, setRefreshToken] = useState("");

  const user = useSelector((state) => state.auth.user);

  const [revokeIntegration] = useRevokeIntegrationMutation();

  useEffect(() => {
    // When the component mounts, set accessToken and refreshToken from props if connected
    if (connected) {
      setAccessToken(storedAccessToken);
      setRefreshToken(storedRefreshToken);
    }
  }, [connected, storedAccessToken, storedRefreshToken]);

  const handleDomainChange = useCallback((e) => {
    setDomain(e.target.value);
    if (SITE_URL_REGEX.test(e.target.value)) {
      setIsDomainValid(true);
    } else {
      setIsDomainValid(false);
    }
  }, []);

  const handlePluginNameChange = useCallback((e) => {
    if (e.target.value.trim() === "") {
      setIsPluginNameValid(false);
      setPluginName(e.target.value);
    } else if (PLUGIN_NAME_REGEX.test(e.target.value)) {
      setPluginName(e.target.value.replaceAll(" ", "-"));
      setIsPluginNameValid(true);
    }
  }, []);

  const updateIntegrationAssets = async () => {
    const parsedDomain = new URL(domain);
    const domainHostname = parsedDomain.hostname;

    const updatedPluginName = pluginName.trim().toLowerCase();

    var access_token = `https://${domainHostname}/wp-json/mcb/v1/${user.account_id}-${updatedPluginName}`;
    if (inventoryName === "oc-inventory") {
      access_token = `https://${domainHostname}/index.php?route=extension/module/mychatbot/products&app_id=${updatedPluginName}`;
    } else if (inventoryName === "xml-inventory") {
      access_token = domain;
    }

    try {
      const account_id = user.account_id;
      await supabase
        .from("integrations")
        .insert({
          access_token,
          type: "cms",
          integration: title,
          knowledge_base: knowledgeBase,
          is_knowledge_base: true,
        })
        .match({ account_id: user.account_id });
      toast.success("Integration assets updated successfully");

      if (inventoryName !== "xml-inventory") {
        window.open(
          `${process.env.REACT_APP_API_URL}/downloads/${inventoryName}/${account_id}/${updatedPluginName}`
        );
      } else {
        setIsModalOpen(false);
        const validateIntegrationRes = await validateIntegrationAssets({
          account_id: user.account_id,
          integration: title,
          access_token,
        });
        if (validateIntegrationRes.error) {
          toast.error(validateIntegrationRes.error);
        }
      }
    } catch ({ message }) {
      toast.error(message);
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
    }
  };

  const handleOnConnect = useCallback(async () => {
    if (isDomainValid && isPluginNameValid) {
      setIsLoading(true);
      try {
        await updateIntegrationAssets();
      } catch ({ message }) {
        toast.error(message);
      }
      fetchIntegrations();
    }
  }, [
    fetchIntegrations,
    isDomainValid,
    isPluginNameValid,
    updateIntegrationAssets,
  ]);

  const handleOnRevoke = useCallback(async () => {
    setIsLoading(true);
    try {
      // Prepare the payload
      const payload = {
        account_id: user.account_id,
        integration: title,
        access_token: accessToken,
        refresh_token: refreshToken,
        //sheet_id: sheetId,
      };

      // Send POST request to /revoke-integration
      try {
        await revokeIntegration(payload).unwrap();
      } catch (error) {
        toast.error("Failed to revoke integration");
      }

      // Proceed to delete the record from Supabase
      const { error: errorSupabase } = await supabase
        .from("integrations")
        .delete()
        .eq("account_id", user.account_id)
        .eq("id", id)
        .eq("integration", title);

      if (errorSupabase) {
        toast.error(errorSupabase.message || errorSupabase);
      }

      setDomain("");
      setPluginName("");
      setInventoryName("");
      setAccessToken("");
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
      setIsModalOpen(false);
      fetchIntegrations();
    }
  }, [fetchIntegrations, id, title, user.account_id, accessToken]);

  const isIntegrationDisabled = useMemo(() => {
    if (integrationInProgressId && integrationInProgressId !== id) {
      return true;
    }
    return isAnyCMSConnected && !connected;
  }, [connected, isAnyCMSConnected, integrationInProgressId, id]);

  const integrationContent = useMemo(
    () => (
      <>
        <div className={styles.iconContainer}>
          <div className={styles.iconBox}>{icon ? icon : children}</div>
          {docLink && (
            <a target="_blank" href={docLink} className={styles.link}>
              link
            </a>
          )}
          {!!title && <div className={styles.title}>{title}</div>}
          {!!description && (
            <div className={styles.description}>{description}</div>
          )}
        </div>
        <Button
          onClick={() => {
            connected ? handleOnRevoke() : setIsModalOpen(true);
          }}
          className={classNames(styles.button, {
            [styles.connected]: connected,
          })}
          title={connected ? "revoke" : "connect"}
          disabled={isIntegrationDisabled}
        />
      </>
    ),
    [
      children,
      connected,
      description,
      handleOnRevoke,
      icon,
      isIntegrationDisabled,
      title,
    ]
  );

  const modalContent = useMemo(
    () => (
      <div className={styles.modalContent}>
        <div>
          <Input
            inputTitle={
              inventoryName && inventoryName === "xml-inventory"
                ? "XML file URL"
                : inventoryName === "yml-inventory"
                ? "YML file URL"
                : "Website URL"
            }
            customClass={styles.input}
            onChange={handleDomainChange}
            error={!isDomainValid}
            errorText={isDomainValid ? "" : "Invalid URL"}
            placeholder={
              inventoryName === "yml-inventory"
                ? "https://yourwebsite.com/var/files/1/yml/prices.yml"
                : "https://example.com/feed.xml"
            }
          />
        </div>
        {inventoryName &&
          inventoryName !== "xml-inventory" &&
          inventoryName !== "yml-inventory" && (
            <div>
              <Input
                inputTitle="Plugin Name (Alphanumeric characters and spaces/hyphens only)"
                customClass={styles.input}
                onChange={
                  inventoryName !== "xml-inventory"
                    ? handlePluginNameChange
                    : () => {}
                }
                errorText={isPluginNameValid ? "" : "Plugin name is required"}
                placeholder="Plugin for My Site"
                value={pluginName}
              />
            </div>
          )}
      </div>
    ),
    [
      handleDomainChange,
      handlePluginNameChange,
      isDomainValid,
      isPluginNameValid,
      pluginName,
      inventoryName,
    ]
  );

  const modalActions = useMemo(() => {
    const isDisabled =
      !domain || !pluginName || !isDomainValid || !isPluginNameValid;
    return (
      <Button
        variant="contained"
        title="connect"
        onClick={handleOnConnect}
        className={styles.connectButton}
        disabled={isDisabled}
      />
    );
  }, [domain, handleOnConnect, isDomainValid, isPluginNameValid, pluginName]);

  useEffect(() => {
    const inventoryName =
      title === WORDPRESS_CMS
        ? "wp-inventory"
        : title === OPENCART_CMS
        ? "oc-inventory"
        : title === "YML Product Feed"
        ? "yml-inventory"
        : "xml-inventory";
    setInventoryName(inventoryName);
  }, [title]);
  return (
    <div className={styles.container}>
      {isLoading || isLoadingRefreshData ? (
        <div className={styles.loaderContainer}>
          <Loader width={40} height={40} />
        </div>
      ) : (
        integrationContent
      )}
      <Modal
        isShowModal={isModalOpen}
        setIsShowModal={setIsModalOpen}
        title={`Connect ${title}`}
        actions={modalActions}
      >
        {modalContent}
      </Modal>
    </div>
  );
};
