import React from "react";
import { useDispatch } from "react-redux";
import { toggleDisableBody } from "../../../store/slices/disableBodySlice";
import Popup from "reactjs-popup";
import { Loader } from "../../Loader";
import styles from "./OptimazeInstructions.module.scss";

const OptimizeInstructions = ({
  disabled,
  roleAndInstructions,
  suggestInstractions,
  isOptimizeInstructions,
  loading,
  isInstructionsPopupOpen,
  handleOptimize,
  saveSuggestInstructions,
  setRoleAndInstructions,
  setSuggestInstractions,
  setIsInstructionsPopupOpen,
  setIsOptimizeInstructions,
}) => {
  const dispatch = useDispatch();

  return (
    <Popup
      onClose={() => {
        setIsInstructionsPopupOpen(false);
        dispatch(toggleDisableBody(false));
      }}
      closeOnDocumentClick={false}
      open={isInstructionsPopupOpen}
      contentStyle={{
        padding: 15,
        width: "80vw",
        borderRadius: "1rem",
        border: "none",
        textAlign: "center",
        minHeight: "90vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {isOptimizeInstructions ? (
        <>
          <div className={styles.suggestInstractionsContainer}>
            <div className={styles.suggestInstractionsWrapper}>
              <div className={styles.textareaTitle}>Previos instructions</div>
              <textarea
                type="textarea"
                className={styles.textarea}
                maxLength={10000}
                value={roleAndInstructions}
                disabled
              />
            </div>
            <div className={styles.suggestInstractionsWrapper}>
              <div className={styles.textareaTitle}>New instructions</div>
              <textarea
                type="textarea"
                className={styles.textarea}
                maxLength={10000}
                value={suggestInstractions}
                onChange={(e) => setSuggestInstractions(e.target.value)}
              />
            </div>
          </div>

          <div className="row" style={{ flexGrow: 0 }}>
            <button
              type="button"
              className="modalBtn saveBtn"
              onClick={() => {
                setIsOptimizeInstructions(false);
                setIsInstructionsPopupOpen(false);
                dispatch(toggleDisableBody(false));
              }}
              disabled={disabled}
            >
              Save old
            </button>
            <button
              type="button"
              className="modalBtn saveBtn"
              onClick={saveSuggestInstructions}
              disabled={disabled}
            >
              Save new
            </button>
          </div>
        </>
      ) : (
        <>
          <div className={styles.textareaTitle}>Role and instructions</div>
          <textarea
            type="textarea"
            className={styles.textarea}
            maxLength={10000}
            value={roleAndInstructions}
            onChange={(e) => setRoleAndInstructions(e.target.value)}
            disabled={loading}
          />

          <div className="row">
            {!loading ? (
              <button
                type="button"
                className="modalBtn saveBtn"
                onClick={() => {
                  setIsInstructionsPopupOpen(false);
                  dispatch(toggleDisableBody(false));
                }}
                disabled={disabled}
              >
                Collapse instructions
              </button>
            ) : (
              <Loader height={25} width={25} />
            )}
            <button
              className={styles.optimizeBtn}
              type="button"
              disabled={loading}
              onClick={() => {
                handleOptimize();
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#2c2c2c"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="lucide lucide-bot-message-square"
              >
                <path d="M12 6V2H8" />
                <path d="m8 18-4 4V8a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2Z" />
                <path d="M2 12h2" />
                <path d="M9 11v2" />
                <path d="M15 11v2" />
                <path d="M20 12h2" />
              </svg>
            </button>
          </div>
        </>
      )}
    </Popup>
  );
};

export default OptimizeInstructions;
