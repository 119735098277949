export const Google = ({width, height}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M23.8068 9.77869C23.9135 9.77869 24 9.86517 24 9.97186V12C24 12.7591 23.9295 13.5013 23.7942 14.2213C22.7481 19.8139 17.8211 24.0404 11.9154 23.9997C5.2887 23.954 -0.0146415 18.5997 3.03692e-05 11.973C0.0146085 5.35809 5.3817 0 12 0C15.2425 0 18.1844 1.28634 20.3444 3.37584C20.4224 3.45127 20.4244 3.57558 20.3477 3.65227L17.4784 6.52153C17.4045 6.59541 17.2852 6.59733 17.2095 6.52528C15.8538 5.23486 14.0196 4.44263 12 4.44263C7.82947 4.44263 4.47111 7.77712 4.4428 11.9475C4.41434 16.1453 7.80884 19.5573 12 19.5573C15.4005 19.5573 18.2767 17.3109 19.2251 14.2213H12.1932C12.0865 14.2213 12 14.1348 12 14.0281V9.97181C12 9.86512 12.0865 9.77864 12.1932 9.77864H23.8068V9.77869Z"
                fill="#2196F3"/>
            <path
                d="M23.8073 9.77869H22.3587C22.4653 9.77869 22.5518 9.86517 22.5518 9.97186V12C22.5518 12.7591 22.4813 13.5013 22.3461 14.2213C21.3481 19.5567 16.8178 23.6487 11.2754 23.9783C11.4875 23.9909 11.7009 23.9982 11.9159 23.9997C17.8217 24.0404 22.7487 19.8139 23.7948 14.2213C23.93 13.5013 24.0005 12.7591 24.0005 12V9.97181C24.0005 9.86517 23.914 9.77869 23.8073 9.77869Z"
                fill="#1E88E5"/>
            <path
                d="M5.13467 8.83509L1.49023 6.20184C3.53483 2.50383 7.47477 0 11.999 0C15.2415 0 18.1835 1.28634 20.3435 3.37584C20.4215 3.45127 20.4235 3.57558 20.3467 3.65227L17.4775 6.52153C17.4037 6.59527 17.2844 6.59756 17.2089 6.52566C15.8532 5.235 14.0188 4.44267 11.999 4.44267C8.95536 4.44267 6.33177 6.24192 5.13467 8.83509Z"
                fill="#F44336"/>
            <path
                d="M4.08398 8.07488L5.13609 8.83505C6.22875 6.46814 8.51006 4.76349 11.2143 4.48388C11.2346 4.48167 11.2542 4.479 11.2749 4.47703C11.0369 4.45444 10.7957 4.44263 10.5518 4.44263C7.80225 4.44263 5.406 5.89214 4.08398 8.07488Z"
                fill="#E53935"/>
            <path
                d="M18.8956 3.37584C18.9736 3.45127 18.9756 3.57558 18.8989 3.65231L16.57 5.9812C16.7931 6.15089 17.0067 6.33239 17.2093 6.52533C17.285 6.59737 17.4044 6.59545 17.4783 6.52158L20.3475 3.65231C20.4243 3.57558 20.4222 3.45131 20.3443 3.37584C18.1843 1.28634 15.2423 0 11.9998 0C11.7565 0 11.5151 0.00810937 11.2754 0.0224063C14.2304 0.198422 16.8976 1.44309 18.8956 3.37584Z"
                fill="#E53935"/>
            <path
                d="M20.8037 20.1551C18.6123 22.5199 15.4792 24 12.0009 24C7.30284 24 3.23541 21.3001 1.26562 17.3669L4.98633 14.8171C6.10284 17.5953 8.823 19.5573 12.0009 19.5573C13.9953 19.5573 15.8091 18.7846 17.1593 17.5223L20.8037 20.1551Z"
                fill="#4CAF50"/>
            <path
                d="M4.98583 14.8173L3.88867 15.5691C5.16297 17.943 7.66886 19.5574 10.5517 19.5574C10.7955 19.5574 11.0365 19.5454 11.2744 19.5229C8.41272 19.25 6.01623 17.3813 4.98583 14.8173Z"
                fill="#43A047"/>
            <path
                d="M11.9999 23.9999C15.4782 23.9999 18.6112 22.5199 20.8026 20.1551L19.91 19.5103C17.8524 22.071 14.7656 23.7698 11.2773 23.9781C11.5164 23.9923 11.7573 23.9999 11.9999 23.9999Z"
                fill="#43A047"/>
            <path
                d="M4.44267 12.0001C4.44267 12.9958 4.63533 13.9466 4.98544 14.8173L1.26469 17.367C0.455391 15.7522 0 13.9292 0 12.0001C0 9.89706 0.540844 7.92053 1.49119 6.2019L5.13562 8.83515C4.69088 9.79754 4.44267 10.8701 4.44267 12.0001Z"
                fill="#FFC107"/>
            <path
                d="M3.88846 15.5691L4.98562 14.8173C4.63551 13.9466 4.44285 12.9957 4.44285 12C4.44285 10.8701 4.69105 9.79751 5.1358 8.83512L4.08369 8.07495C3.40049 9.203 3.00398 10.5267 2.99432 11.9476C2.98541 13.258 3.31012 14.4918 3.88846 15.5691Z"
                fill="#FFB300"/>
        </svg>
    );
};
