import React, { useMemo } from "react";
import Switch from "react-switch";
import { Title } from "../../../../components/Title";
import { Button, Input, Loader, Typography } from "../../../../components";
import { WpWidgetInputs, SocialLinks } from "./components";
import WidgetColorPicker from "./components/WidgetColorPicker/WidgetColorPicker";
import classNames from "classnames";
import WpWidgetOptionsButtons from "./components/WpWidgetOptionsButtons/WpWidgetOptionsButtons";
import useCMSWidgetDataContext, {
  CMSWidgetDataProvider,
} from "./useCMSWidgetDataContext";
import { FaRegCopy } from "react-icons/fa";
import { toast } from "react-toastify";

import styles from "./CMSWidget.module.scss";
import {
  OC_WIDGET,
  SW_WIDGET,
  WIDGET_TYPES,
  WP_WIDGET,
} from "../../../../constants/channels";
import { Modal } from "../../../../components/Modal";

const CMSWidgetComponent = ({ widgetType }) => {
  const {
    handleToggleConnectAssistant,
    isSwitchDisabled,
    isCheck,
    handleDownloadButton,
    handleRemoveButton,
    isDownloaded,
    isLoading,
    swScript,
    handleCloseSwModal,
  } = useCMSWidgetDataContext();

  const widgetTitle = useMemo(
    () => WIDGET_TYPES[widgetType].name,
    [widgetType],
  );

  const downloadButtonText = useMemo(
    () =>
      widgetType === SW_WIDGET.id
        ? "Generate an HTML code and add it to the <head> of your website"
        : "Download a zip archive and install on your website",
    [widgetType],
  );

  if (isLoading) {
    return (
      <div className={styles.loaderWrapper}>
        <Loader />
      </div>
    );
  }

  const handleOnCopy = (e) => {
    e.stopPropagation();
    navigator.clipboard.writeText(swScript);
    toast.success("Script copied to clipboard");
  };

  return (
    <>
      <Title title={widgetTitle} />

      <div className={styles.enableBot}>
        <Switch
          disabled={isSwitchDisabled}
          onChange={handleToggleConnectAssistant}
          checked={isCheck}
          uncheckedIcon={false}
          checkedIcon={false}
          height={27}
          offColor={"#E3E6EE"}
          onColor={"#3588E9"}
        />
        <div className={styles.enableBotText}>
          Connect assistant to this channel
        </div>
      </div>

      <div className={styles.settingsContainer}>
        <Typography variant="h5" className={styles.subTitle}>
          Enter data and choose options for your widget
        </Typography>
        <WpWidgetInputs />
        <div className={styles.optionsContainer}>
          <div className={styles.socialLinks}>
            <Typography variant="h5" className={styles.subTitle}>
              Connect assistant to this channel
            </Typography>
            <Typography variant="body3" className={styles.linksDescription}>
              Insert link
            </Typography>
            <div className={styles.socialLinksContainer}>
              <SocialLinks />
            </div>
          </div>
          <div className={styles.colorPickerContainer}>
            <Typography
              variant="h5"
              className={classNames(styles.subTitle, styles.colorPickerTitle)}
            >
              Choose the widget color
            </Typography>
            <WidgetColorPicker />
            <WpWidgetOptionsButtons />
          </div>
        </div>
        <div className={styles.downloadContainer}>
          <Typography variant="h5" className={styles.subTitle}>
            {downloadButtonText}
          </Typography>
          <Button
            variant="contained"
            title={isDownloaded ? "Remove" : "Download"}
            onClick={isDownloaded ? handleRemoveButton : handleDownloadButton}
          />
        </div>
      </div>

      <Modal
        isShowModal={swScript}
        setIsShowModal={handleCloseSwModal}
        title="Please copy this script and paste it to your website"
      >
        <div className={styles.modalContent}>
          <Input customClass={styles.swScriptInput} value={swScript} disabled />
          <FaRegCopy
            size="2rem"
            onClick={handleOnCopy}
            title="Copy script"
            className={styles.copyIcon}
          />
        </div>
      </Modal>
    </>
  );
};

export const WpWidget = (props) => (
  <CMSWidgetDataProvider {...props} widgetType={WP_WIDGET.id}>
    <CMSWidgetComponent widgetType={WP_WIDGET.id} />
  </CMSWidgetDataProvider>
);

export const OcWidget = (props) => (
  <CMSWidgetDataProvider {...props} widgetType={OC_WIDGET.id}>
    <CMSWidgetComponent widgetType={OC_WIDGET.id} />
  </CMSWidgetDataProvider>
);

export const SwWidget = (props) => (
  <CMSWidgetDataProvider {...props} widgetType={SW_WIDGET.id}>
    <CMSWidgetComponent widgetType={SW_WIDGET.id} />
  </CMSWidgetDataProvider>
);
