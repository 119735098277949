export const GoogleDocsLink = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.6667 6.25H18.3334L2.91675 32.7083L9.58341 43.75H40.4167C40.4167 43.75 47.0834 32.2917 47.0834 32.5C47.0834 32.7083 31.6667 6.25 31.6667 6.25Z"
        stroke="#384157"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3335 6.25L40.4168 43.75"
        stroke="#384157"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M31.6668 6.25L9.5835 43.75"
        stroke="#384157"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.125 32.7084H45.8333"
        stroke="#384157"
        strokeWidth="3"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
