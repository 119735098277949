import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    step: null,
};

export const onboardingSlice = createSlice({
    name: 'onboarding',
    initialState,
    reducers: {
        setOnboarding: (state, action) => {
            state.step = action.payload;
        },
    },
});

export const {setOnboarding} = onboardingSlice.actions;

export default onboardingSlice.reducer;
