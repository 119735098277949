import React, { useEffect, useRef, useState } from "react";
import supabase from "../../supabase";
import { useDispatch, useSelector } from "react-redux";
import { setOnboarding } from "../../store/slices/onboardingSlice";
import {
  useClearChatMutation,
  useMessagesQuery,
  useSendMessageMutation,
} from "../../store/api";
import { Logo, Send } from "../../assets/icons";
import { Modal } from "../Modal";
import { MdToHTML } from "../../components/MdToHTML";
import styles from "./Bot.module.scss";
import close from "../../assets/img/close.png";
import MessageImage from "./MessageImage/MessageImage";

export const Bot = ({ assistantId }) => {
  const windowWidth = window.innerWidth;

  const dispatch = useDispatch();

  const step = useSelector((state) => state.onboarding.step);
  const user = useSelector((state) => state.auth.user);

  const [isTyping, setIsTyping] = useState(false);
  const [checked, setChecked] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [messages, setMessages] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [errorText, setErrorText] = useState("");

  const textareaRef = useRef();
  const bottomListRef = useRef();

  const [clearChat] = useClearChatMutation();
  const [sendMessage] = useSendMessageMutation();
  const { data } = useMessagesQuery({ id: user.account_id, assistantId });

  const clearChatHandle = async () => {
    try {
      await clearChat({ id: user.account_id, assistantId });
      setMessages([]);
    } catch (error) {
      console.error(error);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault();
      handleSend();
    }
  };

  const handleSend = async () => {
    try {
      if (!isTyping && inputValue !== "") {
        setIsTyping(true);
        const now = new Date().toISOString();
        const newMessage = {
          created_at: now,
          role: "user",
          content: inputValue,
        };

        setMessages((prev) => [...prev, newMessage]);

        const fullMessagesList = [...messages, newMessage];

        const body = {
          account_id: user.account_id,
          assistant_id: assistantId,
          messages: fullMessagesList,
        };

        setInputValue("");

        const { error: errorApi, data } = await sendMessage(body);

        if (errorApi) {
          setIsModalVisible(true);
          setErrorText(errorApi?.data);
        }
        if (step === 2) {
          await supabase
            .from("onboardings")
            .update({ step: checked ? 4 : 3 })
            .match({ account_id: user.account_id });
          dispatch(setOnboarding(checked ? 4 : 3));
        }

        if (data) {
          //setMessages(prev => [...prev, ...data]);
          setMessages((prev) => [...prev, ...data]);
        }
      }
    } catch (error) {
      setIsModalVisible(true);
      setErrorText("Failed to send message");
    } finally {
      setIsTyping(false);
    }
  };

  const fetchData = async () => {
    const { data } = await supabase
      .from("subscriptions")
      .select("*")
      .eq("email", user.email);
    if (data.length === 0) {
      setChecked(false);
    } else {
      setChecked(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, [assistantId]);

  useEffect(() => {
    if (data && data.messages && step >= 2) {
      setMessages(data.messages);
    }
  }, [data]);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "inherit";
      const scrollHeight = textareaRef.current.scrollHeight;
      textareaRef.current.style.height =
        scrollHeight > 300 ? "16rem" : scrollHeight + "px";
    }
  }, [inputValue]);

  useEffect(() => {
    if (bottomListRef.current) {
      bottomListRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  useEffect(() => {
    if (step === 1) {
      setMessages([
        { content: "Hi there, how can I assist you today?", role: "user" },
        { content: "Hello!", role: "assistant" },
      ]);
    }
  }, [step]);

  return (
    <div
      className={`${styles.testBot} ${
        step < 2 && step !== 6 ? styles.blurred : ""
      }`}
      style={{
        height:
          windowWidth >= 666
            ? step === 6
              ? "auto"
              : "calc(100vh - 18rem)"
            : "",
        maxHeight:
          windowWidth >= 666 ? (step === 6 ? "" : "calc(100vh - 18rem)") : "",
      }}
    >
      <div className={styles.title}>
        <div className={styles.titleBot}>Test your assistant</div>
        <div
          onClick={clearChatHandle}
          className={styles.imageWrapper}
          style={step < 2 ? { cursor: "default" } : { cursor: "pointer" }}
        >
          <img className={styles.closeIcon} src={close} alt="" />
        </div>
      </div>
      <div className={styles.chatList}>
        {messages
          .filter((message) => message.role !== "function")
          .map(
            (message, index, filteredMessages) =>
              message.role != "function" &&
              !message.function_call && (
                <div
                  key={index}
                  className={`${styles.message} ${styles[message.role]}`}
                  ref={
                    index === filteredMessages.length - 1 ? bottomListRef : null
                  }
                >
                  {/* <p>{message.content}</p> */}
                  <MdToHTML content={message?.content} />
                  <MessageImage message={message.content} />
                </div>
              )
          )}
        {isTyping && (
          <div className={styles.botResponse}>
            <Logo />
            <p>Bot is typing...</p>
          </div>
        )}
      </div>
      <div className={styles.wrapper}>
        <textarea
          disabled={step < 2}
          ref={textareaRef}
          className={styles.textarea}
          rows={1}
          placeholder={"Type your message..."}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyPress={handleKeyPress}
        ></textarea>
        <div
          onClick={handleSend}
          className={`${styles.icon} ${
            isTyping || inputValue === "" ? styles.active : ""
          }`}
        >
          <Send />
        </div>
      </div>
      <Modal
        title={"Error"}
        description={errorText}
        setIsShowModal={setIsModalVisible}
        isShowModal={isModalVisible}
      />
    </div>
  );
};
