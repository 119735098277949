export const EDGE_CONDITION_TYPES = {
  reply: "reply",
  silence: "silence",
};

export const ANALYSIS_DEPTH_OPTIONS = [
  { label: "Last 1 message", value: 1 },
  { label: "Last 2 messages", value: 2 },
  { label: "Last 3 messages", value: 3 },
  { label: "Last 4 messages", value: 4 },
  { label: "Last 5 messages", value: 5 },
  { label: "Last 6 messages", value: 6 },
  { label: "Last 7 messages", value: 7 },
  { label: "Last 8 messages", value: 8 },
  { label: "Last 9 messages", value: 9 },
  { label: "Last 10 messages", value: 10 },
  { label: "Last 20 messages", value: 20 },
  { label: "All messages", value: 100 },
];

export const NODE_TYPES = {
  start: "start",
  branch: "branch",
};

export const ALL_STATUSES = "All statuses";
