import { Bar } from "react-chartjs-2";
import {
  Chart,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

Chart.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
  },
};

const convertDate = (date) => {
  const dateObj = new Date(date);
  const day = String(dateObj.getDate()).padStart(2, "0");
  const month = String(dateObj.getMonth() + 1).padStart(2, "0"); // Месяцы в JavaScript начинаются с 0
  const year = dateObj.getFullYear();
  return `${day}.${month}.${year}`;
};

const getUniqueLabels = (arr) => {
  const dateMap = new Map();
  arr.forEach((date) => {
    const dateObj = convertDate(date.created_at);
    dateMap.set(dateObj, dateObj);
  });
  return Array.from(dateMap.values());
};

const getUniqueData = (data, key) => {
  const dateMap = new Map();

  data.forEach((item) => {
    const formattedDate = convertDate(item.created_at);

    if (dateMap.has(formattedDate)) {
      dateMap.set(formattedDate, dateMap.get(formattedDate) + item[key]);
    } else {
      dateMap.set(formattedDate, item[key]);
    }
  });

  return Array.from(dateMap.values());
};

const ChartsVertical2 = ({ chatTokens }) => {
  const data = {
    labels: getUniqueLabels(chatTokens),
    datasets: [
      {
        label: "Prompt",
        data: getUniqueData(chatTokens, "prompt_tokens"),
        backgroundColor: "rgba(53, 162, 235, 0.5)",
      },
      {
        label: "Completion",
        data: getUniqueData(chatTokens, "completion_tokens"),
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };
  return (
    <div
      style={{
        display: "flex",
        flex: 1,
        width: "100%",
        maxWidth: "80vw",
      }}
    >
      <Bar options={options} data={data} />
    </div>
  );
};

export default ChartsVertical2;
