import styles from "./DropDownMenu.module.scss";
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { FaChevronDown } from "react-icons/fa";
import { FiPlus } from "react-icons/fi";

const DropDownMenu = ({
  value,
  items,
  label,
  chipBtnText,
  formBtnAction,
  formLabel,
  formBtnLabel,
  handleSelect,
}) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const [isOpenForm, setIsOpenForm] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [menuPosition, setMenuPosition] = useState({ top: 0, left: 0 });
  const portalContainerRef = useRef();
  const buttonRef = useRef();
  const formRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        portalContainerRef.current &&
        !portalContainerRef.current.contains(event.target)
      )
        setIsOpenMenu(false);

      if (formRef.current && !formRef.current.contains(event.target))
        setIsOpenForm(false);
    };

    if (isOpenMenu || isOpenForm)
      document.addEventListener("mousedown", handleClickOutside);
    else document.removeEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpenMenu, isOpenForm]);

  useEffect(() => {
    if (isOpenMenu && buttonRef.current) {
      const rect = buttonRef.current.getBoundingClientRect();
      setMenuPosition({
        top: rect.bottom + window.scrollY + 5,
        left: rect.left + window.scrollX,
        width: rect.width,
      });
    }
  }, [isOpenMenu]);

  const handleFormAction = (inputValue) => {
    formBtnAction(inputValue);
    setInputValue("");
    setIsOpenForm(false);
  };

  const onSelect = (selectedValue) => {
    handleSelect(selectedValue);
    setIsOpenMenu(false);
  };

  return (
    <div className={styles.dropDownContainer}>
      <div className={styles.label}>
        <p>{label}</p>
        {chipBtnText && (
          <button
            className={styles.chipBtn}
            onClick={() => setIsOpenForm(!isOpenForm)}
          >
            <FiPlus size={10} />
            <p>{chipBtnText}</p>
          </button>
        )}
      </div>
      <div className={styles.flex}>
        <button
          ref={buttonRef}
          className={styles.btn}
          onClick={() => setIsOpenMenu(!isOpenMenu)}
        >
          {value}
          <FaChevronDown size={14} className={styles.icon} />
        </button>
        {isOpenForm && (
          <div className={styles.formContainer} ref={formRef}>
            <p>{formLabel}</p>
            <input
              type="text"
              onChange={({ target: { value } }) => setInputValue(value)}
              value={inputValue}
            />
            <button
              disabled={!inputValue}
              onClick={() => handleFormAction(inputValue)}
            >
              {formBtnLabel}
            </button>
          </div>
        )}
      </div>
      {isOpenMenu &&
        createPortal(
          <div
            className={styles.container}
            ref={portalContainerRef}
            style={{
              top: `${menuPosition.top}px`,
              left: `${menuPosition.left}px`,
              width: `${menuPosition.width}px`,
            }}
          >
            {items.length > 0 ? (
              items.map((item) => (
                <button
                  className={styles.action}
                  onClick={() => onSelect(item)}
                  key={item}
                >
                  <p>{item}</p>
                </button>
              ))
            ) : (
              <p className={styles.action}>Not yet...</p>
            )}
          </div>,
          document.body
        )}
    </div>
  );
};

export default DropDownMenu;
