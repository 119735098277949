import { useState } from "react";
import { useOptimizeMutation } from "../store/api";
import { useDispatch } from "react-redux";
import { toggleDisableBody } from "../store/slices/disableBodySlice";

export const useOptimizeInstructions = (account_id, assistant_id) => {
  const dispatch = useDispatch();
  const [roleAndInstructions, setRoleAndInstructions] = useState("");
  const [suggestInstractions, setSuggestInstractions] = useState("");
  const [isOptimizeInstructions, setIsOptimizeInstructions] = useState(false);
  const [errorText, setErrorText] = useState("");
  const [isShowModal, setIsShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isInstructionsPopupOpen, setIsInstructionsPopupOpen] = useState(false);

  const [createOptimize] = useOptimizeMutation();

  const handleOptimize = async () => {
    setLoading(true);
    try {
      const { data, error } = await createOptimize({
        account_id,
        assistant_id,
        prompt: roleAndInstructions,
      });
      setErrorText(error);
      setIsOptimizeInstructions(true);
      setSuggestInstractions(data.prompt);
    } catch (error) {
      setIsOptimizeInstructions(false);
      setIsShowModal(true);
      setErrorText("Failed to optimize, try again");
    } finally {
      setLoading(false);
    }
  };

  const saveSuggestInstructions = () => {
    setIsOptimizeInstructions(false);
    dispatch(toggleDisableBody(false));
    setRoleAndInstructions(suggestInstractions);
  };

  return {
    roleAndInstructions,
    setRoleAndInstructions,
    suggestInstractions,
    setSuggestInstractions,
    isOptimizeInstructions,
    setIsOptimizeInstructions,
    errorText,
    isShowModal,
    setIsShowModal,
    loading,
    isInstructionsPopupOpen,
    setIsInstructionsPopupOpen,
    handleOptimize,
    saveSuggestInstructions,
  };
};
