export const KnowledgeBase = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.1517 5.18104C17.1517 5.00202 17.0806 4.83033 16.954 4.70375C16.8275 4.57716 16.6558 4.50604 16.4767 4.50604H9.53474C9.35572 4.50604 9.18403 4.57716 9.05744 4.70375C8.93086 4.83033 8.85974 5.00202 8.85974 5.18104C8.85974 5.36006 8.93086 5.53175 9.05744 5.65834C9.18403 5.78493 9.35572 5.85604 9.53474 5.85604H16.4767C16.5654 5.85604 16.6532 5.83858 16.7351 5.80466C16.8169 5.77074 16.8914 5.72102 16.954 5.65834C17.0167 5.59566 17.0664 5.52125 17.1004 5.43935C17.1343 5.35746 17.1517 5.26968 17.1517 5.18104ZM9.53474 7.42054C9.35572 7.42054 9.18403 7.49166 9.05744 7.61825C8.93086 7.74483 8.85974 7.91652 8.85974 8.09554C8.85974 8.27456 8.93086 8.44625 9.05744 8.57284C9.18403 8.69943 9.35572 8.77054 9.53474 8.77054H12.8977C13.0768 8.77054 13.2485 8.69943 13.375 8.57284C13.5016 8.44625 13.5727 8.27456 13.5727 8.09554C13.5727 7.91652 13.5016 7.74483 13.375 7.61825C13.2485 7.49166 13.0768 7.42054 12.8977 7.42054H9.53474Z"
        fill="#384157"
        stroke="currentColor"
        strokeWidth="1"
      />
      <path
        d="M14.3025 19.6193H4.125C2.10225 19.6193 2.08275 16.47 4.125 16.47H12.7725C12.9515 16.47 13.1232 16.3989 13.2498 16.2723C13.3764 16.1457 13.4475 15.974 13.4475 15.795C13.4475 15.616 13.3764 15.4443 13.2498 15.3177C13.1232 15.1911 12.9515 15.12 12.7725 15.12H6.6285V2.17503H18.2325C18.7822 2.17503 19.23 2.62278 19.23 3.17253V10.425C19.23 10.6041 19.3011 10.7757 19.4277 10.9023C19.5543 11.0289 19.726 11.1 19.905 11.1C20.084 11.1 20.2557 11.0289 20.3823 10.9023C20.5089 10.7757 20.58 10.6041 20.58 10.425V3.17253C20.5794 2.54992 20.3318 1.95298 19.8916 1.51272C19.4513 1.07247 18.8544 0.824875 18.2317 0.82428H4.125C3.34954 0.825668 2.60624 1.13439 2.05798 1.68279C1.50971 2.2312 1.20119 2.97457 1.2 3.75003V18.045C1.2 19.6575 2.5125 20.97 4.125 20.97H14.3025C14.4816 20.97 14.6534 20.8989 14.7801 20.7722C14.9067 20.6456 14.9779 20.4738 14.9779 20.2947C14.9779 20.1155 14.9067 19.9437 14.7801 19.8171C14.6534 19.6904 14.4816 19.6193 14.3025 19.6193ZM2.55 3.75003C2.55 2.88153 3.2565 2.17503 4.125 2.17503H5.27925V15.1193C4.338 15.1193 3.38325 15.0488 2.55 15.579V3.75003Z"
        fill="#384157"
        stroke="currentColor"
        strokeWidth="1"
      />
      <path
        d="M21.7627 19.008C22.4318 18.268 22.8018 17.3056 22.8007 16.308C22.8022 15.7754 22.6978 15.2478 22.4936 14.7558C22.2894 14.2638 21.9895 13.8174 21.6112 13.4423C21.2376 13.0664 20.7931 12.7683 20.3035 12.5653C19.814 12.3623 19.289 12.2584 18.759 12.2595H18.7463C17.6685 12.2595 16.656 12.6795 15.8917 13.4423C15.5137 13.8175 15.214 14.264 15.0098 14.7559C14.8056 15.2479 14.7011 15.7754 14.7022 16.308C14.7022 17.3078 15.0712 18.2663 15.7402 19.0065C16.0687 19.3718 16.2495 19.8233 16.2495 20.2763C16.2472 20.7338 16.2488 21.1905 16.2488 21.648C16.2495 22.0528 16.4107 22.4407 16.6969 22.7269C16.9831 23.0131 17.371 23.1742 17.7757 23.175H19.728C20.1327 23.1742 20.5207 23.0131 20.8069 22.7269C21.0931 22.4407 21.2542 22.0528 21.255 21.648C21.255 21.1905 21.2565 20.733 21.2543 20.2763C21.2543 19.8225 21.435 19.3718 21.7627 19.008ZM16.053 16.308C16.053 15.5865 16.3342 14.9078 16.8457 14.397C17.0951 14.1464 17.3916 13.9478 17.7182 13.8126C18.0448 13.6775 18.395 13.6084 18.7485 13.6095H18.7568C19.4738 13.6095 20.148 13.8885 20.6572 14.397C20.9093 14.6472 21.1092 14.945 21.2453 15.273C21.3813 15.6011 21.4509 15.9529 21.45 16.308C21.4511 16.9715 21.2051 17.6115 20.76 18.1035C20.3433 18.5616 20.0632 19.1272 19.9515 19.7363H17.5522C17.4399 19.127 17.1596 18.5613 16.743 18.1028C16.2981 17.611 16.0521 16.9712 16.053 16.308ZM19.728 21.825H17.7757C17.729 21.825 17.6842 21.8065 17.6511 21.7734C17.6181 21.7404 17.5995 21.6955 17.5995 21.6488V21.0885H19.9042V21.6488C19.9042 21.6955 19.8857 21.7404 19.8526 21.7734C19.8196 21.8065 19.7747 21.825 19.728 21.825Z"
        fill="#384157"
        stroke="currentColor"
        strokeWidth="1"
      />
    </svg>
  );
};
