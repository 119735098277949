import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  disable: false,
};

const disableBodySlice = createSlice({
  name: "disableBody",
  initialState,
  reducers: {
    toggleDisableBody: (state, action) => {
      state.disable = action.payload;
    },
  },
});
export const { toggleDisableBody } = disableBodySlice.actions;

export const selectDisableBody = (state) => state.disableBody.disable;

export default disableBodySlice.reducer;
