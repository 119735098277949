export const messagesSuccess =
  "Payment was successful, your token balance will be updated soon 🎉";

export const messageFail =
  "There was an error processing your payment, please contact our support team 😞";

export const messagePayment =
  "Please, use mychatbot login email as an 'email' during payment";

export const errorMesAssistantState = "Failed to update assistant state";

export const errorMesPayment =
  "Payment failed, please contact us at team@mychatbot.app";

export const tokensPerMessage = 10000;

export const validateIntegrationTimeOut =  "Index is being updated, this may take up to 10 minutes. Please wait."