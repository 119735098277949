import { Button, Typography } from "../../../../../../components";

import styles from "./WpWidgetOptionsButtons.module.scss";
import { BUTTON_SIDE_LEFT, BUTTON_SIDE_RIGHT } from "../../const";
import useCMSWidgetDataContext from "../../useCMSWidgetDataContext";

const WpWidgetOptionsButtons = () => {
  const {
    buttonPosition,
    setButtonPosition,
    buttonShow,
    setButtonShow,
    isDownloaded,
  } = useCMSWidgetDataContext();

  return (
    <div className={styles.optionsButtonsContainer}>
      <Typography variant="h4" className={styles.title}>
        Button position
      </Typography>
      <div className={styles.buttonsContainer}>
        <Button
          variant="contained"
          title="Left side"
          color={buttonPosition === BUTTON_SIDE_LEFT ? "" : "edit"}
          onClick={() => {
            setButtonPosition(BUTTON_SIDE_LEFT);
          }}
          disabled={isDownloaded}
        />
        <Button
          variant="contained"
          title="Right side"
          color={buttonPosition === BUTTON_SIDE_RIGHT ? "" : "edit"}
          onClick={() => {
            setButtonPosition(BUTTON_SIDE_RIGHT);
          }}
          disabled={isDownloaded}
        />
      </div>
      <Typography variant="h4" className={styles.title}>
        Button text
      </Typography>
      <div className={styles.buttonsContainer}>
        <Button
          variant="contained"
          title="Show"
          color={buttonShow ? "" : "edit"}
          onClick={() => setButtonShow(true)}
          disabled={isDownloaded}
        />
        <Button
          variant="contained"
          title="Hide"
          color={!buttonShow ? "" : "edit"}
          onClick={() => setButtonShow(false)}
          disabled={isDownloaded}
        />
      </div>
    </div>
  );
};

export default WpWidgetOptionsButtons;
