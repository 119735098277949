export const COUNTRY_CODES = {
  ua: {
    label: "olx.ua",
    code: "ua",
  },
  pl: {
    label: "olx.pl",
    code: "pl",
  },
  ro: {
    label: "olx.ro",
    code: "ro",
  },
};
