import React, {useEffect, useState} from 'react';
import {useNavigate} from "react-router-dom";

import supabase from "../../supabase";

import {Title} from "../../components/Title";
import {Input} from "../../components/Input";
import {Button} from "../../components/Button";
import signUp from '../../assets/img/signUp.png';

import styles from './RepeatPassword.module.scss';
import {Loader} from "../../components/Loader";

export const RepeatPasswordPage = () => {

    const navigate = useNavigate();

    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [isError, setIsError] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [disabled, setDisabled] = useState(false);

    const handleReseatPassword = async () => {
        setDisabled(true);

        if (password !== repeatPassword) {
            setDisabled(false);
            setIsError(true);
            setErrorText('Passwords do not match');
            return;
        }

        await supabase.auth.updateUser({
            password: password
        }).then((response) => {
            if (response?.data?.user?.aud === 'authenticated') {
                setDisabled(false);
                navigate("/")
            } else if (response?.error?.message) {
                setDisabled(false);
                setIsError(true);
                setErrorText(response?.error?.message);
            }
        })
    }


    useEffect(() => {
        setErrorText('');
        setIsError(false);
    }, [password, repeatPassword]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                handleReseatPassword();
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [handleReseatPassword]);

    return (
        <div className={styles.wrapper}>
            <div className={styles.imgWrapper}>

                <img className={styles.active} src={signUp} alt=""/>
            </div>
            <div className={`${styles.authModal} ${styles.active}`}>
                <Title title={'Reset password'}/>

                <div className={`${styles.forgotPassword} ${styles.active}`}>Enter your new password
                </div>
                <Input typeInput={'default'} type={'password'} value={password} placeholder={'Password'}
                       onChange={e => setPassword(e.target.value)} error={isError}/>
                <Input typeInput={'default'} value={repeatPassword} type={'password'} placeholder={'Repeat password'}
                       onChange={e => setRepeatPassword(e.target.value)} error={isError}
                       errorText={errorText}/>
                {
                    disabled ?
                        <div
                            className={styles.loaderContainer}
                        >
                            <Loader height={30} width={30}/>
                        </div> :
                        <Button className={styles.authButton} onClick={handleReseatPassword} title={'UPDATE PASSWORD'}/>
                }

            </div>
        </div>
    );
};
