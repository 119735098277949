export const Messenger = ({width, height}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_36_1665)">
                <path
                    d="M0 23.1479C0 30.4312 3.63333 36.9292 9.31458 41.1729V50L17.8271 45.3292C20.0979 45.9562 22.5042 46.2979 24.9979 46.2979C38.8042 46.2979 49.9979 35.9354 49.9979 23.15C50 10.3646 38.8062 0 25 0C11.1937 0 0 10.3625 0 23.1479ZM22.3625 16.6646L28.8833 23.4542L41.1479 16.6646L27.4812 31.1687L21.1167 24.3812L8.69375 31.1708L22.3625 16.6646Z"
                    fill="#2196F3"/>
            </g>
            <defs>
                <clipPath id="clip0_36_1665">
                    <rect width="50" height="50" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};
