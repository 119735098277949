import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getData } from "../../service/supabase";
import axios from "axios";

const initialState = {
  followUps: [],
  funnelStatuses: [],
};

export const fetchFollowUps = createAsyncThunk(
  "followUps/fetchFollowUps",
  async (_, { getState }) => {
    const currentUserId = getState().auth.user.account_id;
    const { data } = await axios.get(`/follow-up?account=${currentUserId}`);
    return data;
  }
);

export const fetchFunnelStatuses = createAsyncThunk(
  "followUps/fetchFunnelStatuses",
  async (_, { getState }) => {
    const currentUserId = getState().auth.user.account_id;
    const { data } = await getData(
      "funnel_statuses",
      "status_name",
      "account_id",
      currentUserId
    );
    return data;
  }
);

export const followUpsSlice = createSlice({
  name: "followUps",
  initialState,
  reducers: {
    setFollowUps: (state, action) => {
      state.followUps = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFunnelStatuses.fulfilled, (state, action) => {
      state.funnelStatuses = action.payload;
    });
    builder.addCase(fetchFollowUps.fulfilled, (state, action) => {
      state.followUps = action.payload;
    });
  },
});

export const { setFollowUps } = followUpsSlice.actions;
export const selectFollowUps = (state) => state.followUps.followUps;
export const selectFunnelStatuses = (state) => state.followUps.funnelStatuses;

export default followUpsSlice.reducer;
