import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchIntegrations,
  selectSelectedAssistant,
  selectSelectedIntegrationsIdsByType,
  selectSelectedIntegrationType,
} from "../../../../../../store/slices/integrationSlice";
import {
  fetchInstructions as fetchAssistants,
  selectListOfAssistants,
} from "../../../../../../store/slices/assistantSlice";
import { Button, Input, Typography } from "../../../../../../components";
import useUpdateAssistantIntegrations from "../../../../useUpdateAssistantIntegrations";

import styles from "./ConnectToExistingIntegration.module.scss";
import { useLoadingContext } from "../../../../useLoadingContext";

const ConnectToExistingIntegration = () => {
  const [selectedIntegrationId, setSelectedIntegrationId] = useState(null);

  const { setIsLoading } = useLoadingContext();
  const dispatch = useDispatch();
  const selectedAssistant = useSelector(selectSelectedAssistant);
  const listOfAssistants = useSelector(selectListOfAssistants);
  const selectedIntegrationType = useSelector(selectSelectedIntegrationType);
  const selectedIntegrationsIdsByType = useSelector(
    selectSelectedIntegrationsIdsByType
  );
  const {
    addAssistantIntegration,
    currentTypeAssistantIntegrationId,
    removeAssistantIntegration,
  } = useUpdateAssistantIntegrations();

  useEffect(() => {
    setSelectedIntegrationId(null);
  }, [selectedAssistant, selectedIntegrationType]);

  const existingIntegrationsOptions = useMemo(() => {
    if (!listOfAssistants || !selectedAssistant || !selectedIntegrationType) {
      return [];
    }
    const possibleAssistants = listOfAssistants.filter(
      (assistant) => assistant.id !== selectedAssistant.id
    );

    const result = {};

    possibleAssistants.forEach((assistant) => {
      selectedIntegrationsIdsByType.forEach((integrationId) => {
        if (!assistant?.integrations) {
          return;
        }
        if (
          assistant?.integrations?.includes(integrationId) &&
          currentTypeAssistantIntegrationId !== integrationId
        ) {
          if (!result[integrationId]) {
            result[integrationId] = {
              label: assistant.bot_name,
              value: integrationId,
            };
          } else {
            result[integrationId].label += `, ${assistant.bot_name}`;
          }
        }
      });
    });

    return Object.values(result);
  }, [listOfAssistants, selectedAssistant, selectedIntegrationType]);

  const onIntegrationChange = useCallback((selectedIntegration) => {
    setSelectedIntegrationId(selectedIntegration.value);
  }, []);

  const existingIntegrationInputValue = useMemo(() => {
    return selectedIntegrationId
      ? {
          value: selectedIntegrationId,
          label: listOfAssistants.find((assistant) =>
            assistant?.integrations?.includes(selectedIntegrationId)
          )?.bot_name,
        }
      : null;
  }, [selectedIntegrationId]);

  const handleChooseIntegration = useCallback(async () => {
    setIsLoading(true);
    let updatedIntegrations = null;
    if (currentTypeAssistantIntegrationId) {
      updatedIntegrations = await removeAssistantIntegration(
        currentTypeAssistantIntegrationId
      );
    }
    await addAssistantIntegration(selectedIntegrationId, updatedIntegrations);
    await dispatch(fetchIntegrations());
    await dispatch(fetchAssistants());
    setIsLoading(false);
  }, [selectedIntegrationId]);

  if (existingIntegrationsOptions.length === 0) {
    return null;
  }

  return (
    <div className={styles.container}>
      <Typography variant="caption2">
        You can choose from established connection
      </Typography>
      <Input
        typeInput="select"
        options={existingIntegrationsOptions}
        placeholder="Select Existing Connection"
        value={existingIntegrationInputValue || ""}
        onChange={onIntegrationChange}
      />
      <Button
        variant="contained"
        title="Choose this integration"
        className={styles.connectButton}
        onClick={handleChooseIntegration}
        disabled={!selectedIntegrationId}
      />
    </div>
  );
};

export default ConnectToExistingIntegration;
