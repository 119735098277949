import styles from "./Chat.styles.module.scss";
import ChatContextProvider from "./context/ChatContextProvider";
import { Outlet } from "react-router-dom";
import ChatList from "./components/ChatList";
import ClientCard from "./components/ClientCard";

const DesktopChatV2 = () => {
  return (
    <ChatContextProvider>
      <div className={styles.chatsContainer}>
        <ChatList />
        <Outlet />
        <ClientCard />
      </div>
    </ChatContextProvider>
  );
};

export default DesktopChatV2;
