import { useEffect } from "react";
import { checkAuthorizationStatus } from "../../../../service/telegramService";
import { toast } from "react-toastify";

// Handles the polling logic to check if the authorization process is complete
export const useAuthorizationPolling = (
  waitingForAuthorization,
  params,
  onAuthorizationSuccess,
  onAuthorizationFailure,
  initialAccessToken
) => {
  useEffect(() => {
    let interval;
    let timeout;
    if (waitingForAuthorization) {
      interval = setInterval(async () => {
        if (!waitingForAuthorization || !params.userId || !params.phone) {
          return;
        }

        try {
          const data = await checkAuthorizationStatus(
            params.userId,
            params.assistantId,
            params.phone,
            params.tgAccountName
          );

          if (data && data.length > 0) {
            const channel = data[0];
            // Check if 'access_token' has changed
            if (channel.page_id && channel.page_id !== "") {
              onAuthorizationSuccess();

              clearInterval(interval);
              clearTimeout(timeout); // Clear the timeout if authorization succeeds
            }
          }
        } catch (error) {
          console.error("Error checking authorization status:", error);
          toast.success("Connection problem, please try again.");

          clearInterval(interval);
          clearTimeout(timeout);
          onAuthorizationFailure();
        }
      }, 5000);

      // Set a timeout of 60 seconds
      timeout = setTimeout(() => {
        clearInterval(interval);
        onAuthorizationFailure();
      }, 60000);
    }
    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [
    waitingForAuthorization,
    params,
    onAuthorizationSuccess,
    onAuthorizationFailure,
    initialAccessToken,
  ]);
};
