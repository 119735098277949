import { useState } from "react";
import { useDispatch } from "react-redux";
import { getAltegToken } from "../../../../../../store/slices/integrationSlice";
import { toast } from "react-toastify";

export const useAltegioToken = ({
  fields,
  updateField,
  selectedIntegrationName,
}) => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const dispatch = useDispatch();

  const handleLoginSubmit = async () => {
    try {
      const partnerToken = fields.partnerToken;
      const companyId = fields.companyId;
      const chainId = fields.chainId;

      const response = await dispatch(
        getAltegToken({ partnerToken, login, password })
      ).unwrap();

      const { user_token, name } = response.data;
      // Update the fields with userAccessToken and userName
      updateField(selectedIntegrationName, "partnerToken", partnerToken);
      updateField(selectedIntegrationName, "userAccessToken", user_token);
      updateField(selectedIntegrationName, "userName", name);
      updateField(selectedIntegrationName, "companyId", companyId);
      updateField(selectedIntegrationName, "chainId", chainId);

      toast.success("Successfully obtained User Access Token!");
      setIsPopupOpen(false);
    } catch (error) {
      toast.error("Failed to obtain User Access Token.");
    }
  };

  return {
    login,
    setLogin,
    password,
    setPassword,
    isPopupOpen,
    setIsPopupOpen,
    handleLoginSubmit,
  };
};
