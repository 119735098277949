import React, { useEffect, useMemo } from "react";
import { ROUTES } from "../../../../constants/routes";
import { FaRegBell } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { TbAlertSquareFilled } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchNotifications,
  selectAllNotifications,
} from "../../../../store/slices/notificationsSlice";

import styles from "../Navigation.module.scss";
import mobileStyles from "../../MobileNavigator/MobileNavigator.module.scss";

const Notifications = ({ isMobile = false, setIsSidebarOpen = () => {} }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const notifications = useSelector(selectAllNotifications);

  useEffect(() => {
    dispatch(fetchNotifications());
  }, []);

  const hasNewNotifications = useMemo(
    () => notifications?.some((notification) => notification.status === "new"),
    [notifications]
  );

  if (isMobile) {
    return (
      <Link
        to={ROUTES.notifications}
        className={`${mobileStyles.navigation} ${
          location.pathname === ROUTES.notifications
            ? mobileStyles.navigationActive
            : ""
        }`}
        onClick={() => {
          setIsSidebarOpen(false);
        }}
      >
        <FaRegBell
          style={{
            flexShrink: 0,
          }}
        />
        Notifications
        {hasNewNotifications ? (
          <div className={styles.newIconWrapper}>
            <TbAlertSquareFilled
              className={styles.newNotifications}
              size={24}
            />
          </div>
        ) : null}
      </Link>
    );
  }

  return (
    <Link
      to={ROUTES.notifications}
      className={`${styles.navigation} ${
        location.pathname === ROUTES.notifications
          ? styles.navigationActive
          : ""
      }`}
    >
      <FaRegBell
        style={{
          flexShrink: 0,
        }}
      />
      Notifications
      {hasNewNotifications ? (
        <div className={styles.newIconWrapper}>
          <TbAlertSquareFilled className={styles.newNotifications} size={24} />
        </div>
      ) : null}
    </Link>
  );
};

export default Notifications;
