import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Button, Typography } from "../../../../../../components";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  selectAvailableIntegrations,
  selectSelectedAssistant,
  selectSelectedIntegrationsIdsByType,
  selectSelectedIntegrationType,
  selectAltegioToken,
  selectAltegioUserName,
  clearAltegioToken,
} from "../../../../../../store/slices/integrationSlice";
import { useIntegrationChecks } from "../useIntegrationChecks";
import useKeyConnectionData from "./useKeyConnectionData";
import styles from "../../IntegrationSettings.module.scss";
import { INTEGRATION_NAMES } from "../../../../../../constants/integrations";
import { REQUIRED_FIELDS } from "../../../../../../constants/integrations";
import RenderIntegrationService from "../RenderIntegrationService";
import { useAltegioToken } from "./useAltegioToken";
import { AltegioLoginPopup } from "../../../../../../components/popups/AltegioLoginPopup/AltegioLoginPopup";
import { fetchIntegrations } from "../../../../../../store/slices/integrationSlice";
import useUpdateIntegrationData from "../../../../useUpdateIntegrationData";

const KeyConnection = () => {
  const [isDataSent, setIsDataSent] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    createConnection,
    updateField,
    getFieldsForIntegration,
    resetFieldsForIntegration,
    setIntegrationFields,
    integrationFields,
  } = useKeyConnectionData();

  const { isAuthTypeApiRefreshToken, isAltegio, isBitrix } =
    useIntegrationChecks();

  const dispatch = useDispatch();

  const selectedIntegrationType = useSelector(selectSelectedIntegrationType);
  const selectedIntegrationName = selectedIntegrationType?.name;

  const selectedAssistant = useSelector(selectSelectedAssistant);
  const availableIntegrations = useSelector(selectAvailableIntegrations);
  const selectedIntegrationsIdsByType = useSelector(
    selectSelectedIntegrationsIdsByType
  );

  // Use useMemo to recompute fields when dependencies change
  const fields = useMemo(
    () => getFieldsForIntegration(selectedIntegrationName),
    [integrationFields, selectedAssistant, selectedIntegrationName]
  );

  const {
    login,
    setLogin,
    password,
    setPassword,
    isPopupOpen,
    setIsPopupOpen,
    handleLoginSubmit,
  } = useAltegioToken({
    fields,
    partnerToken: fields.partnerToken,
    updateField,
    selectedIntegrationName,
  });

  const handleLogin = () => {
    setIsPopupOpen(true);
  };

  const altegioToken = useSelector(selectAltegioToken);
  const altegioUserName = useSelector(selectAltegioUserName);

  const { deleteIntegration } = useUpdateIntegrationData();

  const handleDisconnectAltegio = async () => {
    try {
      const integrationId = availableIntegrations.find(
        ({ integration }) => integration === INTEGRATION_NAMES.ALTEGIO
      )?.id;

      if (integrationId) {
        await deleteIntegration(integrationId);

        dispatch(clearAltegioToken());

        updateField(INTEGRATION_NAMES.ALTEGIO, "partnerToken", "");
        updateField(INTEGRATION_NAMES.ALTEGIO, "companyId", "");
        updateField(INTEGRATION_NAMES.ALTEGIO, "chainId", "");

        resetFieldsForIntegration(INTEGRATION_NAMES.ALTEGIO);

        toast.success("Altegio credentials have been successfully removed!");

        dispatch(fetchIntegrations());
      } else {
        toast.error("Altegio integration not found.");
      }
    } catch (error) {
      toast.error("Failed to disconnect Altegio.");
    }
  };

  const isAssistantConnected = useMemo(() => {
    return selectedAssistant?.integrations?.some((integrationId) =>
      selectedIntegrationsIdsByType.includes(integrationId)
    );
  }, [selectedAssistant, selectedIntegrationsIdsByType, availableIntegrations]);

  const filteredIntegration = useMemo(() => {
    if (!selectedAssistant || !selectedAssistant.integrations) {
      return null;
    }

    return availableIntegrations.find(
      ({ integration, id }) =>
        integration === selectedIntegrationName &&
        selectedAssistant.integrations.includes(id)
    );
  }, [availableIntegrations, selectedAssistant.id, selectedIntegrationName]);

  const isIntegrationConnected = useMemo(() => {
    if (isBitrix && filteredIntegration) {
      return (
        filteredIntegration.access_token !== "placeholder_access_token" &&
        filteredIntegration.refresh_token !== "placeholder_refresh_token"
      );
    }
    return false;
  }, [isBitrix, filteredIntegration]);

  useEffect(() => {
    if (filteredIntegration) {
      const existingFields = {};

      switch (selectedIntegrationName) {
        case INTEGRATION_NAMES.KEYCRM:
          existingFields.apiKey = filteredIntegration?.access_token || "";
          existingFields.crmPipelineId =
            filteredIntegration?.metadata?.crm_pipeline_id || "";
          break;

        case INTEGRATION_NAMES.ALTEGIO:
          existingFields.partnerToken = filteredIntegration?.access_token || "";
          existingFields.companyId =
            filteredIntegration?.metadata?.company_id || "";
          existingFields.chainId =
            filteredIntegration?.metadata?.chain_id || "";

          if (altegioToken) {
            existingFields.userAccessToken = altegioToken;
            existingFields.userName = altegioUserName;
          }

          break;

        case INTEGRATION_NAMES.BITRIX:
          existingFields.accessTokenBitrix =
            filteredIntegration?.access_token || "";
          existingFields.refreshTokenBitrix =
            filteredIntegration?.refresh_token || "";
          existingFields.domain = filteredIntegration?.metadata?.domain || "";
          existingFields.clientId =
            filteredIntegration?.metadata?.client_id || "";
          existingFields.clientSecret =
            filteredIntegration?.metadata?.client_secret || "";

          break;

        case INTEGRATION_NAMES.WLAUNCH:
          existingFields.apiKey = filteredIntegration?.access_token || "";
          existingFields.refreshToken =
            filteredIntegration?.refresh_token || "";

          break;

        default:
          break;
      }

      setIntegrationFields((prev) => ({
        ...prev,
        [selectedAssistant.id]: {
          ...(prev[selectedAssistant.id] || {}),
          [selectedIntegrationName]: existingFields,
        },
      }));
    } else {
      resetFieldsForIntegration(selectedIntegrationName);
    }
  }, [
    selectedIntegrationType,
    filteredIntegration,
    selectedIntegrationName,
    setIntegrationFields,
    resetFieldsForIntegration,
    altegioToken,
    altegioUserName,
    selectedAssistant.id,
  ]);

  const handleSubmit = async () => {
    if (!isSubmitting) {
      setIsSubmitting(true);
      try {
        await createConnection();
        setIsDataSent(true);
        setIsSubmitting(false);
      } catch (error) {
        toast.error("Error submitting data");
        setIsSubmitting(false);
      }
    }
  };

  const isLoginDisabled = () => {
    return !fields.partnerToken || !fields.companyId || !fields.chainId;
  };
  const isSubmitDisabled = () => {
    const integrationName = selectedIntegrationName;
    const requiredFields = REQUIRED_FIELDS[integrationName] || ["apiKey"];

    return (
      requiredFields.some((field) => !fields[field]) ||
      isSubmitting ||
      isAssistantConnected ||
      isIntegrationConnected
    );
  };

  return (
    <div className={styles.newConnection}>
      {
        <Typography variant="caption2">
          Create new "{selectedIntegrationType.name}" integration{" "}
          {isBitrix
            ? "by inserting required credentials"
            : isAltegio
            ? "by logging in to obtain the User Access Token and entering the required credentials"
            : isAuthTypeApiRefreshToken
            ? "by pasting a new Partner Token and User Access Token"
            : "by pasting a new API key"}
        </Typography>
      }
      <div
        className={`${styles.apiInputWrapper} ${
          isAltegio || isBitrix ? styles.columnDirection : ""
        }`}
      >
        {isAltegio && altegioToken && (
          <Button
            title="Disconnect Altegio"
            variant="contained"
            onClick={handleDisconnectAltegio}
            className={styles.actionBtn}
          />
        )}
        <RenderIntegrationService
          selectedIntegrationName={selectedIntegrationName}
          fields={fields}
          handleInputChange={(field) => (e) =>
            updateField(selectedIntegrationName, field, e.target.value)}
          isAuthTypeApiRefreshToken={isAuthTypeApiRefreshToken}
          isLoginDisabled={isLoginDisabled}
          isSubmitDisabled={isSubmitDisabled}
          handleLogin={handleLogin}
          createConnection={createConnection}
          isIntegrationConnected={isIntegrationConnected}
          filteredIntegration={filteredIntegration}
        />

        {
          <Button
            variant="contained"
            title="Submit"
            onClick={handleSubmit}
            disabled={isSubmitDisabled()}
            className={styles.actionBtn}
          />
        }
      </div>
      <AltegioLoginPopup
        isPopupOpen={isPopupOpen}
        setIsPopupOpen={setIsPopupOpen}
        login={login}
        setLogin={setLogin}
        password={password}
        setPassword={setPassword}
        handleLoginSubmit={handleLoginSubmit}
      />
    </div>
  );
};

export default KeyConnection;
