import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const subscriptionApi = createApi({
    reducerPath: 'subscriptionApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        credentials: 'include',
    }),
    endpoints: (builder) => ({
            subscription: builder.mutation({
                query: ({currentSubscription}) => ({
                    url: `/subscriptions/${currentSubscription.order_id}`,
                    method: 'DELETE',
                    credentials: 'include',
                    body: {
                    },
                }),
            }),
            upgrade: builder.mutation({
                query: ({currentSubscription, plan}) => ({
                    url: `/subscriptions/${currentSubscription.order_id}`,
                    method: 'PATCH',
                    credentials: 'include',
                    body: {
                        upgrade_to: plan,
                    },
                }),
            }),
        }
    ),
});

export const {
    useSubscriptionMutation,
    useUpgradeMutation,
} = subscriptionApi;
