import { useCallback } from "react";
import { useSelector } from "react-redux";
import { FaRegCopy } from "react-icons/fa";
import { Input, Typography } from "../../../../components";
import { useGetReferralInviteLinkQuery } from "../../../../store/api";
import { selectUser } from "../../../../store/slices/authSlice";
import { toast } from "react-toastify";

import styles from "./InviteViaLink.module.scss";

const InviteViaLink = () => {
  const user = useSelector(selectUser);

  const { data: inviteLink } = useGetReferralInviteLinkQuery(user?.account_id);

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(inviteLink?.link);
    toast.success("Link copied to clipboard");
  }, [inviteLink]);

  return (
    <div className={styles.container}>
      <Typography variant="h5">Invite people via link</Typography>
      <Typography variant="body3" className={styles.description}>
        Copy and post the link on your social media or blog to attract as many
        users and bonuses as possible. It would be great if you write a few
        words about MyChatBot in the description of the link.
      </Typography>
      <div className={styles.inputWrapper}>
        <Input
          value={inviteLink?.link || ""}
          customClass={styles.input}
          disabled
          onChange={() => {}}
        />
        <FaRegCopy size={18} className={styles.icon} onClick={handleCopy} />
      </div>
    </div>
  );
};

export default InviteViaLink;
