import { useEffect, useState } from "react";
import supabase from "../../supabase";

export const useSubscription = (user) => {
  const [subscriptionPlan, setSubscriptionPlan] = useState(null);
  const [isFetchingSubscription, setIsFetchingSubscription] = useState(true);
  const [isTrial, setIsTrial] = useState(false);

  useEffect(() => {
    if (!user?.email) return;

    const fetchSubscription = async () => {
      try {
        setIsFetchingSubscription(true);
        const { data, error } = await supabase
          .from("subscriptions")
          .select("subscription_plan")
          .eq("email", user.email)
          .single();

        if (error) {
          console.error("Failed to fetch subscription plan:", error);
          return;
        }

        let plan = data?.subscription_plan;
        let trial = false;
        if (plan?.endsWith("-trial")) {
          trial = true;
          plan = plan.replace("-trial", "");
        }

        setSubscriptionPlan(plan);
        setIsTrial(trial);
      } catch (err) {
        console.error(err);
      } finally {
        setIsFetchingSubscription(false);
      }
    };

    fetchSubscription();
  }, [user]);

  return { subscriptionPlan, isTrial, isFetchingSubscription };
};
