import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const referralProgramApi = createApi({
  reducerPath: "referralProgramApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    credentials: "include",
  }),
  endpoints: (build) => ({
    getReferralStatistics: build.query({
      query: ( userId ) => ({
        url: `/referral-statistics?account=${userId}`,
        method: "GET",
        credentials: "include",
      }),
    }),
    getReferralInviteLink: build.query({
      query: ( userId ) => ({
        url: `/referral-invite-link?account=${userId}`,
        method: "GET",
        credentials: "include",
      }),
    }),
    inviteByEmail: build.mutation({
      query: ({ userId, email }) => ({
        url: "/referral-invite-by-email",
        method: "POST",
        credentials: "include",
        body: {
          account_id: userId,
          email: email,
        },
      }),
    }),
    sendTokens: build.mutation({
      query: ({ userId, email, tokens }) => ({
        url: "/referral-send-tokens",
        method: "POST",
        credentials: "include",
        body: {
          account_id: userId,
          email: email,
          tokens: tokens,
        },
      }),
    }),
  }),
});

export const {
  useGetReferralStatisticsQuery,
  useGetReferralInviteLinkQuery,
  useInviteByEmailMutation,
  useSendTokensMutation,
} = referralProgramApi;
