import {
  ANALYSIS_DEPTH_OPTIONS,
  EDGE_CONDITION_TYPES,
  NODE_TYPES,
} from "./const";

function convertToSeconds({ hours, minutes, seconds }) {
  return hours * 3600 + minutes * 60 + seconds;
}

function convertToTime(seconds) {
  if (!seconds) {
    return { hours: 0, minutes: 0, seconds: 0 };
  }
  const hours = Math.floor(seconds / 3600);
  seconds %= 3600;
  const minutes = Math.floor(seconds / 60);
  seconds %= 60;

  return { hours, minutes, seconds };
}

function getUpdatedStartNodeConditions(nodes) {
  const startNode = nodes.find((node) => node.type === NODE_TYPES.start);

  if (startNode && startNode.conditions) {
    return startNode.conditions.map((condition) => {
      if (
        condition.trigger === EDGE_CONDITION_TYPES.silence &&
        condition.silence
      ) {
        return {
          ...condition,
          silence: convertToSeconds(condition.silence),
        };
      }
      return condition;
    });
  }

  return [];
}

export function convertNodesAndEdges(nodes, edges) {
  const nodeMap = new Map();

  const startNodeConditions = getUpdatedStartNodeConditions(nodes);

  nodes.forEach((node) => {
    nodeMap.set(node.id, {
      type: node.type,
      node: parseInt(node.id),
      edges: [],
      name: node.data.label,
      prompt: node.data.prompt,
      conditions: node.type === NODE_TYPES.start ? startNodeConditions : [],
      chat_depth:
        ANALYSIS_DEPTH_OPTIONS.find(
          (option) => option.label === node.data.depth,
        )?.value || 0,
      position: node.position,
    });
  });

  edges.forEach((edge) => {
    const sourceNode = nodeMap.get(edge.source);
    const targetNode = nodeMap.get(edge.target);

    if (sourceNode && targetNode) {
      sourceNode.edges.push(parseInt(edge.target));

      if (edge.data && edge.data.condition) {
        let condition = {
          edge: parseInt(edge.source),
          trigger: edge.data.condition,
        };
        if (edge.data.condition === "silence" && edge.data.value) {
          condition.silence = convertToSeconds(edge.data.value);
        } else if (edge.data.condition === "reply" && edge.data.value) {
          condition.reply = edge.data.value;
        }
        targetNode.conditions.push(condition);
      }
    }
  });

  return Array.from(nodeMap.values());
}

export function getNextNodeId(nodes) {
  let id = nodes.length + 1;
  while (nodes.some((node) => node.id === id.toString())) {
    id++;
  }
  return id.toString();
}

export function convertCombinedToNodesAndEdges(combined) {
  const nodes = [];

  combined.forEach((item) => {
    const newNode = {
      id: item.node.toString(),
      position: item.position,
      data: {
        label: item.name,
        prompt: item.prompt,
        depth:
          ANALYSIS_DEPTH_OPTIONS.find(
            (option) => option.value === item.chat_depth,
          )?.label || "",
      },
      type: item.type,
    };

    if (item.type === NODE_TYPES.start && item?.conditions?.length) {
      newNode.conditions = [
        {
          ...item.conditions[0],
          silence: convertToTime(item.conditions[0].silence),
        },
      ];
    }

    nodes.push(newNode);
  });

  const edges = combined.flatMap((node) =>
    node.conditions.map((condition) => {
      return {
        source: condition.edge.toString(),
        sourceHandle: null,
        target: node.node.toString(),
        targetHandle: null,
        type: "custom-edge",
        id: `reactflow__edge-${condition.edge}-${node.node}`,
        selected: false,
        data: {
          condition: condition.trigger,
          value:
            condition.trigger === "silence"
              ? convertToTime(condition.silence)
              : condition.reply,
        },
      };
    }),
  );

  return { nodes, edges };
}
