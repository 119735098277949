import React, {useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";

import axios from "axios";

import {login} from "../../store/slices/authSlice";
import supabase from "../../supabase";

import {Title} from "../../components/Title";
import {Input} from "../../components/Input";
import {Button} from "../../components/Button";
import {Loader} from "../../components/Loader";
import signIn from '../../assets/img/signIn.png';
import signUp from '../../assets/img/signUp.png';
import message from '../../assets/img/message.png';
import {ArrowLeft, Close, Facebook, Google} from "../../assets/icons";

import styles from './AuthPage.module.scss';
import { toast } from "react-toastify";
import classNames from "classnames";

export const AuthPage = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const [isActive, setIsActive] = useState('login');
    const [isError, setIsError] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [disabled, setDisabled] = useState(false);

    const handleClick = (status) => {
        setIsActive(status);
    };

    const handleSocialAuth = async (provider) => {
        return
        if (provider === 'facebook') {
            await supabase.auth.signInWithOAuth({
                provider: provider,
                options: {
                    scopes:  'public_profile email'
                }
            }).then((response) => {
                if (response?.data?.session !== null) {
                    dispatch(login({isLogin: true, user: response.data.user}));
                } else if (response?.error?.message) {
                    setIsError(true);
                    console.log(response)
                    setErrorText(response?.error?.message);
                }
            });
        } else {
            await supabase.auth.signInWithOAuth({
                provider: provider,
            }).then((response) => {
                if (response?.data?.session !== null) {
                    dispatch(login({isLogin: true, user: response.data.user}));
                } else if (response?.error?.message) {
                    setIsError(true);
                    console.log(response)
                    setErrorText(response?.error?.message);
                }
            });
        }
    };

    const handleEmailPasswordSubmit = async () => {
        setDisabled(true);
        if (isActive === 'login') {
            await supabase.auth.signInWithPassword({email, password}).then((response) => {
                if (response?.data?.session !== null) {
                    setDisabled(false);
                    dispatch(login({isLogin: true, user: response.data.user}));
                    navigate("/")
                } else if (response?.error?.message) {
                    setDisabled(false);
                    setIsError(true);
                    setErrorText(response?.error?.message);
                }
            });
        } else {
            if (password !== repeatPassword) {
                setDisabled(false);
                setIsError(true);
                setErrorText('Passwords do not match');
                return;
            }

            //------------------custom logic for register-------------------
            try {
            const response = await axios.post('https://api.mychatbot.app/signupintercept', {
              email: email,
              password: password
            });
            //check if the status is 201
            if (response.status === 201) {
              setDisabled(false);
              window.location.href = `${process.env.REACT_APP_DOMAIN}`;
              return;
            }
            } catch (error) {
                const errorText = error.response.data.error;
                console.log("error", error)
                setDisabled(false);
                setIsError(true);
                setErrorText(errorText);
                toast.error(errorText);
                return;
            }


            await supabase.auth.signUp({email, password}).then((response) => {
                if (response?.data?.user?.aud === 'authenticated') {
                    setDisabled(false);
                    // temporary disabled
                    // setShowModal(true);
                    toast.success('Account created successfully.');
                    setIsActive('login');
                } else if (response?.error?.message) {
                    setDisabled(false);
                    setIsError(true);
                    setErrorText(response?.error?.message);
                }
            });
        }
    };

    const handleResetPassword = async () => {
        setDisabled(true);
        await supabase.auth.resetPasswordForEmail(email, {
            redirectTo: `${process.env.REACT_APP_DOMAIN}/repeat-password`,
        }).then((response) => {
            if (response?.error?.message) {
                setDisabled(false);
                setIsError(true);
                setErrorText(response?.error?.message);
            } else {
                setDisabled(false);
                // temporary disabled
                // setShowModal(true);
                setIsActive('login');
                toast.success('Reset password email sent successfully.');
            }
        })
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Enter') {
                switch (isActive) {
                    case 'login':
                        handleEmailPasswordSubmit();
                        break;
                    case 'register':
                        handleEmailPasswordSubmit();
                        break;
                    case 'forgotPassword':
                        handleResetPassword();
                        break;
                    default:
                        break;
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isActive, handleSocialAuth, handleEmailPasswordSubmit, handleResetPassword]);


    const Modal = ({isOpen, children}) => {
        if (!isOpen) return null;

        return (
            <div className={styles.modalOpen}>
                <div className={styles.modalContent}>
                    {children}
                </div>
            </div>
        );
    };

    const renderPages = () => {
        return (() => {
            switch (isActive) {
                case 'login':
                    return (
                        <>
                            <div className={styles.statusAuth}>
                                <Button onClick={() => handleClick('login')}
                                        className={`${styles.authStatusButton} ${isActive === 'login' ? styles.active : ''}`}
                                        title={'SIGN IN'}/>
                                <Button onClick={() => handleClick('register')}
                                        className={`${styles.authStatusButton} ${isActive === 'register' ? styles.active : ''}`}
                                        title={'SIGN UP'}/>
                            </div>

                            <Button icon={<Google width={24} height={24}/>} className={classNames(styles.socialButton, styles.disabled)}
                                    title={'CONTINUE WITH GOOGLE'} onClick={() => handleSocialAuth('google')}/>
                            <Button icon={<Facebook width={24} height={24}/>} className={classNames(styles.socialButton, styles.disabled)}
                                    title={'CONTINUE WITH FACEBOOK'} onClick={() => handleSocialAuth('facebook')}/>

                            <div className={styles.lineText}>
                                <div className={styles.line}></div>
                                <span className={styles.text}>or</span>
                                <div className={styles.line}></div>
                            </div>

                            <Title title={'Sign in with Email'}/>
                            <Input typeInput={'default'} placeholder={'Email'} value={email}
                                   onChange={e => setEmail(e.target.value)}
                                   error={isError}/>
                            <Input typeInput={'default'} type={'password'} value={password} placeholder={'Password'}
                                   onChange={e => setPassword(e.target.value)} error={isError} errorText={errorText}/>
                            {
                                disabled ?
                                    <div
                                        className={styles.loaderContainer}
                                    >
                                        <Loader height={30} width={30}/>
                                    </div> :
                                    <Button className={styles.authButton} title={'SIGN IN'}
                                            onClick={handleEmailPasswordSubmit}/>
                            }

                            <div onClick={() => setIsActive('forgotPassword')} className={styles.forgotPassword}>Forgot
                                password ?
                            </div>
                        </>
                    )
                case 'register':
                    return (
                        <>
                            <div className={`${styles.statusAuth} ${styles.register}`}>
                                <Button onClick={() => handleClick('login')}
                                        className={`${styles.authStatusButton} ${isActive === 'login' ? styles.active : ''}`}
                                        title={'SIGN IN'}
                                        disabled={disabled}
                                />
                                <Button onClick={() => handleClick('register')}
                                        className={`${styles.authStatusButton} ${isActive === 'register' ? styles.active : ''}`}
                                        title={'SIGN UP'}
                                        disabled={disabled}
                                />
                            </div>
                            <div className={`${styles.termOfUse} ${styles.register}`}>By clicking "Continue" below, you
                                agree to the <a target="_blank" href={'https://mychatbot.app/terms-of-use'}>Terms
                                    of
                                    Use</a>
                            </div>

                            <Button icon={<Google width={24} height={24}/>} className={classNames(styles.socialButton, styles.disabled)}
                                    title={'CONTINUE WITH GOOGLE'} onClick={() => handleSocialAuth('google')}/>
                            <Button icon={<Facebook width={24} height={24}/>} className={classNames(styles.socialButton, styles.disabled)}
                                    title={'CONTINUE WITH FACEBOOK'} onClick={() => handleSocialAuth('facebook')}/>

                            <div className={styles.lineText}>
                                <div className={styles.line}></div>
                                <span className={styles.text}>or</span>
                                <div className={styles.line}></div>
                            </div>

                            <Title title={'Sign up with Email'}/>
                            <Input typeInput={'default'} placeholder={'Email'} value={email}
                                   onChange={e => setEmail(e.target.value)}
                                   error={isError}/>
                            <Input typeInput={'default'} type={'password'} value={password} placeholder={'Password'}
                                   onChange={e => setPassword(e.target.value)} error={isError}/>
                            <Input typeInput={'default'} value={repeatPassword} type={'password'}
                                   placeholder={'Repeat password'}
                                   onChange={e => setRepeatPassword(e.target.value)} error={isError}
                                   errorText={errorText}/>
                            {
                                disabled ?
                                    <div
                                        className={styles.loaderContainer}
                                    >
                                        <Loader height={30} width={30}/>
                                    </div> :
                                    <Button className={`${styles.authButton} ${isError ? styles.error : ''}`}
                                            title={'Create account with email'}
                                            onClick={handleEmailPasswordSubmit}/>
                            }

                            <div className={styles.termOfUse__bottom}>By clicking "Create account"
                                above
                                you agree to the <a target="_blank"
                                                    href={'https://mychatbot.app/terms-of-use'}>Terms of
                                    Use</a>
                            </div>
                        </>
                    )
                case 'forgotPassword' :
                    return (
                        <>
                            <div onClick={() => setIsActive('login')} className={styles.arrowContainer}>
                                <div className={styles.arrowButton}>
                                    <ArrowLeft width={18} height={18}/>
                                </div>
                                <Title title={'Forgot password'}/>
                            </div>

                            <div className={`${styles.forgotPassword} ${styles.active}`}>Enter your email and we’ll send
                                you a reset link
                            </div>
                            <Input
                                typeInput={'default'}
                                placeholder={'Email'}
                                value={email}
                                onChange={e => setEmail(e.target.value)}
                                error={isError}
                                errorText={errorText}
                            />
                            {
                                disabled ?
                                    <div
                                        className={styles.loaderContainer}
                                    >
                                        <Loader height={30} width={30}/>
                                    </div> :
                                    <Button
                                        className={styles.authButton}
                                        title={'SEND RESET EMAIL'}
                                        onClick={handleResetPassword}
                                    />
                            }
                        </>
                    )
                default:
                    return null
            }
        })()
    };

    useEffect(() => {
        setEmail('');
        setPassword('');
        setRepeatPassword('');
        setErrorText('');
        setIsError(false);
        setDisabled(false);
    }, [isActive]);

    useEffect(() => {
        setErrorText('');
        setIsError(false);
        setDisabled(false);
    }, [email, password, repeatPassword])
    return (
        <div className={styles.wrapper}>
            <div className={styles.imgWrapper}>
                <img className={`${isActive === 'login' ? styles.active : ''} ${styles.signIn}`}
                     src={signIn} alt="" loading={'lazy'}/>
                <img className={`${isActive !== 'login' ? styles.active : ''} ${styles.signUp}`}
                     src={signUp} alt="" loading={'lazy'}/>
            </div>
            <div className={`${styles.authModal} ${isActive === 'login' ? '' : styles.active}`}>
                {renderPages()}
            </div>
            <Modal isOpen={showModal}>
                <div className={styles.modalHeader}>
                    <h1>Verify your email to continue</h1>
                    <div
                        onClick={() => {
                            setShowModal(false);
                            setIsActive('login')
                        }}>
                        <Close width={24} height={24}/>
                    </div>
                </div>
                <div className={styles.modalContent__img}>
                    <img src={message} alt=""/>
                </div>
                <p>We value your security and want to be sure, that you will not loose access to your account.
                    We sent you an email with a verification link.</p>
            </Modal>
        </div>
    );
};
