import Popup from "reactjs-popup";
import "reactjs-popup/dist/index.css";
import "./style.css";

export const ControlledPopup = ({
  className,
  message,
  isOpenPopup,
  closePopup,
  actions,
}) => {
  const closeModal = () => {
    closePopup();
  };

  const contentStyle = {
    borderRadius: 8,
    border: "none",
  };

  return (
    <Popup
      className={className}
      open={isOpenPopup}
      closeOnDocumentClick
      onClose={closeModal}
      {...{
        contentStyle,
      }}
    >
      <div className="modal">
        <span className="close" onClick={closeModal}>
          &times;
        </span>
        <div className="content">
          {message}
          <div className={actions ? "actions" : ""}>{actions}</div>
        </div>
      </div>
    </Popup>
  );
};
