import { Logo } from "../../../../../../../assets/icons";
import { formatDate } from "../../../../../../../utils";
import styles from "./Knowledge.styles.module.scss";
import { BsFillCheckSquareFill } from "react-icons/bs";

const Knowledge = ({
  message,
  knowledgeHints,
  isSending,
  handleClickSpecMessageSave,
  handleChangeSpecMessageTextarea,
}) => {
  const generateKnowledgeMsg = (knowledgeItem, ind) => {
    const knowledgeMsg = knowledgeHints.find((el) => el.id === knowledgeItem);

    return (
      <div key={ind}>
        <div className={styles.specialMsg}>{knowledgeMsg?.knowledge}</div>
        <div className={`${styles.messageDate} ${styles[message.role]}`}>
          {formatDate(knowledgeMsg?.created_at)}
        </div>
      </div>
    );
  };

  return (
    <>
      {message?.knowledge_hints &&
        message?.knowledge_hints.length > 0 &&
        message.knowledge_hints.map((knowledgeItem, ind) =>
          generateKnowledgeMsg(knowledgeItem, ind)
        )}

      {message.knowledge &&
        (isSending ? (
          <div className={styles.containerTyping}>
            <div className={styles.botResponse}>
              <Logo />
              <p>Saving...</p>
            </div>
          </div>
        ) : (
          <div className={styles.specialMsgBox}>
            <textarea
              onChange={(value) =>
                handleChangeSpecMessageTextarea(message.id, value)
              }
              className={styles.specialMsgTextarea}
              placeholder="Input useful context knowledge, it will be used next time client asks something similar to the above question."
            />

            <BsFillCheckSquareFill
              size={26}
              title="Save changes"
              className={styles.specMessageIconSave}
              onClick={handleClickSpecMessageSave}
            />
          </div>
        ))}
    </>
  );
};

export default Knowledge;
