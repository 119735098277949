import React from "react";
import Popup from "reactjs-popup";
import { Button } from "../../Button";
import { contentStyle } from "../../../constants/popups";
import styles from "./SuccessSubscriptionUpdate.module.scss";

export const SuccessSubscriptionUpdate = ({ isPopupOpen, setIsPopupOpen }) => {
  return (
    <Popup
      open={isPopupOpen}
      onClose={() => setIsPopupOpen(false)}
      {...{ contentStyle }}
    >
      <div className="modal">
        <h2 className="modalTitle">Subscription Updated</h2>
        <div>
          <h2 className="modalSubtitle">
            Your subscription was updated successfully
          </h2>
          <Button
            onClick={() => setIsPopupOpen(false)}
            title={"OK"}
            className={styles.successBtn}
          />
        </div>
      </div>
    </Popup>
  );
};
