import supabase from "../../../supabase";
const PAGE_SIZE = 50;

export const fetchChatPartner = async (account_id, client_id, pageId) => {
  if (!account_id || !client_id) {
    return {
      data: null,
      error: new Error("Missing required parameters"),
    };
  }

  let query = supabase
    .from("chats")
    .select("*")
    .eq("account_id", account_id)
    .eq("client_id", client_id);

  if (pageId && pageId !== "null") {
    query = query.eq("page_id", pageId);
  }

  const { data, error } = await query
    .order("last_active", { ascending: false })
    .limit(1);

  if (error) {
    console.error("Error fetching chat partner:", error);
    return { data: null, error };
  }

  if (data && data.length > 0) {
    return { data: data[0], error: null };
  } else {
    return { data: null, error: null };
  }
};

export const fetchPageContextData = (id) =>
  supabase.from("chats").select("page_context").eq("id", id).single();

export const updateNeedsOperatorStatus = (id, needs_operator) =>
  supabase.from("chats").update({ needs_operator }).match({ id });

export const fetchKnowledgeHints = (account_id) =>
  supabase.from("knowledge_hints").select("*").eq("account_id", account_id);

export const fetchChatList = (
  account_id,
  page,
  searchValue,
  buttonFilterChatsList
) => {
  let chats = supabase
    .from("chats")
    .select("*", { count: "exact" })
    .eq("account_id", account_id)
    .order("last_active", { ascending: false })
    .order("unread_count", { ascending: false })
    .range(0, page * PAGE_SIZE - 1);
  if (searchValue) {
    chats = chats.or(
      `client_name.ilike."*${searchValue}*",page_id.ilike."*${searchValue}*"`
    );
  }
  if (buttonFilterChatsList !== "All") {
    chats = chats.eq("communication_channel", buttonFilterChatsList);
  }
  return chats;
};

export const fetchChatListNeedOperator = (
  account_id,
  page,
  searchValue,
  buttonFilterChatsList
) => {
  let chats = supabase
    .from("chats")
    .select("*", { count: "exact" })
    .eq("account_id", account_id)
    .eq("needs_operator", true)
    .order("last_active", { ascending: false })
    .order("unread_count", { ascending: false })
    .range(0, page * PAGE_SIZE - 1);
  if (searchValue) {
    chats = chats.or(
      `client_name.ilike."*${searchValue}*",page_id.ilike."*${searchValue}*"`
    );
  }
  if (buttonFilterChatsList !== "All") {
    chats = chats.eq("communication_channel", buttonFilterChatsList);
  }
  return chats;
};

export const fetchChatListItem = (account_id, client_id, pageId) => {
  let query = supabase
    .from("chats")
    .select("*")
    .eq("account_id", account_id)
    .eq("client_id", client_id);

  if (pageId && pageId !== "null") {
    query = query.eq("page_id", pageId);
  }

  return query.order("last_active", { ascending: false }).limit(1).single();
};
