export const ReferralProgram = ({ width = 22, height = 24 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.1524 5.18101C16.1524 5.00199 16.0812 4.8303 15.9546 4.70372C15.8281 4.57713 15.6564 4.50601 15.4774 4.50601H8.53535C8.35633 4.50601 8.18464 4.57713 8.05805 4.70372C7.93147 4.8303 7.86035 5.00199 7.86035 5.18101C7.86035 5.36003 7.93147 5.53172 8.05805 5.65831C8.18464 5.7849 8.35633 5.85601 8.53535 5.85601H15.4774C15.566 5.85601 15.6538 5.83855 15.7357 5.80463C15.8176 5.77071 15.892 5.72099 15.9546 5.65831C16.0173 5.59563 16.067 5.52122 16.101 5.43932C16.1349 5.35743 16.1524 5.26965 16.1524 5.18101ZM8.53535 7.42051C8.35633 7.42051 8.18464 7.49163 8.05805 7.61822C7.93147 7.7448 7.86035 7.91649 7.86035 8.09551C7.86035 8.27453 7.93147 8.44622 8.05805 8.57281C8.18464 8.6994 8.35633 8.77051 8.53535 8.77051H11.8984C12.0774 8.77051 12.2491 8.6994 12.3756 8.57281C12.5022 8.44622 12.5734 8.27453 12.5734 8.09551C12.5734 7.91649 12.5022 7.7448 12.3756 7.61822C12.2491 7.49163 12.0774 7.42051 11.8984 7.42051H8.53535Z"
        fill="#384157"
        stroke="#384157"
        strokeWidth="0.3"
      />
      <path
        d="M13.3027 19.6193H3.1252C1.10245 19.6193 1.08295 16.47 3.1252 16.47H11.7727C11.9517 16.47 12.1234 16.3989 12.25 16.2723C12.3766 16.1457 12.4477 15.974 12.4477 15.795C12.4477 15.616 12.3766 15.4443 12.25 15.3177C12.1234 15.1911 11.9517 15.12 11.7727 15.12H5.6287V2.175H17.2327C17.7824 2.175 18.2302 2.62275 18.2302 3.1725V10.425C18.2302 10.604 18.3013 10.7757 18.4279 10.9023C18.5545 11.0289 18.7262 11.1 18.9052 11.1C19.0842 11.1 19.2559 11.0289 19.3825 10.9023C19.5091 10.7757 19.5802 10.604 19.5802 10.425V3.1725C19.5796 2.54989 19.332 1.95295 18.8918 1.51269C18.4515 1.07244 17.8546 0.824845 17.2319 0.824249H3.1252C2.34973 0.825638 1.60644 1.13436 1.05818 1.68276C0.509912 2.23117 0.201385 2.97454 0.200195 3.75V18.045C0.200195 19.6575 1.5127 20.97 3.1252 20.97H13.3027C13.4818 20.97 13.6536 20.8988 13.7803 20.7722C13.9069 20.6455 13.9781 20.4737 13.9781 20.2946C13.9781 20.1155 13.9069 19.9437 13.7803 19.8171C13.6536 19.6904 13.4818 19.6193 13.3027 19.6193ZM1.5502 3.75C1.5502 2.8815 2.2567 2.175 3.1252 2.175H4.27945V15.1193C3.3382 15.1193 2.38345 15.0487 1.5502 15.579V3.75Z"
        fill="#384157"
        stroke="#384157"
        strokeWidth="0.3"
      />
      <path
        d="M20.7627 19.008C21.4317 18.268 21.8017 17.3056 21.8007 16.308C21.8021 15.7753 21.6977 15.2477 21.4935 14.7558C21.2893 14.2638 20.9894 13.8173 20.6112 13.4423C20.2375 13.0664 19.793 12.7683 19.3035 12.5653C18.8139 12.3623 18.2889 12.2583 17.7589 12.2595H17.7462C16.6684 12.2595 15.6559 12.6795 14.8917 13.4423C14.5137 13.8175 14.2139 14.264 14.0097 14.7559C13.8055 15.2478 13.701 15.7754 13.7022 16.308C13.7022 17.3077 14.0712 18.2662 14.7402 19.0065C15.0687 19.3717 15.2494 19.8232 15.2494 20.2762C15.2472 20.7337 15.2487 21.1905 15.2487 21.648C15.2495 22.0527 15.4106 22.4407 15.6968 22.7269C15.983 23.0131 16.3709 23.1742 16.7757 23.175H18.7279C19.1327 23.1742 19.5206 23.0131 19.8068 22.7269C20.093 22.4407 20.2541 22.0527 20.2549 21.648C20.2549 21.1905 20.2564 20.733 20.2542 20.2762C20.2542 19.8225 20.4349 19.3717 20.7627 19.008ZM15.0529 16.308C15.0529 15.5865 15.3342 14.9077 15.8457 14.397C16.095 14.1464 16.3915 13.9478 16.7181 13.8126C17.0447 13.6774 17.3949 13.6084 17.7484 13.6095H17.7567C18.4737 13.6095 19.1479 13.8885 19.6572 14.397C19.9092 14.6472 20.1091 14.945 20.2452 15.273C20.3812 15.6011 20.4508 15.9529 20.4499 16.308C20.451 16.9714 20.205 17.6115 19.7599 18.1035C19.3432 18.5616 19.0631 19.1272 18.9514 19.7362H16.5522C16.4398 19.127 16.1595 18.5612 15.7429 18.1027C15.298 17.611 15.052 16.9712 15.0529 16.308ZM18.7279 21.825H16.7757C16.7289 21.825 16.6841 21.8064 16.651 21.7734C16.618 21.7403 16.5994 21.6955 16.5994 21.6487V21.0885H18.9042V21.6487C18.9042 21.6955 18.8856 21.7403 18.8525 21.7734C18.8195 21.8064 18.7747 21.825 18.7279 21.825Z"
        fill="#384157"
        stroke="#384157"
        strokeWidth="0.3"
      />
    </svg>
  );
};
