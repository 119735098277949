import { useCallback, useState } from "react";
import { toast } from "react-toastify";
import { Button, Input, Loader, Typography } from "../../../../components";
import { EMAIL_REGEX } from "../../const";
import { useInviteByEmailMutation } from "../../../../store/api";

import styles from "./InviteByEmail.module.scss";

const InviteByEmail = () => {
  const [inputValue, setInputValue] = useState("");
  const [hasError, setHasError] = useState(false);

  const [inviteByEmail, { isLoading }] = useInviteByEmailMutation();

  const onInputChange = useCallback(
    (e) => {
      if (hasError) {
        setHasError(false);
      }
      setInputValue(e.target.value);
    },
    [hasError]
  );

  const handleSend = useCallback(async () => {
    if (!EMAIL_REGEX.test(inputValue)) {
      setHasError(true);
      return;
    }
    try {
      inviteByEmail({ email: inputValue });
      toast.success("Invitation sent");
      setInputValue("");
    } catch (error) {
      toast.error("Error sending email");
    }
    setHasError(false);
  }, [inputValue, inviteByEmail]);

  return (
    <div className={styles.container}>
      <Typography variant="h5">Invite by email</Typography>
      <Typography variant="body3" className={styles.description}>
        Send an invitation to your friends and colleagues to MyChatBot via
        email. The more invitations, the more bonuses you can receive
      </Typography>
      <div className={styles.inputWrapper}>
        <div className={styles.inputContainer}>
          <Input
            value={inputValue}
            onChange={onInputChange}
            placeholder="Enter email"
            customClass={styles.input}
          />

          {isLoading ? (
            <div className={styles.loaderContainer}>
              <Loader height={40} width={40} />
            </div>
          ) : (
            <Button
              variant="contained"
              title="Send"
              className={styles.submitButton}
              onClick={handleSend}
            />
          )}
        </div>
        {hasError ? (
          <Typography variant="body3" color="error" className={styles.error}>
            Please enter a valid email
          </Typography>
        ) : null}
      </div>
    </div>
  );
};

export default InviteByEmail;
