import supabase from "../supabase";

export const getData = (table, fields, key, val) => {
  return supabase.from(table).select(fields).eq(key, val);
};

export const updateData = (table, data = {}, match = {}) => {
  return supabase.from(table).update(data).match(match);
};

export const addData = (table, data) => {
  return supabase.from(table).upsert(data);
};

export const deleteData = (table, match) => {
    return supabase.from(table).delete().match(match);
}

export const deleteUser = (userId) => {
  return supabase.auth.admin.deleteUser(userId);
};
