export const DialogExamples = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M37.2293 45.5625C36.5835 45.5625 35.9377 45.3958 35.3543 45.0416L27.0002 40.0833C26.1252 40.0625 25.2502 40 24.4168 39.875C23.8543 39.7917 23.3751 39.4166 23.1668 38.8749C22.9585 38.3333 23.0627 37.7499 23.4377 37.3124C24.8127 35.7083 25.521 33.7916 25.521 31.75C25.521 26.7083 21.0835 22.6041 15.6252 22.6041C13.5835 22.6041 11.6251 23.1667 9.97931 24.25C9.52098 24.5417 8.95849 24.5833 8.45849 24.3542C7.97932 24.125 7.62517 23.6666 7.56267 23.1249C7.50017 22.5416 7.4585 21.9583 7.4585 21.3541C7.4585 11.0208 16.4168 2.625 27.4168 2.625C38.4168 2.625 47.3752 11.0208 47.3752 21.3541C47.3752 27.0208 44.7501 32.2291 40.1251 35.7916L40.8335 41.4584C41.0002 42.875 40.3752 44.2084 39.1877 44.9792C38.6043 45.3542 37.9168 45.5625 37.2293 45.5625ZM27.396 36.9374C27.6876 36.9166 27.9793 37 28.2293 37.1667L36.9585 42.3542C37.1877 42.5 37.3752 42.4375 37.5002 42.3542C37.6043 42.2917 37.771 42.125 37.7293 41.8333L36.9168 35.2499C36.8543 34.6666 37.1043 34.1042 37.5627 33.7709C41.8127 30.7917 44.2502 26.2499 44.2502 21.3124C44.2502 12.7083 36.7085 5.70829 27.4168 5.70829C18.4793 5.70829 11.146 12.2084 10.6043 20.375C12.1668 19.7709 13.8543 19.4375 15.6043 19.4375C22.7918 19.4375 28.6252 24.9374 28.6252 31.7083C28.646 33.5416 28.2085 35.3124 27.396 36.9374Z"
        fill="#384157"
      />
      <path
        d="M9.54119 47.3959C8.99953 47.3959 8.47868 47.2502 7.99952 46.9377C7.06202 46.3335 6.56204 45.2918 6.68704 44.1877L7.1037 40.9792C4.2912 38.6876 2.62451 35.2917 2.62451 31.7292C2.62451 27.6667 4.74953 23.8751 8.31203 21.6043C10.4579 20.2084 12.9995 19.4585 15.6453 19.4585C22.8328 19.4585 28.6662 24.9584 28.6662 31.7292C28.6662 34.4792 27.6662 37.1876 25.8328 39.3335C23.4787 42.1876 19.9579 43.8542 16.0829 43.9792L10.9995 47.0001C10.5412 47.2709 10.0412 47.3959 9.54119 47.3959ZM15.6245 22.5835C13.5829 22.5835 11.6245 23.146 9.97868 24.2293C7.31201 25.9376 5.72869 28.7292 5.72869 31.7292C5.72869 34.6251 7.14537 37.271 9.64537 38.9793C10.1245 39.3127 10.3745 39.8751 10.312 40.4584L9.85368 44.021L14.8328 41.0627C15.0828 40.9168 15.3537 40.8334 15.6245 40.8334C18.687 40.8334 21.5829 39.521 23.4162 37.2918C24.7912 35.6668 25.5204 33.75 25.5204 31.7084C25.5204 26.6875 21.0829 22.5835 15.6245 22.5835Z"
        fill="#384157"
      />
    </svg>
  );
};
