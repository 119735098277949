export const OLX = ({ width = 280, height = 300 }) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 280 300"
    >
      <rect fill="#002f34" width={width} height={height} />
      <path
        fill="#23e5db"
        d="M171.91,94.93V204.35H144.56V94.93Zm-82,9.1a45.6,45.6,0,1,1-45.6,45.6A45.61,45.61,0,0,1,89.87,104Zm110.5,18.25,8,8,8-8h19.34v19.34l-8,8,8,8V177H216.39l-8-8-8,8H181V157.64l8-8-8-8V122.28Zm-110.5,9.13a18.22,18.22,0,1,0,18.22,18.22A18.24,18.24,0,0,0,89.87,131.41Z"
      />
    </svg>
  );
};
