import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

import Switch from "react-switch";

import { useSetupWebhookMutation } from "../../../../store/api";
import supabase from "../../../../supabase";

import { Title } from "../../../../components/Title";
import { Input, Button, Loader } from "../../../../components";
import { Modal } from "../../../../components/Modal";

import styles from "./OLXAccount.module.scss";
import { COUNTRY_CODES } from "./const";

export const OLXAccount = ({ channelData, fetchChannels, assistantId }) => {
  const [setupWebhook] = useSetupWebhookMutation();
  const user = useSelector((state) => state.auth.user);
  const [searchParams] = useSearchParams();

  const [isLoading, setIsLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [accounts, setAccounts] = useState([]);
  const [activeAccountTab, setActiveAccountTab] = useState(0);
  const [accountName, setAccountName] = useState("");
  const [countryCode, setCountryCode] = useState(COUNTRY_CODES.ua.code);
  const [errorText, setErrorText] = useState("");

  const currentAccountData = accounts[activeAccountTab];

  const isAccounts = !!accounts.length;
  const isCheck =
    Object.keys(currentAccountData ?? {}).includes("is_on") &&
    currentAccountData?.is_on;
  const isDisabledAddNewAccountButton = accounts.at(-1)?.isNewAccount ?? false;
  const isDisabledConnectButton = !!accountName.length && !errorText.length;
  const isSwitchDisabled =
    !Object.keys(currentAccountData ?? {}).includes("is_on") || isLoading;
  const isChannelCreated =
    (currentAccountData?.page_id &&
      !!currentAccountData?.page_id.length &&
      Object.keys(currentAccountData ?? {}).includes("is_on")) ??
    false;

  const validateAccountName = (name) => {
    if (name.length < 5) {
      setErrorText("The name is too short.");
    }

    if (name.length === 0 || name.length >= 5) {
      setErrorText("");
    }

    if (name.match(/[.,:()]/g)) {
      setErrorText('The name cannot contain the following characters ",.:()"');
    }
  };

  const handleAddNewAccountButton = () => {
    setAccounts((a) => [...a, { isNewAccount: true }]);
    setActiveAccountTab(accounts.length);
  };

  const handleToggleSwitch = async () => {
    try {
      setIsLoading(true);

      const page_id = currentAccountData.page_id;

      if (!isCheck) {
        const { error } = await setupWebhook({
          channel: "OLX Account",
          account_id: user.account_id,
          page_id,
          assistant_id: assistantId,
        });

        if (error) {
          throw new Error("Failed to setup webhook");
        }
      } else {
        const { error } = await supabase
          .from("channels")
          .update({ is_on: false })
          .match({
            account_id: user.account_id,
            communication_channel: "OLX Account",
            page_id,
          });

        if (error) {
          throw new Error("Failed to turn off channel");
        }
      }
    } catch (error) {
      setErrorText(error.message);
      setIsModalVisible(true);
    } finally {
      await fetchChannels();
      setIsLoading(false);
    }
  };

  const handleAccountNameChange = (value) => {
    validateAccountName(value);
    setAccountName(value);
  };

  const handleConnectButton = () => {
    const redirectURI = `${process.env.REACT_APP_DOMAIN}/oauth/olx?account=${accountName}&country=${countryCode}&assistant=${assistantId}`;
    const qEscapedRedirectURI = encodeURIComponent(redirectURI);
    const OLXoauthURI = `https://www.olx.${countryCode}/oauth/authorize/?client_id=${process.env.REACT_APP_OLX_CLIENT_ID}&response_type=code&state=x93ld3v&scope=read+write+v2&redirect_uri=${qEscapedRedirectURI}`;

    window.location.href = OLXoauthURI;
  };

  const handleCloseModal = () => {
    setErrorText("");
    setIsModalVisible(false);
  };

  useEffect(() => {
    const subTab = searchParams.get("subtab");

    if (subTab && subTab === "new") {
      handleAddNewAccountButton();
    }

    if (subTab && subTab !== "new") {
      const index = accounts.findIndex(({ page_id }) => page_id === subTab);
      setActiveAccountTab(index);
    }
  }, []);

  useEffect(() => {
    setAccounts([...channelData]);
  }, [channelData]);

  useEffect(() => {
    setAccountName(currentAccountData?.page_id ?? "");
  }, [currentAccountData]);

  useEffect(() => {
    validateAccountName(accountName);
  }, [accountName]);

  return (
    <>
      <div className={styles.titleWithButton}>
        <Title title="OLX Accounts" />
        <Button
          className={styles.saveOptimize}
          title="NEW"
          disabled={isDisabledAddNewAccountButton}
          onClick={handleAddNewAccountButton}
        />
      </div>

      {isAccounts && (
        <div className={styles.accountTabs}>
          {accounts.map(({ page_id }, i) => (
            <button
              key={i}
              className={`${styles.accountTabButton} ${
                activeAccountTab === i ? styles.active : ""
              }`}
              onClick={() => setActiveAccountTab(i)}
            >
              {!!page_id?.length ? page_id : "New Account"}
            </button>
          ))}
        </div>
      )}

      {isAccounts ? (
        <>
          <div className={styles.enableBot}>
            <Switch
              disabled={isSwitchDisabled}
              onChange={handleToggleSwitch}
              checked={isCheck}
              uncheckedIcon={false}
              checkedIcon={false}
              height={27}
              offColor={"#E3E6EE"}
              onColor={"#3588E9"}
            />
            <div className={styles.enableBotText}>
              Connect assistant to this channel
            </div>
          </div>
          <div className={styles.connectionBot}>
            {isLoading ? (
              <div className={styles.loader}>
                <Loader height={40} width={40} />
              </div>
            ) : (
              <>
                <div className={styles.connectionBodyHeader}>
                  <div className={styles.connectionBotText}>
                    {isChannelCreated ? "Your Account" : "New Account"}
                  </div>
                  <div className={styles.countryOptionsWrapper}>
                    {Object.values(COUNTRY_CODES).map((country) => (
                      <Button
                        key={country.code}
                        title={country.label}
                        variant="contained"
                        color="light"
                        isSelected={country.code === countryCode}
                        onClick={() => setCountryCode(country.code)}
                      />
                    ))}
                  </div>
                </div>
                <Input
                  typeInput="connected"
                  placeholder="Account Name"
                  value={accountName}
                  onChange={(e) => handleAccountNameChange(e.target.value)}
                  error={!!errorText.length}
                  errorText={errorText}
                  disabled={isChannelCreated}
                />

                <div className={styles.saveChanges}>
                  <Button
                    className={styles.saveOptimize}
                    disabled={!isDisabledConnectButton}
                    title={isChannelCreated ? "RECONNECT" : "CONNECT"}
                    onClick={handleConnectButton}
                  />
                </div>
              </>
            )}
          </div>
        </>
      ) : (
        <div className={`${styles.connectionBot}  ${styles.noAccounts}`}>
          <div className={styles.connectionBotText}>
            None of the accounts are connected.
          </div>
          <div className={styles.connectionBotText}>
            Please add a new account!
          </div>
        </div>
      )}

      <Modal
        title={"Error"}
        description={errorText}
        isShowModal={isModalVisible}
        setIsShowModal={handleCloseModal}
      />
    </>
  );
};
