export const Pen = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.5818 7.24002C22.5826 7.10841 22.5573 6.97795 22.5076 6.85611C22.4578 6.73427 22.3845 6.62346 22.2918 6.53002L18.0518 2.29002C17.9584 2.19734 17.8475 2.12401 17.7257 2.07425C17.6039 2.02448 17.4734 1.99926 17.3418 2.00002C17.2102 1.99926 17.0797 2.02448 16.9579 2.07425C16.8361 2.12401 16.7252 2.19734 16.6318 2.29002L13.8018 5.12002L2.8718 16.05C2.77912 16.1435 2.7058 16.2543 2.65603 16.3761C2.60627 16.4979 2.58104 16.6284 2.5818 16.76V21C2.5818 21.2652 2.68716 21.5196 2.8747 21.7071C3.06223 21.8947 3.31659 22 3.5818 22H7.8218C7.96173 22.0076 8.1017 21.9857 8.23262 21.9358C8.36355 21.8858 8.48251 21.8089 8.5818 21.71L19.4518 10.78L22.2918 8.00002C22.3831 7.9031 22.4574 7.79155 22.5118 7.67002C22.5214 7.59031 22.5214 7.50973 22.5118 7.43002C22.5165 7.38347 22.5165 7.33657 22.5118 7.29002L22.5818 7.24002ZM7.4118 20H4.5818V17.17L14.5118 7.24002L17.3418 10.07L7.4118 20ZM18.7518 8.66002L15.9218 5.83002L17.3418 4.42002L20.1618 7.24002L18.7518 8.66002Z"
        fill="#1B1B1B"
      />
    </svg>
  );
};
