import React, { useEffect, useState } from "react";
import Switch from "react-switch";
import { Button, Typography, Loader } from "../../../../components";
import { Title } from "../../../../components/Title";
import supabase from "../../../../supabase";
import { useSelector } from "react-redux";
import { TelegramAccountForm } from "../../../../components/Forms/TelegramAccountForm/TelegramAccountForm";
import { TwoFactorAuthTelegramForm } from "../../../../components/Forms/TwoFactorAuthTelegramForm/TwoFactorAuthTelegramForm";
import { TelegramAccountItem } from "./TelegramAccountItem/TelegramAccountItem";
import { TelegramAccountInfo } from "./TelegramAccountItem/TelegramAccountInfo";
import { NoConnection } from "../../../../components/NoConnection";
import { useWaitCodePolling } from "./useWaitCodePolling";
import { useAuthorizationPolling } from "./useAuthorizationPolling";
import { useSetupWebhookMutation } from "../../../../store/api";
import styles from "./TelegramAccounts.module.scss";
import { toast } from "react-toastify";
import { deleteTelegramAccount } from "../../../../service/telegramService";

export const TelegramAccounts = ({ fetchChannels, assistantId }) => {
  const [accounts, setAccounts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [showNewAccountForm, setShowNewAccountForm] = useState(false);
  const [showAddAccountBtn, setShowAddAccountBtn] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [savedToken, setSavedToken] = useState("");
  const [waitingForCode, setWaitingForCode] = useState(false);
  const [pollingParams, setPollingParams] = useState(null);
  const [waitingForAuthorization, setWaitingForAuthorization] = useState(false);
  const [pollingForWaitCode, setPollingForWaitCode] = useState(false);
  const [isCheck, setIsCheck] = useState(false);
  const [initialAccessToken, setInitialAccessToken] = useState(null);
  const [accountWaitingForCode, setAccountWaitingForCode] = useState(null);

  const user = useSelector((state) => state.auth.user);
  const [setupWebhook] = useSetupWebhookMutation();

  const setupChannelWebhook = async (channelName, account, remove = false) => {
    const { error } = await setupWebhook({
      channel: channelName,
      account_id: user.account_id,
      assistant_id: assistantId,
      page_id: account?.page_id,
      remove,
    });
    if (error) {
      throw new Error("Failed to setup webhook");
    }
  };

  const handleChange = async () => {
    try {
      setIsLoading(true);

      if (!isCheck) {
        await setupChannelWebhook("Telegram Account", selectedAccount);
      } else {
        const { error } = await supabase
          .from("channels")
          .update({ is_on: false })
          .match({
            account_id: user.account_id,
            assistant_id: assistantId,
            communication_channel: "Telegram Account",
          });

        if (error) {
          throw new Error("Failed to turn off channel");
        }
      }

      setIsCheck((prevIsCheck) => !prevIsCheck);

      await fetchChannels();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchAccounts = async () => {
    setIsLoading(true);
    const { data, error } = await supabase
      .from("channels")
      .select("*")
      .eq("account_id", user.account_id)
      .eq("assistant_id", assistantId)
      .eq("communication_channel", "Telegram Account")
      .neq("page_id", "");

    if (error) {
      console.error("Error fetching accounts:", error);
    } else {
      const connectedAccounts = data.filter((account) => account.access_token);

      const sortedAccounts = connectedAccounts.sort(
        (a, b) => new Date(b.created_at) - new Date(a.created_at)
      );
      setAccounts(sortedAccounts);

      if (!selectedAccount && sortedAccounts.length > 0) {
        setSelectedAccount(sortedAccounts[0]);
        setSavedToken(sortedAccounts[0].access_token || "");
        setIsCheck(sortedAccounts[0].is_on || false);
      } else if (selectedAccount) {
        const updatedAccount = data.find(
          (account) => account.access_token === selectedAccount.access_token
        );
        if (updatedAccount) {
          setSelectedAccount(updatedAccount);
        } else {
          setSelectedAccount(null);
        }
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAccounts();
  }, [assistantId, user.account_id]);

  const handleAddAccount = () => {
    setShowAddAccountBtn(true);
    setShowNewAccountForm(true);
    setSelectedAccount(null);
  };

  const handleNewAccountClick = () => {
    setShowNewAccountForm(true);
    setSelectedAccount(null);
  };

  const handleAccountAdded = () => {
    setShowNewAccountForm(false);
    setAccountWaitingForCode(null);
    setWaitingForAuthorization(false);
    setPollingForWaitCode(false);
    setInitialAccessToken(null);
    fetchAccounts();
    fetchChannels();
    toast.success("Telegram account connected successfully!");
  };

  const handleSelectAccount = (account) => {
    setShowNewAccountForm(false);
    setSelectedAccount(account);
    setSavedToken(account.access_token || "");
    setIsCheck(account.is_on || false);
  };

  const onWaitCodeNeeded = (params) => {
    setPollingParams({
      userId: user.account_id,
      assistantId,
      ...params,
    });

    // Start polling for wait_code
    setWaitingForCode(true);
    setPollingForWaitCode(true);
    setIsLoading(false);
  };

  useWaitCodePolling(
    pollingForWaitCode,
    pollingParams,
    (waitCodeRequired, channel) => {
      setPollingForWaitCode(false);

      if (waitCodeRequired === "error") {
        setIsLoading(false);

        return;
      }

      if (waitCodeRequired) {
        setInitialAccessToken(channel.access_token);
        setAccountWaitingForCode(channel);
        fetchAccounts();
      } else {
        // 2FA is not required, we start polling the authorization status

        setWaitingForAuthorization(true);
      }
    }
  );

  useAuthorizationPolling(
    waitingForAuthorization,
    pollingParams,
    handleAccountAdded,
    () => {
      setWaitingForAuthorization(false);
      setIsLoading(false);
      toast.error("Authorization failed. Please try again.");
    },
    initialAccessToken
  );

  const handleDelete = async () => {
    if (
      !selectedAccount ||
      !selectedAccount.assistant_id ||
      !selectedAccount.access_token
    ) {
      toast.error("Missing account information. Cannot delete.");
      return;
    }

    setIsLoading(true);
    try {
      await setupChannelWebhook("Telegram Account", selectedAccount, true);

      handleAccountDeleted(selectedAccount.access_token);
    } catch (error) {
      console.error("Error deleting account:", error);
      toast.error("Failed to delete account.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleAccountDeleted = (deletedAccessToken) => {
    if (
      selectedAccount &&
      selectedAccount.access_token === deletedAccessToken
    ) {
      setSelectedAccount(null);
    }

    fetchAccounts();
    fetchChannels();
  };

  const handleAccountUpdated = (updatedAccount) => {
    setSelectedAccount(updatedAccount);

    fetchAccounts();
    fetchChannels();
  };

  const disableNewAccountButton =
    waitingForAuthorization || waitingForCode || isLoading;

  return (
    <>
      <div className={styles.wrapper}>
        <Title title={"Telegram Accounts"} />
        {/* {accounts.length > 0 && (
          <Switch
            disabled={savedToken === ""}
            onChange={handleChange}
            checked={isCheck}
            uncheckedIcon={false}
            checkedIcon={false}
            height={27}
            width={56}
            handleDiameter={25}
            //offColor={"#ffffff"}
            onColor={"#3588E9"}
            handleColor={"#E3E6EE"}
          />
        )} */}
        {!showAddAccountBtn && (
          <Button
            title="Add Telegram Account"
            onClick={handleAddAccount}
            className={styles.addAccount}
            disabled={disableNewAccountButton}
          />
        )}
      </div>

      {isLoading ? (
        <div className={styles.loaderContainer}>
          <Loader height={40} width={40} />
        </div>
      ) : (
        <>
          <div className={styles.wrapperConnectedAccounts}>
            {showAddAccountBtn && (
              <Button
                title="New Account"
                onClick={handleNewAccountClick}
                className={`${styles.newAccount} ${
                  showNewAccountForm ? styles.activeAccount : ""
                }`}
              />
            )}
            {accounts.length > 0 &&
              !accountWaitingForCode &&
              accounts.map((account, index) => (
                <TelegramAccountItem
                  key={account.access_token}
                  account={account}
                  onSelectedAccount={handleSelectAccount}
                  isActive={
                    selectedAccount?.access_token === account.access_token
                  }
                />
              ))}
          </div>
          <div className={styles.connectionBot}>
            {showNewAccountForm && !accountWaitingForCode && (
              <>
                <Typography variant="h2" className={styles.title}>
                  Connect your Telegram account
                </Typography>
                <TelegramAccountForm
                  assistantId={assistantId}
                  onWaitCodeNeeded={onWaitCodeNeeded}
                />
              </>
            )}
            {accountWaitingForCode ? (
              <>
                <Typography variant="h2" className={styles.title}>
                  Login code
                </Typography>
                <Typography variant="h4" className={styles.subtitle}>
                  Enter the code sent to your phone via SMS or Telegram app
                </Typography>
                <TwoFactorAuthTelegramForm
                  assistantId={assistantId}
                  account={accountWaitingForCode}
                  onAuthorizationSuccess={handleAccountAdded}
                />
              </>
            ) : selectedAccount ? (
              <TelegramAccountInfo
                selectedAccount={selectedAccount}
                fetchAccounts={fetchAccounts}
                userId={user.account_id}
                assistantId={assistantId}
                onAccountUpdated={handleAccountUpdated}
                handleDelete={() => handleDelete(selectedAccount)}
                setupChannelWebhook={setupChannelWebhook}
              />
            ) : accounts.length === 0 && !showNewAccountForm ? (
              <NoConnection
                title="No Telegram accounts connected"
                description="Please add a new account"
              />
            ) : null}
          </div>
        </>
      )}
    </>
  );
};
