import React, { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { ControlledPopup } from "../../Popup";
import { Button } from "../../Button";
import { getAFormOfSubscriptionPayment } from "../../../service/mychatbot.js";
import styles from "./PopupSubscription.module.scss";
import { normalizeSubscriptionPlan } from "../../../helper";

export const PopupSubscription = ({
  isOpenPopup,
  setIsOpenPopup,
  userId,
  subscriptionPlan,
}) => {
  const [htmlString, setHtmlString] = useState("");
  const [disabled, setDisabled] = useState(false);
  const wFormRef = useRef(null);

  const closePopup = () => {
    setIsOpenPopup(false);
  };

  const onProceedToPaymentClick = async (selectedPeriod) => {
    setDisabled(true);
    await addSubscriptionForm(selectedPeriod);
    setTimeout(() => {
      const form = wFormRef.current.querySelector(".wfpform");
      if (form) {
        form.submit();
      }
    }, 0);
  };

  const addSubscriptionForm = async (selectedPeriod) => {
    try {
      const formMarkup = await getAFormOfSubscriptionPayment({
        account: userId,
        subscription: subscriptionPlan,
        period: selectedPeriod,
      });
      setHtmlString(formMarkup);
      return formMarkup;
    } catch (error) {
      toast.error(error.message || "Failed to initiate payment");
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (!isOpenPopup) {
      setHtmlString("");
      setDisabled(false);
    }
  }, [isOpenPopup]);

  // Add available subscription options based on plan
  const availablePeriods = () => {
    const normalizedPlan =
      normalizeSubscriptionPlan(subscriptionPlan).toLowerCase();
    if (normalizedPlan === "corporate") {
      return ["monthly"];
    }
    return ["monthly", "yearly"];
  };

  return (
    <>
      <div
        className={styles.wForm}
        dangerouslySetInnerHTML={{ __html: htmlString }}
        ref={wFormRef}
      ></div>
      <ControlledPopup
        isOpenPopup={isOpenPopup}
        closePopup={closePopup}
        actions={
          <div className={styles.form}>
            <div className={styles.wInput}>
              <label className={styles.iLabel}>
                Select Subscription Period
              </label>
              <div className={styles.buttonGroup}>
                {availablePeriods().includes("monthly") && (
                  <Button
                    className={styles.selectButton}
                    onClick={() => onProceedToPaymentClick("monthly")}
                    disabled={disabled}
                    title={"Proceed with Monthly Payments"}
                  />
                )}
                {availablePeriods().includes("yearly") && (
                  <Button
                    className={styles.selectButton}
                    onClick={() => onProceedToPaymentClick("yearly")}
                    disabled={disabled}
                    title={"Proceed with Yearly Payments"}
                  />
                )}
              </div>
            </div>
          </div>
        }
      />
    </>
  );
};
