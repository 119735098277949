import React from "react";
import styles from "./Channel.module.scss";

export const Channel = ({
  title,
  icon,
  onClick,
  activeTab,
  value,
  status,
  subscriptionPlan,
}) => {
  const handleClick = () => {
    if (subscriptionPlan !== null) {
      onClick();
    }
  };

  const nonActive = subscriptionPlan === null;

  return (
    <div
      onClick={handleClick}
      className={`${styles.container} ${
        nonActive ? `${styles.nonActive} ${styles.multipro}` : ""
      }`}
      style={{ backgroundColor: activeTab === value ? "#F5F5F5" : "" }}
    >
      <div className={styles.iconContainer}>
        {icon}
        <div className={styles.title}>{title}</div>
      </div>
      <div
        style={{ backgroundColor: status?.color ?? "#3588e9" }}
        className={`${styles.button} ${nonActive ? styles.multipro : ""}`}
      >
        {nonActive ? "Standard" : status?.name ?? "Connect"}
      </div>
    </div>
  );
};
