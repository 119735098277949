import styles from "./ActionMenu.module.scss";
import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { PiDotsThreeOutlineVerticalFill } from "react-icons/pi";

const ActionMenu = ({ options }) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false);
  const portalContainerRef = useRef();

  useEffect(() => {
    const handleClickOutside = (event) =>
      portalContainerRef.current &&
      !portalContainerRef.current.contains(event.target) &&
      setIsOpenMenu(false);

    if (isOpenMenu) document.addEventListener("mousedown", handleClickOutside);
    else document.removeEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isOpenMenu]);

  return (
    <>
      <button className={styles.btn} onClick={() => setIsOpenMenu(!isOpenMenu)}>
        <PiDotsThreeOutlineVerticalFill size={24} />
      </button>
      {isOpenMenu &&
        createPortal(
          <div className={styles.container} ref={portalContainerRef}>
            <p>Options</p>
            <div className={styles.actions}>
              {options.map((option, i) => (
                <button
                  key={i}
                  className={styles.action}
                  onClick={() => {
                    option.action();
                    setIsOpenMenu(false);
                  }}
                >
                  {option.icon}
                  <p>{option.label}</p>
                </button>
              ))}
            </div>
          </div>,
          document.body
        )}
    </>
  );
};

export default ActionMenu;
