import React, { useEffect, useState } from "react";
import { getPrice } from "../../service/mychatbot.js";
import { formatNumber } from "../../utils";

import styles from "./DetailedInfo.module.scss";

export const DetailedInfo = ({ chatTokens = [], userId }) => {
  const boxes = [
    { id: 1, value: 0, title: "Active dialogs for the period" },
    { id: 2, value: 0, title: "Total token usage for period" },
    { id: 3, value: 0, title: "Total $ cost for period" },
    { id: 4, value: 0, title: "Average token usage per dialog" },
    { id: 5, value: 0, title: "Average $ cost of a dialog" },
    { id: 6, value: 0, title: "Average replies per dialog" },
  ];

  const divider = 1000000;

  const [items, setItems] = useState([]);
  const [price, setPrice] = useState(0);

  useEffect(() => {
    getPrice({ account: userId })
      .then((data) => {
        setPrice(data.price);
      })
      .catch((e) => {});
  }, []);

  const getTheNumberOfElements = (items = [], key = "") => {
    return [...new Set(items.map((el) => el[key]))].length;
  };

  const getSum = (items = [], key1 = "", key2 = "") => {
    return items.reduce((acc, el) => {
      return acc + Number(el[key1]) + Number(el[key2]);
    }, 0);
  };

  useEffect(() => {
    if (!chatTokens || !price) return;
    const totalTokens = getSum(
      chatTokens,
      "completion_tokens",
      "prompt_tokens"
    );
    const totalTokensFormatted = formatNumber(
      getSum(chatTokens, "completion_tokens", "prompt_tokens"),
      divider
    );

    const uniqueChats = getTheNumberOfElements(chatTokens, "chat_id");

    const averageNumberOfTokens = Math.floor(totalTokens / uniqueChats);
    const averageCost = ((averageNumberOfTokens / divider) * price).toFixed(2);

    boxes[0].value = uniqueChats;
    boxes[1].value = totalTokensFormatted;
    boxes[2].value = Math.floor((totalTokens / divider) * price);
    boxes[3].value = averageNumberOfTokens || 0;
    boxes[4].value = isNaN(averageCost)
      ? 0
      : formatNumber(averageCost, divider);
    boxes[5].value = Math.floor(chatTokens.length / uniqueChats) || 0;
    setItems(boxes);
  }, [chatTokens, price]);

  return (
    <div className={styles.gallery}>
      {items.map((item) => (
        <div className={styles.galleryCard} key={item.id}>
          <h3 className={styles.galleryCardTitle}>{item.title}</h3>
          <p className={styles.galleryCardValue}>{item.value}</p>
        </div>
      ))}
    </div>
  );
};
