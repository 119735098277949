export const FollowUp = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 31 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.595 1.351c-1.876-0.002-3.704 0.594-5.218 1.701s-2.635 2.669-3.201 4.457c-0.566 1.788-0.547 3.711 0.055 5.487s1.754 3.315 3.289 4.393c-2.658 1.062-4.937 2.895-6.544 5.263s-2.469 5.163-2.474 8.025c0.022 0.354 0.178 0.687 0.436 0.93s0.6 0.378 0.955 0.378c0.355 0 0.696-0.135 0.955-0.378s0.414-0.575 0.436-0.93c-0.005-2.055 0.541-4.075 1.58-5.848s2.534-3.236 4.329-4.237c1.795-1.001 3.826-1.503 5.881-1.454s4.059 0.647 5.805 1.732c0.156 0.097 0.33 0.162 0.511 0.191s0.366 0.023 0.545-0.019c0.179-0.042 0.347-0.119 0.496-0.226s0.275-0.243 0.372-0.399 0.162-0.33 0.191-0.511c0.030-0.181 0.023-0.366-0.019-0.545s-0.119-0.347-0.226-0.496c-0.107-0.149-0.243-0.275-0.399-0.372-0.808-0.486-1.662-0.892-2.55-1.209 2.193-1.604 3.628-4.186 3.628-7.099 0-4.854-3.981-8.834-8.834-8.834zM14.595 4.138c3.35 0 6.047 2.698 6.047 6.047s-2.698 6.042-6.047 6.042c-3.35 0-6.042-2.682-6.042-6.042 0-3.35 2.687-6.047 6.042-6.047zM28.672 20.839c-0.226 0.007-0.447 0.068-0.644 0.179s-0.364 0.268-0.487 0.458l-4.796 7.188-3.008-2.014c-0.152-0.112-0.326-0.193-0.51-0.236s-0.375-0.049-0.562-0.016c-0.186 0.033-0.364 0.103-0.523 0.206s-0.294 0.238-0.4 0.395c-0.105 0.157-0.177 0.334-0.212 0.52s-0.031 0.377 0.010 0.562c0.041 0.185 0.12 0.359 0.231 0.513s0.252 0.283 0.414 0.38l4.18 2.782c0.308 0.204 0.684 0.278 1.045 0.205s0.68-0.286 0.884-0.594l5.569-8.35c0.145-0.212 0.228-0.461 0.24-0.717s-0.046-0.512-0.17-0.737-0.307-0.412-0.53-0.54c-0.223-0.128-0.477-0.191-0.734-0.183z"
        fill="#384157"
      />
    </svg>
  );
};
