import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { openInNewTab } from "../../../utils";

import { Support } from "../../../assets/icons";
import logo from "../../../assets/img/logo.png";
import userAvatar from "../../../assets/img/user.png";
import { useSubscription } from "../../../pages/SubscriptionPage/useSubscription";
import styles from "./Navigation.module.scss";
import { normalizeSubscriptionPlan } from "../../../helper";
import {
  navigationTabs,
  navigationTabsBottom,
} from "../../../constants/navigationMenu";
import { ROUTES } from "../../../constants/routes";

export const Navigation = () => {
  const location = useLocation();
  const user = useSelector((state) => state.auth.user);
  const { subscriptionPlan } = useSubscription(user);

  const normalizedPlan = normalizeSubscriptionPlan(subscriptionPlan);

  const isSubscriptionActive = normalizedPlan !== null && normalizedPlan !== "";

  const getLinkClassName = (route, requiresSubscription) => {
    let className = `${styles.navigation}`;
    if (
      location.pathname === route ||
      (route !== "/" && location.pathname.startsWith(route))
    ) {
      className += ` ${styles.navigationActive}`;
    }
    if (!isSubscriptionActive && requiresSubscription) {
      className += ` ${styles.nonActive}`;
    }
    return className;
  };

  const userRole = user.role;

  const filteredTabs = navigationTabs.filter((tab) =>
    tab.roles.includes(userRole)
  );

  const filteredTabsBottom = navigationTabsBottom.filter((tab) =>
    tab.roles.includes(userRole)
  );

  return (
    <div className={styles.container}>
      <div className={styles.navigationContainer}>
        <div>
          <img src={logo} alt="logo" />
          {filteredTabs.map((tab) => (
            <Link
              key={tab.to}
              to={tab.to}
              className={getLinkClassName(tab.to, false)}
            >
              {tab.icon}
              {tab.label}
            </Link>
          ))}
        </div>
        <div>
          {filteredTabsBottom.map((tab) => (
            <Link
              key={tab.to}
              to={tab.to}
              className={getLinkClassName(tab.to, false)}
            >
              {tab.icon}
              {tab.label}
            </Link>
          ))}
          <div
            className={styles.navigation}
            onClick={() => {
              openInNewTab(`https://docs.mychatbot.app/`);
            }}
          >
            <Support />
            <a>Support</a>
          </div>
        </div>
      </div>
      <div className={styles.userCard}>
        {user.app_metadata.provider === "email" ? (
          <Link
            to={ROUTES.profilePage}
            className={`${styles.profile} ${
              location.pathname === ROUTES.profilePage
                ? styles.navigationActive
                : ""
            }`}
          >
            <img src={userAvatar} alt="avatar" />
            <div className={styles.userCard__userInfo}>
              <div className={styles.textInfo}>
                <div className={styles.userCard__email}>{user?.email}</div>
              </div>
            </div>
          </Link>
        ) : (
          <Link
            to={ROUTES.profilePage}
            className={`${styles.profile} ${styles.socials} ${
              location.pathname === ROUTES.profilePage
                ? styles.navigationActive
                : ""
            }`}
          >
            <img src={user?.user_metadata?.avatar_url} alt="avatar" />
            <div className={styles.userCard__userInfo}>
              <div className={styles.textInfo}>
                <div className={styles.userCard__name}>
                  {user?.user_metadata?.full_name}
                </div>
                <div className={styles.userCard__email}>
                  {user?.user_metadata?.email}
                </div>
              </div>
            </div>
          </Link>
        )}
      </div>
    </div>
  );
};
