import React from "react";
import Markdown from "react-markdown";
import styles from "./MdToHTML.module.css";
import rehypeRaw from "rehype-raw";

export const MdToHTML = ({ content }) => {
  // Replacing Google Drive links
  content = content.replace(
    /https:\/\/drive.google.com\/file\/d\/[a-zA-Z0-9_-]+\/view/g,
    (match) => {
      return convertGoogleDriveLinkToDirectImageLink(match);
    }
  );

  // Custom renderer for images and videos
  const components = {
    img: ({ node, ...props }) => (
      <img
        {...props}
        onError={({ currentTarget }) => {
          currentTarget.onerror = null;
          currentTarget.src =
            "https://user-images.githubusercontent.com/20684618/31289519-9ebdbe1a-aae6-11e7-8f82-bf794fdd9d1a.png";
        }}
        className={styles.image}
      />
    ),
    video: ({ node, ...props }) => (
      <video {...props} controls className={styles.video} />
    ),
  };

  return (
    content &&
    typeof content === "string" && (
      <Markdown
        className={styles.reactMarkDown}
        skipHtml={false}
        rehypePlugins={[rehypeRaw]}
        components={components}
      >
        {content}
      </Markdown>
    )
  );
};

function convertGoogleDriveLinkToDirectImageLink(shareableLink, width = 1000) {
  // If this is not a Google Drive link, return unchanged
  if (!shareableLink.includes("drive.google.com")) {
    return shareableLink;
  }

  const parts = shareableLink.split("/");
  if (parts.length < 6) {
    return "Invalid link format";
  }

  const fileId = parts[5];

  // Creating a direct link to an image
  const directLink = `https://lh3.googleusercontent.com/d/${fileId}=w${width}`;
  return directLink;
}
