import React, { useMemo, useState } from "react";
import { Modal } from "../../../../../../components/Modal";
import styles from "../EditEdgeModal/EditEdgeModal.module.scss";
import { Typography } from "../../../../../../components/Typography/Typography";
import CustomFollowUpDropdown from "../../../CustomFollowUpDropdown/CustomFollowUpDropdown";

import { Button } from "../../../../../../components/Button";
import DurationPicker from "react-duration-picker";
import { useFollowUpPageContext } from "../../../../context/useFollowUpPageContext";
import { ALL_STATUSES, EDGE_CONDITION_TYPES, NODE_TYPES } from "../../const";
import { selectFunnelStatuses } from "../../../../../../store/slices/followUpsSlice";
import { useSelector } from "react-redux";
import { PickADay } from "../../../../../../components/popups/PickADay";

export function EditStartNodeModal() {
  const {
    isEditStartNodeModalOpen,
    setIsEditStartNodeModalOpen,
    updateStartNodeData,
    nodes,
    schedule,
    setSchedule,
    targetStatusName,
    setTargetStatusName,
  } = useFollowUpPageContext();
  const [edgeData, setEdgeData] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const funnelStatuses = useSelector(selectFunnelStatuses);
  const startNode = useMemo(
    () => nodes.find((node) => node.type === NODE_TYPES.start),
    [nodes],
  );
  const defaultSilenceDuration = useMemo(() => {
    return startNode.conditions?.[0]?.silence;
  }, [startNode]);

  const handleOnSave = () => {
    updateStartNodeData(edgeData);
    setIsEditStartNodeModalOpen(false);
  };

  const handleOnStatusChange = (status) => {
    if (status === ALL_STATUSES) {
      setTargetStatusName(null);
    } else {
      setTargetStatusName(status);
    }
  };

  const funnelStatusesOptions = useMemo(
    () => funnelStatuses?.map(({ status_name }) => status_name) || [],
    [funnelStatuses],
  );

  return (
    <Modal
      isShowModal={isEditStartNodeModalOpen}
      title="Select condition"
      modalClassName={styles.modal}
      setIsShowModal={setIsEditStartNodeModalOpen}
      actions={
        <div className={styles.actionsWrapper}>
          <Typography variant="body2" className={styles.description}>
            When connecting nodes, the agent relies on pathway labels for
            decision-making. Keep labels brief and clear.
          </Typography>
          <div className={styles.selectWrapper}>
            <div className={styles.inputLabel}>Funnel Status</div>
            <CustomFollowUpDropdown
              placeholder="Select status"
              value={targetStatusName || ALL_STATUSES}
              onChange={handleOnStatusChange}
              options={[ALL_STATUSES, ...funnelStatusesOptions]}
            />
          </div>
          <div className={styles.selectWrapper}>
            <div className={styles.scheduleWrapper}>
              <Button
                variant="contained"
                color="edit"
                title="Set follow up hours"
                onClick={() => setIsModalOpen(true)}
              />
            </div>
          </div>
          <div className={styles.selectWrapper}>
            <div className={styles.inputLabel}>Condition</div>
            <CustomFollowUpDropdown
              value={EDGE_CONDITION_TYPES.silence}
              disabled={true}
            />
          </div>
          <div className={styles.silenceOptionContent}>
            <DurationPicker
              maxHours={115}
              onChange={(newValue) => {
                setEdgeData(newValue);
              }}
              initialDuration={defaultSilenceDuration}
            />
          </div>

          <div className={styles.controls}>
            <Button
              title="Save"
              variant="contained"
              color="primary"
              onClick={handleOnSave}
            />
          </div>
        </div>
      }
    >
      <PickADay
        setPickADay={setIsModalOpen}
        defaultWeekSchedule={schedule}
        onSubmit={setSchedule}
        isPickADay={isModalOpen}
      />
    </Modal>
  );
}
