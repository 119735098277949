import { Modal } from "../../../../components/Modal";
import React from "react";
import { Button } from "../../../../components";
import styles from "../../../../components/Integration/Integration.module.scss";
import useNotificationData from "../../useNotificationData";

const NotificationModal = ({
  isModalOpen,
  setIsModalOpen,
  notificationData,
}) => {
  const { removeNotification, markAsRead } = useNotificationData();

  const handleConfirmClick = async () => {
    if (notificationData.status === "new") {
      await markAsRead(notificationData.id);
    } else {
      await removeNotification(notificationData.id);
    }
    setIsModalOpen(false);
  };

  if (!isModalOpen) {
    return null;
  }

  return (
    <Modal
      title={`Notification Details (${notificationData?.type})`}
      description={notificationData?.message}
      setIsShowModal={setIsModalOpen}
      isShowModal={isModalOpen}
      actions={
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            margin: "20px auto 0",
            width: "40%",
          }}
        >
          <Button
            className={styles.button}
            style={{ margin: "10px 10px 10px 0" }}
            onClick={handleConfirmClick}
            title={
              notificationData?.status === "new"
                ? "Mark as seen"
                : "Remove notification"
            }
          />
        </div>
      }
    />
  );
};

export default NotificationModal;
