export const Logo = ({width, height}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.4037 0.819885C7.79406 0.819885 0 8.61103 0 18.2249C0 27.8387 7.79056 35.6299 17.4037 35.6299C27.0169 35.6299 34.8074 27.8387 34.8074 18.2249C34.8074 8.61103 27.0169 0.819885 17.4037 0.819885ZM29.1018 29.623H17.4037C10.9425 29.623 5.70562 24.3857 5.70562 17.924C5.70562 11.4623 10.9425 6.22506 17.4037 6.22506C23.8649 6.22506 29.1018 11.4623 29.1018 17.924C29.1018 21.398 27.5871 24.5152 25.1838 26.6563L29.1018 29.623Z"
                fill="#6C47FF"/>
            <path
                d="M10.5645 11.3889H17.4C21.1746 11.3889 24.2355 14.4501 24.2355 18.2249C24.2355 21.9998 21.1746 25.061 17.4 25.061C13.6254 25.061 10.5645 21.9998 10.5645 18.2249C10.5645 16.1958 11.4495 14.3731 12.8523 13.1206L10.5645 11.3854V11.3889Z"
                fill="#6C47FF"/>
        </svg>
    );
};
