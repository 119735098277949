import { useDispatch, useSelector } from "react-redux";
import { updateData } from "../../service/supabase";
import {
  selectSelectedAssistant,
  selectSelectedIntegrationsIdsByType,
} from "../../store/slices/integrationSlice";
import {
  fetchInstructions as fetchAssistants,
  selectListOfAssistants,
} from "../../store/slices/assistantSlice";
import { useCallback, useMemo } from "react";
import useUpdateIntegrationData from "./useUpdateIntegrationData";

const useUpdateAssistantIntegrations = () => {
  const listOfAssistants = useSelector(selectListOfAssistants);
  const selectedAssistant = useSelector(selectSelectedAssistant);
  const selectedIntegrationsIdsByType = useSelector(
    selectSelectedIntegrationsIdsByType
  );
  const dispatch = useDispatch();
  const { deleteIntegration } = useUpdateIntegrationData();

  const updateAssistantIntegrations = async (assistantId) => {
    await updateData("assistants", { integrations: [] }, { id: assistantId });
  };

  const currentTypeAssistantIntegrationId = useMemo(() => {
    return selectedAssistant?.integrations?.find((id) =>
      selectedIntegrationsIdsByType.includes(id)
    );
  }, [selectedAssistant, selectedIntegrationsIdsByType]);

  const addAssistantIntegration = useCallback(
    async (
      integrationId,
      currentAssistantIntegrationsProps = null,
      assistantId = null
    ) => {
      const currentAssistantIntegrations =
        currentAssistantIntegrationsProps ??
        selectedAssistant?.integrations ??
        [];

      var assistantId = assistantId || selectedAssistant.id;

      await updateData(
        "assistants",
        { integrations: [...currentAssistantIntegrations, integrationId] },
        { id: assistantId }
      );
    },
    [selectedAssistant]
  );

  const removeAssistantIntegration = useCallback(
    async (integrationId, assistantId) => {
      const assistant = listOfAssistants.find((a) => a.id === assistantId);
      if (!assistant) {
        console.error("Assistant not found");
        return;
      }

      const updatedIntegrations =
        assistant.integrations?.filter((id) => id !== integrationId) || [];

      if (!assistantId) {
        console.error("Invalid assistant ID");
        return;
      }
      await updateData(
        "assistants",
        { integrations: updatedIntegrations },
        { id: assistantId }
      );

      const isIntegrationUsed = listOfAssistants.some(
        (a) => a.integrations?.includes(integrationId) && a.id !== assistantId
      );

      if (!isIntegrationUsed) {
        await deleteIntegration(integrationId);
      }

      await dispatch(fetchAssistants());

      return updatedIntegrations;
    },
    [listOfAssistants]
  );

  return {
    currentTypeAssistantIntegrationId,
    addAssistantIntegration,
    removeAssistantIntegration,
    updateAssistantIntegrations,
  };
};

export default useUpdateAssistantIntegrations;
