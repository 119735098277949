import React from "react";
import { Title } from "../Title";
import dog from "../../assets/img/dog.png";

import styles from "./NoConnection.module.scss";

export const NoConnection = ({ title, description }) => {
  return (
    <div className={styles.wrapper}>
      <img src={dog} alt="No connections" />
      <div>
        <Title title={title} className={styles.title} />
      </div>
      <div className={styles.description}>{description}</div>
    </div>
  );
};
