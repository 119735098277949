import Popup from "reactjs-popup";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { clearAltegioToken } from "../../../store/slices/integrationSlice.js";
import { deleteUserAccountByID } from "../../../service/mychatbot.js";

import { contentStyle } from "../../../constants/popups.js";

import "../../../assets/scss/modal.scss";

export const DeleteAccount = ({ isOpen, setIsOpen, handleLogout, userId }) => {
  const closeModal = () => {
    setIsOpen(false);
  };

  const dispatch = useDispatch();

  const deleteUserAccount = async () => {
    try {
      await deleteUserAccountByID(userId);
      dispatch(clearAltegioToken());
      await handleLogout();
      localStorage.removeItem("sb-iuaacuguiphtgltstwdm-auth-token");
    } catch (error) {
      console.log("error ", error);
      toast.error(error);
    } finally {
      closeModal();
    }
  };

  return (
    <Popup
      open={isOpen}
      closeOnDocumentClick
      onClose={closeModal}
      {...{
        contentStyle,
      }}
    >
      <div className="modal">
        <h2 className="modalTitle">
          Are you sure you want to proceed deleting your account?
        </h2>
        <p className="modalSubtitle">All your data will be permanently</p>
        <div className="btns">
          <button
            type="button"
            className="modalBtn btnDanger"
            onClick={deleteUserAccount}
          >
            YES, PROCEED DELETING
          </button>
          <button
            type="button"
            className="modalBtn saveBtn btnShadow"
            onClick={closeModal}
          >
            CANCEL
          </button>
        </div>
      </div>
    </Popup>
  );
};
