import { useCallback, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Input, Typography } from "../../../../components";
import { setSelectedAssistantId } from "../../../../store/slices/integrationSlice";
import { selectSelectedAssistant } from "../../../../store/slices/integrationSlice";
import { selectListOfAssistants } from "../../../../store/slices/assistantSlice";

import styles from "./IntegrationHeader.module.scss";

const IntegrationHeader = () => {
  const dispatch = useDispatch();
  const listOfAssistants = useSelector(selectListOfAssistants);
  const selectedAssistant = useSelector(selectSelectedAssistant);

  const assistantsOptions = useMemo(() => {
    return listOfAssistants?.map(({ id, bot_name }) => ({
      value: id,
      label: bot_name,
    }));
  }, [listOfAssistants]);

  useEffect(() => {
    if (!selectedAssistant && listOfAssistants.length) {
      dispatch(setSelectedAssistantId(listOfAssistants[0]?.id));
    }
  }, [listOfAssistants, selectedAssistant, dispatch]);

  const onAssistantChange = useCallback(
    (selectedAssistant) => {
      dispatch(setSelectedAssistantId(selectedAssistant.value));
    },
    [listOfAssistants, dispatch]
  );

  const pageTitle = useMemo(() => {
    let baseTitle = "Integrations";
    if (selectedAssistant) {
      baseTitle = `${baseTitle} for ${selectedAssistant.bot_name}`;
    }
    return baseTitle;
  }, [selectedAssistant]);

  const selectedAssistantValue = useMemo(() => {
    return {
      value: selectedAssistant?.id,
      label: selectedAssistant?.bot_name,
    };
  }, [selectedAssistant]);

  return (
    <div className={styles.header}>
      <Typography variant="title" className={styles.title}>
        {pageTitle}
      </Typography>
      <div className={styles.selectWrapper}>
        <Input
          typeInput="select"
          options={assistantsOptions}
          placeholder="Select Assistant"
          value={selectedAssistantValue || ""}
          onChange={onAssistantChange}
        />
      </div>
    </div>
  );
};

export default IntegrationHeader;
