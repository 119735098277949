import { Input } from "../../../../../../components";
import React from "react";
import styles from "./WpWidgetInputs.module.scss";
import useCMSWidgetDataContext from "../../useCMSWidgetDataContext";

const WpWidgetInputs = () => {
  const {
    widgetName,
    setWidgetName,
    greetingText,
    setGreetingText,
    buttonText,
    setButtonText,
    isWidgetNameValid,
    setIsWidgetNameValid,
    isDownloaded,
  } = useCMSWidgetDataContext();

  const handleWidgetNameChange = (e) => {
    const value = e.target.value;

    // Remove non-ASCII characters and spaces
    const filteredValue = value.replace(/[^!-~]+/g, "");

    setWidgetName(filteredValue);

    // Update validity state
    if (filteredValue !== value) {
      setIsWidgetNameValid(false);
    } else {
      setIsWidgetNameValid(true);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key.length === 1) {
      const asciiNoSpaceRegex = /^[\x21-\x7E]$/;
      if (!asciiNoSpaceRegex.test(e.key)) {
        e.preventDefault();
      }
    }
  };

  return (
    <div className={styles.inputsContainer}>
      <div>
        <Input
          inputTitle="Widget name"
          placeholder="Plugin for My Site"
          customClass={styles.input}
          value={widgetName}
          onChange={handleWidgetNameChange}
          onKeyPress={handleKeyPress}
          errorText={
            isWidgetNameValid
              ? ""
              : "Please enter only letters and numbers, without any spaces or special characters"
          }
          disabled={isDownloaded}
        />
      </div>
      {/*<div>*/}
      {/*  <Input*/}
      {/*    inputTitle="First greeting message"*/}
      {/*    placeholder="Hello! Let's talk :)"*/}
      {/*    customClass={styles.input}*/}
      {/*    value={greetingText}*/}
      {/*    onChange={(e) => setGreetingText(e.target.value)}*/}
      {/*    disabled={isDownloaded}*/}
      {/*  />*/}
      {/*</div>*/}
      <div>
        <Input
          inputTitle="Widget element pop-up text"
          placeholder="Сontact a manager"
          customClass={styles.input}
          value={buttonText}
          onChange={(e) => setButtonText(e.target.value)}
          disabled={isDownloaded}
        />
      </div>
    </div>
  );
};

export default WpWidgetInputs;
