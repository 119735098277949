import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import Switch from "react-switch";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { contentStyle } from "../../../constants/popups.js";
import "../../../assets/scss/modal.scss";
import styles from "./SetAssistantReplyModal.module.css";

import { updateData } from "../../../service/supabase";
import { fetchInstructions } from "../../../store/slices/assistantSlice";

export const SetAssistantReplyModal = ({
  isOpen,
  onClose,
  metadata,
  assistantId,
}) => {
  const dispatch = useDispatch();
  const [removeLinks, setRemoveLinks] = useState(false);
  const [splitByParagraphs, setSplitByParagraphs] = useState(false);
  const [allowPostponeMessages, setAllowPostponeMessages] = useState(false);

  useEffect(() => {
    setRemoveLinks(metadata.remove_links || false);
    setSplitByParagraphs(metadata.split_reply_by_paragraphs || false);
    setAllowPostponeMessages(metadata.allow_ai_postpone_messages || false);
  }, [metadata]);

  const handleSave = async () => {
    const newMetadata = {};

    newMetadata.remove_links = removeLinks;
    newMetadata.split_reply_by_paragraphs = splitByParagraphs;
    newMetadata.allow_ai_postpone_messages = allowPostponeMessages;

    try {
      await updateData(
        "assistants",
        { metadata: newMetadata },
        { id: assistantId }
      );
      dispatch(fetchInstructions());
      toast.success(
        "The settings for reply format have been successfully saved!"
      );
    } catch (error) {
      toast.error("Failed to save settings for for reply format");
    }

    onClose();
  };

  return (
    <Popup
      open={isOpen}
      closeOnDocumentClick
      onClose={onClose}
      {...{
        contentStyle,
      }}
    >
      <div className="modal">
        <h2 className="modalTitle">Reply format</h2>

        <p className="modalDesc">
          You can adjust the assistant's reply format by removing links and
          splitting replies into paragraphs
        </p>

        <div className={styles.modalToggle}>
          <div className={styles.toggleWrapper}>
            <label htmlFor="removeLinks" className="modalLabel">
              Remove links
            </label>

            <Switch
              id="removeLinks"
              onChange={(checked) => setRemoveLinks(checked)}
              checked={removeLinks}
              uncheckedIcon={false}
              checkedIcon={false}
              height={27}
              offColor="#FF0000"
              onColor="#19CE36"
            />
          </div>
          <div className={styles.toggleWrapper}>
            <label htmlFor="splitByParagraphs" className="modalLabel">
              Split reply by paragraphs
            </label>
            <Switch
              id="splitByParagraphs"
              onChange={(checked) => setSplitByParagraphs(checked)}
              checked={splitByParagraphs}
              uncheckedIcon={false}
              checkedIcon={false}
              height={27}
              offColor="#FF0000"
              onColor="#19CE36"
            />
          </div>
          <div className={styles.toggleWrapper}>
            <label htmlFor="postponeMessages" className="modalLabel">
              Allow AI to postpone messages
            </label>
            <Switch
              id="postponeMessages"
              onChange={(checked) => setAllowPostponeMessages(checked)}
              checked={allowPostponeMessages}
              uncheckedIcon={false}
              checkedIcon={false}
              height={27}
              offColor="#FF0000"
              onColor="#19CE36"
            />
          </div>
        </div>
        <div className="btns">
          <button type="button" className="modalBtn" onClick={onClose}>
            Close
          </button>
          <button
            type="button"
            onClick={handleSave}
            className="modalBtn saveBtn"
          >
            Save
          </button>
        </div>
      </div>
    </Popup>
  );
};
