import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useEdgesState, useNodesState } from "reactflow";
import {
  useGetAllFollowUpsQuery,
  usePublishFollowUpMutation,
} from "../../../store/api";
import { useDispatch, useSelector } from "react-redux";
import {
  convertCombinedToNodesAndEdges,
  convertNodesAndEdges,
} from "../components/EditFollowUp/utils";
import { useParams } from "react-router-dom";
import {
  EDGE_CONDITION_TYPES,
  NODE_TYPES,
} from "../components/EditFollowUp/const";
import {
  fetchFollowUps,
  fetchFunnelStatuses,
} from "../../../store/slices/followUpsSlice";

const FollowUpPageContext = createContext(null);

export const FollowUpPageProvider = ({ children }) => {
  const [isEditNodeModalOpen, setIsEditNodeModalOpen] = useState(false);
  const [isEditEdgeModalOpen, setIsEditEdgeModalOpen] = useState(false);
  const [isEditStartNodeModalOpen, setIsEditStartNodeModalOpen] =
    useState(false);
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [targetStatusName, setTargetStatusName] = useState(null);
  const [schedule, setSchedule] = useState(null);

  const dispatch = useDispatch();

  const [selectedNodeId, setSelectedNodeId] = useState(null);
  const [selectedEdgeId, setSelectedEdgeId] = useState(null);
  const user = useSelector((state) => state.auth.user);

  const { data: followUps, refetch: refetchFollowUps } =
    useGetAllFollowUpsQuery({ userID: user.account_id });

  const [updateFollowUp] = usePublishFollowUpMutation();
  const { id } = useParams();
  const followUp = useMemo(
    () => followUps?.find((followUp) => followUp.id === Number(id)),
    [followUps, id]
  );

  const followUpDefaultSteps = useMemo(() => {
    return followUp?.steps || [];
  }, [followUp]);

  useEffect(() => {
    refetchFollowUps();
    dispatch(fetchFunnelStatuses());
  }, []);

  useEffect(() => {
    setSchedule(followUp?.schedule);
    setTargetStatusName(followUp?.target_status_name);
  }, [followUp]);

  useEffect(() => {
    const { nodes, edges } =
      convertCombinedToNodesAndEdges(followUpDefaultSteps);
    setNodes(nodes);
    setEdges(edges);
  }, [followUpDefaultSteps]);

  const saveFollowUpSteps = useCallback(async () => {
    const newSteps = convertNodesAndEdges(nodes, edges);
    await updateFollowUp({
      ...followUp,
      steps: newSteps,
      account_id: user.account_id,
      target_status_name: targetStatusName,
      schedule: schedule,
    });
    await dispatch(fetchFollowUps());
  }, [
    nodes,
    edges,
    updateFollowUp,
    followUp,
    user.account_id,
    targetStatusName,
    schedule,
  ]);

  const updateNodeData = useCallback(
    ({ nodeName, nodePrompt, nodeDepth }) => {
      setNodes((prevNodes) => {
        return prevNodes.map((node) => {
          if (node.id === selectedNodeId) {
            return {
              ...node,
              data: {
                ...node.data,
                label: nodeName,
                prompt: nodePrompt,
                depth: nodeDepth,
              },
            };
          }
          return node;
        });
      });
    },
    [setNodes, selectedNodeId]
  );

  const updateEdgeData = useCallback(
    ({ edgeOption, edgeData }) => {
      setEdges((prevEdges) => {
        return prevEdges.map((edge) => {
          if (edge.id === selectedEdgeId) {
            return {
              ...edge,
              data: {
                ...edge.data,
                condition: edgeOption,
                value: edgeData,
              },
            };
          }
          return edge;
        });
      });
    },
    [setEdges, selectedEdgeId]
  );

  const updateStartNodeData = useCallback((conditionData) => {
    setNodes((prevNodes) => {
      return prevNodes.map((node) => {
        if (node.type === NODE_TYPES.start) {
          return {
            ...node,
            conditions: [
              {
                edge: 0,
                trigger: EDGE_CONDITION_TYPES.silence,
                silence: conditionData,
              },
            ],
          };
        }
        return node;
      });
    });
  }, []);

  const deleteNode = useCallback(
    (id) => {
      setNodes((prevNodes) => {
        return prevNodes.filter((node) => node.id !== id);
      });
      setEdges((prevEdges) => {
        return prevEdges.filter(
          (edge) => edge.source !== id && edge.target !== id
        );
      });
    },
    [setNodes, setEdges]
  );

  const contextValue = useMemo(() => {
    return {
      nodes,
      setNodes,
      onNodesChange,
      edges,
      setEdges,
      onEdgesChange,
      isEditNodeModalOpen,
      setIsEditNodeModalOpen,
      isEditEdgeModalOpen,
      setIsEditEdgeModalOpen,
      selectedNodeId,
      setSelectedNodeId,
      selectedEdgeId,
      setSelectedEdgeId,
      updateNodeData,
      updateEdgeData,
      deleteNode,
      saveFollowUpSteps,
      isEditStartNodeModalOpen,
      setIsEditStartNodeModalOpen,
      updateStartNodeData,
      schedule,
      setSchedule,
      targetStatusName,
      setTargetStatusName,
    };
  }, [
    nodes,
    setNodes,
    onNodesChange,
    edges,
    setEdges,
    onEdgesChange,
    isEditNodeModalOpen,
    setIsEditNodeModalOpen,
    isEditEdgeModalOpen,
    setIsEditEdgeModalOpen,
    selectedNodeId,
    setSelectedNodeId,
    selectedEdgeId,
    setSelectedEdgeId,
    updateNodeData,
    updateEdgeData,
    deleteNode,
    saveFollowUpSteps,
    isEditStartNodeModalOpen,
    setIsEditStartNodeModalOpen,
    updateStartNodeData,
    schedule,
    setSchedule,
    targetStatusName,
    setTargetStatusName,
  ]);

  return (
    <FollowUpPageContext.Provider value={contextValue}>
      {children}
    </FollowUpPageContext.Provider>
  );
};

export const useFollowUpPageContext = () => useContext(FollowUpPageContext);
