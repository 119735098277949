import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import supabase from "../../supabase";

const initialState = {
  selectedAssistantId: null,
  selectedIntegrationType: null,
  availableIntegrations: [],
  altegioToken: localStorage.getItem("altegioToken") || null,
  altegioUserName: localStorage.getItem("altegioUserName") || null,
};

// async thunk for getting Alteg token
export const getAltegToken = createAsyncThunk(
  "integration/getAltegToken",
  async ({ partnerToken, login, password }, thunkAPI) => {
    try {
      const response = await fetch("https://api.alteg.io/api/v1/auth", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/vnd.api.v2+json",
          Authorization: `Bearer ${partnerToken}`,
        },
        body: JSON.stringify({
          login,
          password,
        }),
      });

      const data = await response.json();
      if (!data.success) {
        throw new Error("Failed to get Alteg token");
      }

      return data;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const exchangeBitrixCodeForTokens = createAsyncThunk(
  "integration/exchangeBitrixCodeForTokens",
  async ({ code, userId, assistantId, domain }, thunkAPI) => {
    try {
      await fetch("https://api.mychatbot.app/bitrix/redirect-uri", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          code,
          state: `${userId}|${assistantId || ""}`,
          domain,
        }),
      });

      return;
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const fetchIntegrations = createAsyncThunk(
  "integration/fetchIntegrations",
  async (_, { getState }) => {
    const userId = getState().auth.user.account_id;
    const { data } = await supabase
      .from("integrations")
      .select("*")
      .eq("account_id", userId)
      .eq("is_knowledge_base", false);

    return data;
  }
);

export const integrationSlice = createSlice({
  name: "integration",
  initialState,
  reducers: {
    setSelectedAssistantId: (state, action) => {
      state.selectedAssistantId = action.payload;
    },
    setSelectedIntegrationType: (state, action) => {
      state.selectedIntegrationType = action.payload;
    },
    clearAltegioToken: (state) => {
      state.altegioToken = null;
      state.altegioUserName = null;
      localStorage.removeItem("altegioToken");
      localStorage.removeItem("altegioUserName");
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchIntegrations.fulfilled, (state, action) => {
        state.availableIntegrations = action.payload;
      })

      .addCase(getAltegToken.fulfilled, (state, action) => {
        const userToken = action.payload.data?.user_token;
        const userName = action.payload.data?.name;
        state.altegioToken = userToken;
        state.altegioUserName = userName;
        localStorage.setItem("altegioToken", userToken);
        localStorage.setItem("altegioUserName", userName);
      });
  },
});

export const {
  setSelectedAssistantId,
  clearAltegioToken,
  setSelectedIntegrationType,
} = integrationSlice.actions;

export const selectSelectedAssistant = (state) => {
  const selectedAssistantId = state.integration.selectedAssistantId;
  return state.assistant.listOfAssistants.find(
    (assistant) => assistant.id === selectedAssistantId
  );
};
export const selectAvailableIntegrations = (state) =>
  state.integration.availableIntegrations;
export const selectSelectedIntegrationType = (state) =>
  state.integration.selectedIntegrationType;
export const selectSelectedIntegrationsIdsByType = (state) => {
  if (
    !state.integration.selectedIntegrationType ||
    !state.integration.availableIntegrations
  ) {
    return [];
  }

  return state.integration.availableIntegrations
    ?.filter(
      (integration) =>
        integration.integration ===
        state.integration.selectedIntegrationType.name
    )
    .map((integration) => integration.id);
};

export const selectAltegioToken = (state) => state.integration.altegioToken;
export const selectAltegioUserName = (state) =>
  state.integration.altegioUserName;

export default integrationSlice.reducer;
