import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const integrationApi = createApi({
  reducerPath: "integrationApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    credentials: "include",
    timeout: 500000,
  }),
  tagTypes: ["Integration"],
  endpoints: (builder) => ({
    integrationToken: builder.mutation({
      query: (body) => ({
        url: "/integration-access-token",
        method: "POST",
        credentials: "include",
        body,
      }),
    }),
    validateIntegrationAssets: builder.mutation({
      query: (body) => ({
        url: "/validate-integration-assets",
        method: "POST",
        credentials: "include",
        body,
      }),
    }),
    revokeIntegration: builder.mutation({
      query: (body) => ({
        url: "/revoke-integration",
        method: "POST",
        credentials: "include",
        body,
      }),
    }),
  }),
});

export const {
  useIntegrationTokenMutation,
  useValidateIntegrationAssetsMutation,
  useRevokeIntegrationMutation,
} = integrationApi;
