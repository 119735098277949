import styles from "./TimePicker.module.scss";
import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  selectEndHours,
  selectEndMinutes,
  selectStartHours,
  selectStartMinutes,
  setEndHours,
  setEndMinutes,
  setStartHours,
  setStartMinutes,
} from "../../../store/slices/timePickerSlice";

import ClockFace from "./ClockFace";

const TimePicker = ({ time, onClick, isOpen, closeButton, type, onChange }) => {
  const dispatch = useDispatch();
  const startHours = useSelector(selectStartHours);
  const startMinutes = useSelector(selectStartMinutes);
  const endHours = useSelector(selectEndHours);
  const endMinutes = useSelector(selectEndMinutes);

  const arrayH = Array.from({ length: 24 }, (_, i) =>
    i.toString().padStart(2, "0")
  );
  const arrayM = Array.from({ length: 60 }, (_, i) =>
    i.toString().padStart(2, "0")
  );

  const timeArray =
    time === null
      ? [0, 0]
      : time.split(":").map((str) => parseInt(str, 10) - 1);

  const [minutesArray, setMinutesArray] = useState([]);
  const [hoursArray, setHoursArray] = useState([]);

  useEffect(() => {
    const timeArray =
      time === null
        ? [-1, -1]
        : time.split(":").map((str) => parseInt(str, 10) - 1);

    setMinutesArray([
      ...arrayM.slice(timeArray[1], 60),
      ...arrayM.slice(0, timeArray[1]),
    ]);

    setHoursArray([
      ...arrayH.slice(timeArray[0], 24),
      ...arrayH.slice(0, timeArray[0]),
    ]);
  }, [time]);

  const handleHoursChange = (newHour) => {
    if (type === "startClock") {
      dispatch(setStartHours(newHour));
    } else if (type === "endClock") {
      dispatch(setEndHours(newHour));
    }
  };

  const handleMinutesChange = (newMinute) => {
    if (type === "startClock") {
      dispatch(setStartMinutes(newMinute));
    } else if (type === "endClock") {
      dispatch(setEndMinutes(newMinute));
    }
  };

  const saveTimeValue = () => {
    if (type === "startClock") {
      if (startHours === "00" && startMinutes === "00") {
        onChange(null);
      } else {
        onChange(`${startHours}:${startMinutes}`);
      }
    } else if (type === "endClock") {
      if (endHours === "00" && endMinutes === "00") {
        onChange(null);
      } else {
        onChange(`${endHours}:${endMinutes}`);
      }
    }
    closeButton();
  };

  return (
    <div className={styles.container}>
      <button
        className={
          !isOpen
            ? `${styles.button} ${styles.buttonClose}`
            : `${styles.button} ${styles.buttonClose} ${styles.showButton}`
        }
        onClick={closeButton}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#3b4171"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M18 6 6 18" />
          <path d="m6 6 12 12" />
        </svg>
      </button>
      <button
        className={
          !isOpen
            ? `${styles.button} ${styles.buttonSave}`
            : `${styles.button} ${styles.buttonSave} ${styles.showButton}`
        }
        onClick={saveTimeValue}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#3b4171"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <path d="M20 6 9 17l-5-5" />
        </svg>
      </button>
      <div
        className={
          !isOpen
            ? `${styles.timePicker}`
            : `${styles.timePicker} ${styles.openClocks}`
        }
        onClick={onClick}
      >
        {time === null ? "--:--" : time}
        <div
          className={
            !isOpen
              ? `${styles.timePickerContent}`
              : `${styles.timePickerContent} ${styles.scale}`
          }
        >
          <div className={styles.clockBackground}>
            <div className={styles.clockShadow}>
              <ClockFace
                timeType={hoursArray}
                header="Hour"
                typeClock={type}
                onChange={handleHoursChange}
              />
              <ClockFace
                timeType={minutesArray}
                header="Minute"
                typeClock={type}
                onChange={handleMinutesChange}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimePicker;
