import React from "react";
import styles from "./TelegramAccountItem.module.scss";

export const TelegramAccountItem = ({
  account,

  onSelectedAccount,
  isActive,
}) => {
  return (
    <div
      className={`${styles.accountItem} ${
        isActive ? styles.activeAccount : ""
      }`}
      onClick={() => onSelectedAccount(account)}
    >
      <h2>{account?.metadata?.tg_account_name}</h2>
    </div>
  );
};
