export const ProductLink = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M31.2292 36.4583H34.3751C40.6667 36.4583 45.8334 31.3125 45.8334 25C45.8334 18.7083 40.6876 13.5416 34.3751 13.5416H31.2292"
        stroke="#384157"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.7501 13.5416H15.6251C9.31258 13.5416 4.16675 18.6875 4.16675 25C4.16675 31.2916 9.31258 36.4583 15.6251 36.4583H18.7501"
        stroke="#384157"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6667 25H33.3334"
        stroke="#384157"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
