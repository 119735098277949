export const Trash = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5818 18C10.847 18 11.1014 17.8946 11.2889 17.7071C11.4764 17.5196 11.5818 17.2652 11.5818 17V11C11.5818 10.7348 11.4764 10.4804 11.2889 10.2929C11.1014 10.1054 10.847 10 10.5818 10C10.3166 10 10.0622 10.1054 9.87468 10.2929C9.68714 10.4804 9.58179 10.7348 9.58179 11V17C9.58179 17.2652 9.68714 17.5196 9.87468 17.7071C10.0622 17.8946 10.3166 18 10.5818 18ZM20.5818 6H16.5818V5C16.5818 4.20435 16.2657 3.44129 15.7031 2.87868C15.1405 2.31607 14.3774 2 13.5818 2H11.5818C10.7861 2 10.0231 2.31607 9.46047 2.87868C8.89786 3.44129 8.58179 4.20435 8.58179 5V6H4.58179C4.31657 6 4.06222 6.10536 3.87468 6.29289C3.68714 6.48043 3.58179 6.73478 3.58179 7C3.58179 7.26522 3.68714 7.51957 3.87468 7.70711C4.06222 7.89464 4.31657 8 4.58179 8H5.58179V19C5.58179 19.7956 5.89786 20.5587 6.46047 21.1213C7.02308 21.6839 7.78614 22 8.58179 22H16.5818C17.3774 22 18.1405 21.6839 18.7031 21.1213C19.2657 20.5587 19.5818 19.7956 19.5818 19V8H20.5818C20.847 8 21.1014 7.89464 21.2889 7.70711C21.4764 7.51957 21.5818 7.26522 21.5818 7C21.5818 6.73478 21.4764 6.48043 21.2889 6.29289C21.1014 6.10536 20.847 6 20.5818 6ZM10.5818 5C10.5818 4.73478 10.6871 4.48043 10.8747 4.29289C11.0622 4.10536 11.3166 4 11.5818 4H13.5818C13.847 4 14.1014 4.10536 14.2889 4.29289C14.4764 4.48043 14.5818 4.73478 14.5818 5V6H10.5818V5ZM17.5818 19C17.5818 19.2652 17.4764 19.5196 17.2889 19.7071C17.1014 19.8946 16.847 20 16.5818 20H8.58179C8.31657 20 8.06222 19.8946 7.87468 19.7071C7.68714 19.5196 7.58179 19.2652 7.58179 19V8H17.5818V19ZM14.5818 18C14.847 18 15.1014 17.8946 15.2889 17.7071C15.4764 17.5196 15.5818 17.2652 15.5818 17V11C15.5818 10.7348 15.4764 10.4804 15.2889 10.2929C15.1014 10.1054 14.847 10 14.5818 10C14.3166 10 14.0622 10.1054 13.8747 10.2929C13.6871 10.4804 13.5818 10.7348 13.5818 11V17C13.5818 17.2652 13.6871 17.5196 13.8747 17.7071C14.0622 17.8946 14.3166 18 14.5818 18Z"
        fill="#1B1B1B"
      />
    </svg>
  );
};
