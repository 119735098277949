export const StaticWebsiteWidget = ({ width, height }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="190 200 390 366"
    fill="#6c47ff"
  >
    <g id="mychatbotlogo" data-name="mychatbot logo">
      <path
        className="cls-1"
        d="M388.03,188.72c-105.9,0-191.74,85.84-191.74,191.74s85.84,191.74,191.74,191.74,191.74-85.84,191.74-191.74-85.85-191.74-191.74-191.74Zm128.9,317.34h-128.9c-71.19,0-128.9-57.71-128.9-128.9s57.71-128.9,128.9-128.9,128.9,57.71,128.9,128.9c0,38.26-16.68,72.61-43.15,96.21l43.15,32.69Z"
      ></path>
      <path
        className="cls-1"
        d="M312.7,305.14h75.32c41.6,0,75.32,33.72,75.32,75.32s-33.72,75.32-75.32,75.32-75.32-33.72-75.32-75.32c0-22.36,9.75-42.43,25.22-56.22l-25.22-19.1Z"
      ></path>
    </g>
  </svg>
);
