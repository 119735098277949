import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import {
  BUTTON_SIDE_LEFT,
  FACEBOOK,
  INSTAGRAM,
  OLX,
  TELEGRAM,
  VIBER,
} from "./const";
import supabase from "../../../../supabase";
import { useSetupWebhookMutation } from "../../../../store/api";
import { selectUser } from "../../../../store/slices/authSlice";
import { SW_WIDGET, WIDGET_TYPES } from "../../../../constants/channels";

const CMSWidgetDataContext = createContext();

const defaultLinksUrls = {
  [VIBER]: "",
  [INSTAGRAM]: "",
  [TELEGRAM]: "",
  [OLX]: "",
  [FACEBOOK]: "",
};

export const CMSWidgetDataProvider = ({
  children,
  channelData,
  fetchChannels,
  assistantId,
  widgetType,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [widgetName, setWidgetName] = useState("");
  const [isWidgetNameValid, setIsWidgetNameValid] = useState(true);
  const [greetingText, setGreetingText] = useState("");
  const [buttonText, setButtonText] = useState("");
  const [widgetColor, setWidgetColor] = useState("");
  const [buttonPosition, setButtonPosition] = useState(BUTTON_SIDE_LEFT);
  const [buttonShow, setButtonShow] = useState(false);
  const [linksUrls, setLinksUrls] = useState(defaultLinksUrls);

  const [swScript, setSwScript] = useState(null);

  const [setupWebhook] = useSetupWebhookMutation();
  const user = useSelector(selectUser);

  // get channel data from props
  const channel = channelData[0];
  const metadata = useMemo(() => channel?.metadata ?? {}, [channel]);
  const isCheck = channel?.is_on ?? false;
  const isDownloaded = !!channel?.page_id;
  const isSwitchDisabled = !isDownloaded || isLoading;

  const cmsChannel = WIDGET_TYPES[widgetType].value;
  const cmsPath = WIDGET_TYPES[widgetType].path;

  useEffect(() => {
    setWidgetName(channel?.page_id ?? "");
  }, [channel]);

  useEffect(() => {
    setWidgetColor(metadata?.color);
    setButtonText(metadata?.pop_up_text);
    setButtonPosition(metadata?.button_position);
    setGreetingText(metadata?.button_text);
    setButtonShow(metadata?.button_has_text);
    setLinksUrls((prevState) => {
      return (
        metadata?.sm_pages?.reduce((acc, { name, url }) => {
          acc[name] = url;
          return acc;
        }, {}) ?? prevState
      );
    });
  }, [metadata]);

  const handleToggleConnectAssistant = useCallback(async () => {
    try {
      setIsLoading(true);

      if (!isCheck) {
        const { error } = await setupWebhook({
          channel: cmsChannel,
          account_id: user.account_id,
          page_id: widgetName,
        });

        if (error) {
          throw new Error("Failed to setup webhook");
        }
      } else {
        const { error } = await supabase
          .from("channels")
          .update({ is_on: false })
          .match({
            account_id: user.account_id,
            communication_channel: cmsChannel,
            page_id: widgetName,
          });

        if (error) {
          throw new Error("Failed to turn off channel");
        }
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      await fetchChannels();
      setIsLoading(false);
    }
  }, [
    isCheck,
    setupWebhook,
    cmsChannel,
    user.account_id,
    widgetName,
    fetchChannels,
  ]);

  const handleDownloadButton = useCallback(async () => {
    if (widgetName.trim() === "") {
      setIsWidgetNameValid(false);
      toast.error("Widget name is required");
      return;
    }

    try {
      setIsLoading(true);

      const page_id = widgetName;
      const account_id = user.account_id;

      const { error } = await supabase.from("channels").upsert([
        {
          page_id,
          account_id,
          access_token: account_id + page_id,
          is_on: false,
          communication_channel: cmsChannel,
          assistant_id: assistantId,
        },
      ]);

      if (error) {
        throw new Error("Failed to create new channel");
      }

      const requestData = {
        account_id: user.account_id,
        widget_id: widgetName,
        sm_pages: [
          { name: FACEBOOK, url: linksUrls?.[FACEBOOK] },
          { name: INSTAGRAM, url: linksUrls?.[INSTAGRAM] },
          { name: VIBER, url: linksUrls?.[VIBER] },
          { name: OLX, url: linksUrls?.[OLX] },
          { name: TELEGRAM, url: linksUrls?.[TELEGRAM] },
        ],
        color: widgetColor,
        pop_up_text: buttonText,
        widget_name: widgetName,
        button_position: buttonPosition,
        button_text: greetingText,
        button_has_text: buttonShow,
      };

      await axios.post(
        `${process.env.REACT_APP_API_URL}/downloads/${cmsPath}`,
        requestData
      );

      const widgetRequestUrl = `${process.env.REACT_APP_API_URL}/downloads/${cmsPath}/${account_id}/${page_id}`;

      if (widgetType === SW_WIDGET.id) {
        const widgetResponse = await axios.get(widgetRequestUrl);
        setSwScript(widgetResponse.data);
      } else {
        window.open(widgetRequestUrl);
        await fetchChannels();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [
    assistantId,
    buttonPosition,
    buttonShow,
    buttonText,
    cmsChannel,
    cmsPath,
    fetchChannels,
    greetingText,
    linksUrls,
    user.account_id,
    widgetColor,
    widgetName,
    widgetType,
  ]);

  const handleRemoveButton = useCallback(async () => {
    try {
      setIsLoading(true);
      const { error } = await supabase
        .from("channels")
        .delete()
        .eq("account_id", user.account_id)
        .eq("communication_channel", cmsChannel);

      if (error) {
        throw new Error("Failed to remove channel");
      }

      await fetchChannels();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setIsLoading(false);
    }
  }, [cmsChannel, fetchChannels, user.account_id]);

  const handleCloseSwModal = useCallback(() => {
    setSwScript(null);
    fetchChannels();
  }, [fetchChannels]);

  const contextValue = useMemo(
    () => ({
      widgetName,
      setWidgetName,
      greetingText,
      setGreetingText,
      buttonText,
      setButtonText,
      buttonPosition,
      setButtonPosition,
      buttonShow,
      setButtonShow,
      linksUrls,
      setLinksUrls,
      widgetColor,
      setWidgetColor,
      handleToggleConnectAssistant,
      isSwitchDisabled,
      isCheck,
      handleDownloadButton,
      handleRemoveButton,
      isDownloaded,
      isWidgetNameValid,
      setIsWidgetNameValid,
      swScript,
      handleCloseSwModal,
    }),
    [
      widgetName,
      greetingText,
      buttonText,
      buttonPosition,
      buttonShow,
      linksUrls,
      widgetColor,
      handleToggleConnectAssistant,
      isSwitchDisabled,
      isCheck,
      handleDownloadButton,
      handleRemoveButton,
      isDownloaded,
      isWidgetNameValid,
      widgetType,
      swScript,
      handleCloseSwModal,
    ]
  );

  return (
    <CMSWidgetDataContext.Provider value={contextValue}>
      {children}
    </CMSWidgetDataContext.Provider>
  );
};

const useCMSWidgetDataContext = () => {
  const context = useContext(CMSWidgetDataContext);

  if (!context) {
    throw new Error(
      "useCMSWidgetDataContext must be used within a CMSWidgetDataProvider"
    );
  }

  return context;
};

export default useCMSWidgetDataContext;
