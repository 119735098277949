import { useSelector } from "react-redux";
import { selectSelectedIntegrationType } from "../../../../../store/slices/integrationSlice";
import {
  INTEGRATION_NAMES,
  INTEGRATION_TYPES,
} from "../../../../../constants/integrations";

export const useIntegrationChecks = () => {
  const selectedIntegrationType = useSelector(selectSelectedIntegrationType);

  const selectedIntegrationAuthType = selectedIntegrationType?.authType;
  const selectedIntegrationName = selectedIntegrationType?.name;

  // INTEGRATION_TYPES
  const isAuthTypeApiRefreshToken =
    selectedIntegrationAuthType === INTEGRATION_TYPES.API_REFRESH_TOKEN;
  const isAuthTypeApiKey =
    selectedIntegrationAuthType === INTEGRATION_TYPES.API_KEY;
  const isAuthTypeOAuth =
    selectedIntegrationAuthType === INTEGRATION_TYPES.OAUTH;

  // INTEGRATION_NAMES
  const isSpreadsheetOrders =
    selectedIntegrationName === INTEGRATION_NAMES.SPREADSHEET_ORDERS;
  const isDDTuningCRM =
    selectedIntegrationName === INTEGRATION_NAMES.DDTUNING_CRM;
  const isServioHMS = selectedIntegrationName === INTEGRATION_NAMES.SERVIO;
  const isAltegio = selectedIntegrationName === INTEGRATION_NAMES.ALTEGIO;
  const isKeyCRM = selectedIntegrationName === INTEGRATION_NAMES.KEYCRM;
  const isSalesDrive =
    selectedIntegrationName === INTEGRATION_NAMES.SALES_DRIVE;
  const isBitrix = selectedIntegrationName === INTEGRATION_NAMES.BITRIX;
  const isKommo = selectedIntegrationName === INTEGRATION_NAMES.KOMMO;
  const isZoho = selectedIntegrationName === INTEGRATION_NAMES.ZOHO;
  const isWlaunch = selectedIntegrationName === INTEGRATION_NAMES.WLAUNCH;

  return {
    isAuthTypeApiRefreshToken,
    isAuthTypeApiKey,
    isAuthTypeOAuth,
    isSpreadsheetOrders,
    isDDTuningCRM,
    isAltegio,
    isKeyCRM,
    isServioHMS,
    isSalesDrive,
    isBitrix,
    isKommo,
    isZoho,
    isWlaunch,
  };
};
