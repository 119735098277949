export const Instagram = ({width, height}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 50 47" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_36_1651)">
                <path
                    d="M3.12489 3.17515C-0.804281 6.98432 -0.000113845 11.0307 -0.000113845 23.3235C-0.000113845 33.5318 -1.90845 43.7654 8.07905 46.1746C11.1978 46.9232 38.8311 46.9232 41.9457 46.1707C46.1041 45.1693 49.4874 42.0213 49.9499 36.5321C50.0145 35.766 50.0145 10.8946 49.9478 10.1129C49.4561 4.26599 45.5999 0.896265 40.5186 0.213765C39.3541 0.0562647 39.1207 0.00959796 33.1457 -0.000124253C11.952 0.00959796 7.30614 -0.871236 3.12489 3.17515Z"
                    fill="url(#paint0_linear_36_1651)"/>
                <path
                    d="M24.9956 6.10349C17.431 6.10349 10.2477 5.47543 7.50394 12.0477C6.37061 14.7621 6.53519 18.2874 6.53519 23.3352C6.53519 27.7646 6.38311 31.9277 7.50394 34.6207C10.2414 41.1968 17.4831 40.5668 24.9914 40.5668C32.2352 40.5668 39.7039 41.2707 42.481 34.6207C43.6164 31.879 43.4498 28.4063 43.4498 23.3352C43.4498 16.6035 43.8477 12.2577 40.3498 8.99488C36.8081 5.68932 32.0185 6.10349 24.9873 6.10349H24.9956ZM23.3414 9.20877C39.1206 9.18543 41.1289 7.54821 40.0206 30.2924C39.6269 38.3366 33.0644 37.4538 24.9977 37.4538C10.2894 37.4538 9.86644 37.061 9.86644 23.3274C9.86644 9.43432 11.0331 9.21655 23.3414 9.20488V9.20877ZM34.8498 12.069C33.6269 12.069 32.6352 12.9946 32.6352 14.136C32.6352 15.2774 33.6269 16.2029 34.8498 16.2029C36.0727 16.2029 37.0644 15.2774 37.0644 14.136C37.0644 12.9946 36.0727 12.069 34.8498 12.069ZM24.9956 14.486C19.7602 14.486 15.5164 18.4488 15.5164 23.3352C15.5164 28.2215 19.7602 32.1824 24.9956 32.1824C30.231 32.1824 34.4727 28.2215 34.4727 23.3352C34.4727 18.4488 30.231 14.486 24.9956 14.486ZM24.9956 17.5913C33.131 17.5913 33.1414 29.079 24.9956 29.079C16.8623 29.079 16.8498 17.5913 24.9956 17.5913Z"
                    fill="white"/>
            </g>
            <defs>
                <linearGradient id="paint0_linear_36_1651" x1="3.22076" y1="43.6859" x2="46.9213" y2="3.16195"
                                gradientUnits="userSpaceOnUse">
                    <stop stopColor="#FFDD55"/>
                    <stop offset="0.5" stopColor="#FF543E"/>
                    <stop offset="1" stopColor="#C837AB"/>
                </linearGradient>
                <clipPath id="clip0_36_1651">
                    <rect width="50" height="46.6667" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    );
};
