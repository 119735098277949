export const SUBSCRIPTIONS = [
  {
    title: "Standard",
    subscriptionIndex: 1,
    description: "Or $45/month when billed annually",
    price: 49,
    anotherOption: "Option 1",
    buttonTitle: "Continue",
    businessType: "Small business",
    support: "Standard",
    list: [
      "200 AI messages, renewed monthly",
      "AI Follow Up Module",
      "Chatbot in Telegram",
      "Chatbot in Viber",
      "Chat on the site",
      "Chatbot in Facebook",
      "Chatbot in Instagram",
      "Chatbot in OLX",
      "Unlimited users",
      "Unlimited chats",
      "AI knowledge base",
      "AI Image Search",
      "AI XML Database",
      "E-Commerce module",
    ],
    buttonURL: "https://secure.wayforpay.com/sub/mychatbot#671f60d5dd710",
  },
  {
    title: "Pro",
    subscriptionIndex: 2,
    description: "Or $89/month when billed annually",
    price: 99,
    anotherOption: "Option 2",
    buttonTitle: "Continue",
    businessType: "Medium business",
    support: "Pro",
    list: [
      "400 AI messages, renewed monthly",
      "Connection to CRM",
      "Connection to ERP",
      "Connection to LMS",
      "AI Follow Up Module",
      "Chatbot in Telegram",
      "Chatbot in Viber",
      "Chat on the site",
      "Chatbot in Facebook",
      "Chatbot in Instagram",
      "Chatbot in OLX",
      "Unlimited users",
      "Unlimited chats",
      "AI knowledge base",
      "AI Image Search",
      "AI XML Database",
      "E-Commerce module",
    ],
    buttonURL: "https://secure.wayforpay.com/sub/mychatbot#671f610ba410b",
  },
  {
    title: "Multipro",
    subscriptionIndex: 3,
    description: "Or $179/month when billed annually",
    price: 199,
    anotherOption: "Option 3",
    buttonTitle: "Continue",
    support: "Multipro",
    list: [
      "800 AI messages, renewed monthly",
      "Multi-account",
      "Connection to CRM",
      "Connection to ERP",
      "Connection to LMS",
      "All Follow Up Module",
      "Chatbot in Telegram",
      "Chatbot in Viber",
      "Chat on the site",
      "Chatbot in Facebook",
      "Chatbot in Instagram",
      "Chatbot in OLX",
      "Unlimited users",
      "Unlimited chats",
      "AI knowledge base",
      "AI Image Search",
      "AI XML Database",
      "E-Commerce module",
    ],
    businessType: "Medium business with branches",
    buttonURL: "https://secure.wayforpay.com/sub/mychatbot#671f613a32036",
  },
  {
    title: "Corporate",
    subscriptionIndex: 4,
    description: "",
    price: 2999,
    anotherOption: "Option 4",
    buttonTitle: "Continue",
    businessType: "Big business",
    support: "Corporate",
    list: [
      "1600 AI messages, renewed monthly",
      "CUSTOM INTEGRATIONS",
      "Multi-account",
      "Connection to CRM",
      "Connection to ERP",
      "Connection to LMS",
      "All Follow Up Module",
      "Chatbot in Telegram",
      "Chatbot in Viber",
      "Chat on the site",
      "Chatbot in Facebook",
      "Chatbot in Instagram",
      "Chatbot in OLX",
      "Unlimited users",
      "Unlimited chats",
      "AI knowledge base",
      "AI Image Search",
      "AI XML Database",
      "E-Commerce module",
    ],
    buttonURL: "https://secure.wayforpay.com/sub/mychatbot#671f6160adad7",
  },
];
