import { ChromePicker } from "react-color";

import styles from "./WidgetColorPicker.module.scss";
import useCMSWidgetDataContext from "../../useCMSWidgetDataContext";

const WidgetColorPicker = () => {
  const { setWidgetColor, isDownloaded, widgetColor } =
    useCMSWidgetDataContext();

  return (
    <div className={isDownloaded ? styles.wrapperDisabled : ""}>
      <ChromePicker
        className={styles.colorPicker}
        color={widgetColor}
        onChange={(color) => {
          if (isDownloaded) return;
          setWidgetColor(color.hex);
        }}
        defaultColor={widgetColor}
        disableAlpha
        defaultView="hex"
      />
    </div>
  );
};

export default WidgetColorPicker;
