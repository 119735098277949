import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  startClockOpen: false,
  endClockOpen: false,
  startHours: "00",
  startMinutes: "00",
  endHours: "00",
  endMinutes: "00",
};

const timePickerSlice = createSlice({
  name: "timePicker",
  initialState,
  reducers: {
    toggleStartClockOpen: (state, action) => {
      state.startClockOpen = action.payload;
    },
    toggleEndClockOpen: (state, action) => {
      state.endClockOpen = action.payload;
    },
    setStartHours: (state, action) => {
      state.startHours = action.payload;
    },
    setStartMinutes: (state, action) => {
      state.startMinutes = action.payload;
    },
    setEndHours: (state, action) => {
      state.endHours = action.payload;
    },
    setEndMinutes: (state, action) => {
      state.endMinutes = action.payload;
    },
  },
});
export const {
  toggleStartClockOpen,
  toggleEndClockOpen,
  setStartHours,
  setStartMinutes,
  setEndHours,
  setEndMinutes,
} = timePickerSlice.actions;

export const selectStartClockOpen = (state) => state.timePicker.startClockOpen;
export const selectEndClockOpen = (state) => state.timePicker.endClockOpen;
export const selectStartHours = (state) => state.timePicker.startHours;
export const selectStartMinutes = (state) => state.timePicker.startMinutes;
export const selectEndHours = (state) => state.timePicker.endHours;
export const selectEndMinutes = (state) => state.timePicker.endMinutes;

export default timePickerSlice.reducer;
