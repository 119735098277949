import React, { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../store/slices/authSlice";
import {
  fetchKnowledgeBase,
  addNewKnowledgeBase,
  _listOfKnowledgeBase,
} from "../../store/slices/knowledgeBaseSlice.js";
import { Loader } from "../../components";
import { Title } from "../../components/Title";
import { Button } from "../../components";
import { Card } from "./components/Card";
import {
  IntegrationGoogleAuth,
  IntegrationWithKeys,
} from "../../components/Integration";
import { AddKnowledgeBaseName } from "../../components/popups/AddKnowledgeBaseName";
import { useSubscription } from "../SubscriptionPage/useSubscription";
import icons from "../../assets/icons/icons.svg";

import styles from "./KnowledgeBase.module.scss";
import { TOOL_TYPE } from "../../constants/general";
import { DOCUMENT_TYPES } from "../../constants/knowledgeBaseItems";
import { normalizeSubscriptionPlan } from "../../helper";

export const KnowledgeBasePage = () => {
  const [subscriptionLoaded, setSubscriptionLoaded] = useState(false);
  const [integrationInProgressId, setIntegrationInProgressId] = useState(null);

  const dispatch = useDispatch();
  const user = useSelector(selectUser);
  const { subscriptionPlan, isFetchingSubscription } = useSubscription(user);
  const listOfKnowledgeBase = useSelector(_listOfKnowledgeBase);

  const normalizedPlan = normalizeSubscriptionPlan(subscriptionPlan);

  useEffect(() => {
    // Checking if subscriptionPlan is loaded
    if (!isFetchingSubscription && normalizedPlan !== undefined) {
      setSubscriptionLoaded(true);
    }
  }, [normalizedPlan, isFetchingSubscription]);

  const [isLoadingRefreshData, setIsLoadingRefreshData] = useState(false);
  const [selectedIdKnowledgeBase, setSelectedIdKnowledgeBase] = useState(null);
  const [selectedKnowledgeBase, setSelectedKnowledgeBase] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const saveKnowledgeBase = (value) => {
    setIsOpen(false);
    if (!value) return;
    dispatch(
      addNewKnowledgeBase({
        id: value,
        knowledge_base: value,
      })
    );
    setSelectedIdKnowledgeBase(value);
  };

  useEffect(() => {
    if (listOfKnowledgeBase.length === 0) {
      setSelectedIdKnowledgeBase(null);
      setSelectedKnowledgeBase(null);
      return;
    }

    const currentKnowledgeBase = listOfKnowledgeBase.find(
      (el) => el.id === selectedIdKnowledgeBase
    );

    if (!currentKnowledgeBase) {
      // The selected knowledge base no longer exists automatically select the first available knowledge base
      setSelectedIdKnowledgeBase(listOfKnowledgeBase[0].id);
      setSelectedKnowledgeBase(listOfKnowledgeBase[0]);
    } else {
      setSelectedKnowledgeBase(currentKnowledgeBase);
    }
  }, [listOfKnowledgeBase, selectedIdKnowledgeBase]);

  const onClickAddCard = () => {
    setIsOpen(true);
  };

  const onClickRefreshData = async () => {
    try {
      setIsLoadingRefreshData(true);

      const data = await axios.post(
        `${process.env.REACT_APP_API_URL}/refresh-knowledge-integration-data?account=${user.account_id}&knowledge_base=${selectedIdKnowledgeBase}`
      );

      if (data.status === 200) {
        setTimeout(() => {
          setIsLoadingRefreshData(false);
          toast.success(
            "Knowledge refresh iniated, will take up to 5 minutes to propagate"
          );
        }, 250);
      }
    } catch (error) {
      toast.log(error.message);
      setIsLoadingRefreshData(false);
    }
  };

  const onClickSave = () => {
    setSelectedIdKnowledgeBase(null);
    setSelectedKnowledgeBase(null);
  };

  const getLink = (integration, id, accessToken) => {
    if (
      accessToken &&
      ["OpenCart CMS", "WordPress CMS", "XML Product Feed"].includes(
        integration
      )
    ) {
      return accessToken;
    }
    if (!id) return "";
    if (integration === "Spreadsheet Inventory") {
      return `https://docs.google.com/spreadsheets/d/${id}/edit`;
    }
    return `https://docs.google.com/document/d/${id}/edit`;
  };

  const fetchIntegrations = (value) => {
    dispatch(fetchKnowledgeBase(user.account_id));
  };

  useEffect(() => {
    fetchIntegrations();
  }, [user]);

  useEffect(() => {
    if (!selectedIdKnowledgeBase) return;
    const result = listOfKnowledgeBase.find(
      (el) => el.id === selectedIdKnowledgeBase
    );
    if (!result) return;
    setSelectedKnowledgeBase(result);
  }, [listOfKnowledgeBase, selectedIdKnowledgeBase]);

  useEffect(() => {
    if (listOfKnowledgeBase.length) return;
    onClickSave();
  }, [listOfKnowledgeBase]);

  const isAnyCMSConnected = useMemo(() => {
    return selectedKnowledgeBase?.items?.some((el) => {
      return el.type === TOOL_TYPE.CMS && el.connected;
    });
  }, [selectedKnowledgeBase]);

  const isAnyDocumentConnected = useMemo(() => {
    return selectedKnowledgeBase?.items?.some((el) => {
      return (
        DOCUMENT_TYPES.includes(el.integration.toLowerCase()) && el.connected
      );
    });
  }, [selectedKnowledgeBase]);

  if (!subscriptionLoaded || isFetchingSubscription) {
    return (
      <div className={styles.loaderContainer}>
        <Loader height={40} width={40} />
      </div>
    );
  }

  return (
    <div className={`${styles.wrapper} customScroll`}>
      <AddKnowledgeBaseName
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        saveKnowledgeBase={saveKnowledgeBase}
      />
      <div className={`${styles.textContainer}`}>
        <div className={styles.titleContainer}>
          <Title title={"Knowledge Base"} />
          <Button
            className={styles.titleButton}
            title={"add new knowledge Base"}
            onClick={onClickAddCard}
            disabled={normalizedPlan === null}
          />
        </div>
        <div className={styles.gallery}>
          {listOfKnowledgeBase &&
            listOfKnowledgeBase?.map((el) => {
              return (
                <Card
                  btnText={"EDIT"}
                  title={el.title}
                  key={el.id}
                  id={el.id}
                  selectedId={selectedIdKnowledgeBase}
                  clickHandler={setSelectedIdKnowledgeBase}
                  subscriptionPlan={normalizedPlan}
                >
                  <svg width="50" height="50">
                    <use href={`${icons}#icon-target`}></use>
                  </svg>
                </Card>
              );
            })}
        </div>
        {selectedKnowledgeBase && (
          <div className={`${styles.textContainer}`}>
            <div className={styles.titleContainer}>
              <Title title={selectedKnowledgeBase?.title} />
              <div className={styles.buttonsTitleBox}>
                <Button
                  className={`${styles.titleButton}`}
                  title={"SAVE"}
                  onClick={onClickSave}
                />
                <Button
                  className={styles.titleButton}
                  title={"REFRESH DATA"}
                  onClick={onClickRefreshData}
                />
              </div>
            </div>
            <div className={styles.gallery}>
              {selectedKnowledgeBase?.items?.map((el) => {
                const sharedProps = {
                  title: el.integration,
                  sheetId: el.sheet_id,
                  accessToken: el.access_token,
                  refreshToken: el.refresh_token,
                  docLink: getLink(
                    el.integration,
                    el.sheet_id,
                    el.access_token
                  ),
                  knowledgeBase: selectedIdKnowledgeBase,
                  description: el?.description,
                  icon: el?.icon,
                  key: el.id,
                  id: el.id,
                  scope: el?.scope,
                  connected: el?.connected,
                  isWorking: el?.isWorking,
                  fetchIntegrations: fetchIntegrations,
                  isLoadingRefreshData: isLoadingRefreshData,
                  isAnyDocumentConnected: isAnyDocumentConnected,
                  integrationInProgressId,
                  setIntegrationInProgressId,
                };

                const isGoogleAuthIntegration =
                  el.isGoogleAuth ||
                  ("type" in el && el.type !== TOOL_TYPE.CMS);

                return isGoogleAuthIntegration ? (
                  <IntegrationGoogleAuth {...sharedProps}>
                    <svg width="50" height="50">
                      <use href={`${icons}#icon-img-placeholder`}></use>
                    </svg>
                  </IntegrationGoogleAuth>
                ) : (
                  <IntegrationWithKeys
                    {...sharedProps}
                    isAnyCMSConnected={isAnyCMSConnected}
                  />
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
