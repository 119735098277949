import { formatDateSubscription } from "../../helper";

export const getButtonProps = (
  userSubscription,
  subscriptionPlan,
  cancelPlan,
  upgradePlan
) => {
  if (!userSubscription || Object.keys(userSubscription).length === 0) {
    return {
      title: "Continue",

      style: {
        background:
          "linear-gradient(146.18deg, #81b1eb 24.02%, #6c48ff 133.109%)",
        color: "#ffffff",
      },
    };
  }

  const { subscription_plan, ends_at, cancel_requested, upgrade_requested } =
    userSubscription;

  let currentPlan = String(subscription_plan).toLowerCase();
  let isTrial = false;

  if (currentPlan.endsWith("-trial")) {
    isTrial = true;
    currentPlan = currentPlan.replace("-trial", "");
  }

  let requestedPlan = String(subscriptionPlan).toLowerCase();

  // Marking that trial is active but allowing to purchase a subscription
  if (
    currentPlan === requestedPlan &&
    ends_at !== null &&
    isTrial &&
    !cancel_requested
  ) {
    return {
      title: `Trial ends at ${formatDateSubscription(ends_at)}`,
      style: {
        background: "#EEDAFF",
        color: "#777EF5",
      },
      tooltip: "You can purchase a full subscription before your trial ends",
    };
  }

  if (currentPlan === requestedPlan && ends_at === null && !cancel_requested) {
    return {
      title: "✓ Your plan",
      style: { background: "#EEDAFF", color: "#777EF5", pointerEvents: "none" },
    };
  }

  if (currentPlan !== requestedPlan || currentPlan === "") {
    if (ends_at === null && cancel_requested) {
      return {
        title: "Continue",
        tooltip: "You will be able to upgrade soon",
      };
    }

    if (
      ends_at !== null &&
      !cancel_requested &&
      upgrade_requested &&
      String(upgrade_requested).toLowerCase() === subscriptionPlan.toLowerCase()
    ) {
      return {
        title: "Upgrade requested",
        tooltip: "You will receive payment link over the email",
      };
    }

    if (
      ends_at !== null &&
      !cancel_requested &&
      upgrade_requested &&
      String(upgrade_requested).toLowerCase() !== subscriptionPlan.toLowerCase()
    ) {
      return {
        title: "Continue",
        tooltip: "You already requested plan upgrade",
      };
    }

    if (
      ends_at === null &&
      !cancel_requested &&
      upgrade_requested &&
      String(upgrade_requested).toLowerCase() !== subscriptionPlan.toLowerCase()
    ) {
      return {
        title: "Continue",
        tooltip: "You need to request cancellation of your current plan first",
      };
    }

    if (ends_at === null && !cancel_requested && !upgrade_requested) {
      return {
        title: "Continue",
        tooltip: "You need to request cancellation of your current plan first",
      };
    }

    if (ends_at !== null && !cancel_requested && !upgrade_requested) {
      return {
        title: "Continue",

        tooltip:
          "You can request change of the plan, we will send the upgrade link to your email",
      };
    }
  }

  if (currentPlan === requestedPlan && ends_at !== null && !cancel_requested) {
    return {
      title: `Ends at ${formatDateSubscription(ends_at)}`,
    };
  }

  return {
    title: "Continue",
    tooltip: "You need to request cancellation of your current plan first",
  };
};
