import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from "@reduxjs/toolkit";
import supabase from "../../supabase";
import { KNOWLEDGEBASE } from "../../constants/knowledgeBaseItems";

const initialState = {
  listOfKnowledgeBase: [],
  status: "",
  error: null,
};

export const fetchKnowledgeBase = createAsyncThunk(
  "knowledgeBase/fetchKnowledgeBase",
  async (userId) => {
    const { data } = await supabase
      .from("integrations")
      .select("*")
      .eq("account_id", userId)
      .eq("is_knowledge_base", true);
    return data;
  },
);

const knowledgeBaseSlice = createSlice({
  name: "knowledgeBase",
  initialState,
  reducers: {
    addNewKnowledgeBase: (state, action) => {
      state.listOfKnowledgeBase = [
        ...state.listOfKnowledgeBase,
        action.payload,
      ];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchKnowledgeBase.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchKnowledgeBase.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.listOfKnowledgeBase = action.payload;
      })
      .addCase(fetchKnowledgeBase.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

const selectKnowledgeBaseState = (state) => state.knowledgeBase;
export const _listOfKnowledgeBase = createSelector(
  [selectKnowledgeBaseState],
  (state) => {
    try {
      if (!state.listOfKnowledgeBase.length) return [];

      const items = state.listOfKnowledgeBase?.map(
        ({ knowledge_base }) => knowledge_base,
      );

      const result = [...new Set(items)].map((el, index) => {
        const title = Number.isFinite(el) ? `Knowledge base №${el}` : el;
        const item = {
          id: el,
          value: el,
          title,
          items: [...KNOWLEDGEBASE],
        };

        if (
          state.listOfKnowledgeBase.find(
            ({ knowledge_base }) => knowledge_base === el,
          )?.access_token
        ) {
          item.items = [
            ...state.listOfKnowledgeBase
              ?.filter(({ knowledge_base }) => knowledge_base === el)
              .map((el) => {
                const obj = KNOWLEDGEBASE.find(
                  (item) => el.integration === item.integration,
                );
                if (obj?.icon)
                  return { ...el, connected: true, icon: obj?.icon };
                return { ...el, connected: true };
              }),
            ...KNOWLEDGEBASE,
          ];
        }

        return item;
      });
      return result;
    } catch (error) {
      console.log(error);
    }
  },
);

export const { addNewKnowledgeBase } = knowledgeBaseSlice.actions;

export default knowledgeBaseSlice.reducer;
