import React from "react";
import { formatDate } from "../../helper";
import userAvatar from "../../assets/img/user.png";

import styles from "./UserChat.module.scss";

export const UserChat = ({
  pageID,
  avatar_url,
  full_name,
  connection,
  onClick,
  last_active,
  is_selected,
  bgColor,
  unread,
}) => {
  return (
    <div
      onClick={onClick}
      className={`${styles.container} ${
        is_selected ? styles.containerActive : ""
      }`}
      style={{ backgroundColor: bgColor }}
    >
      {!!Number(unread) && (
        <div className={styles.unread}>
          <span className={styles.quantity}>{unread}</span>
        </div>
      )}
      <div className={styles.userCard}>
        <img
          src={avatar_url || userAvatar}
          alt="avatar"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = userAvatar;
          }}
        />
        <div className={styles.userInfo}>
          {pageID && <div className={styles.page}>{pageID}</div>}
          <div className={styles.nameWrap}>
            <div className={styles.name}>{full_name}</div>
            {connection}
          </div>
        </div>
      </div>
      <div className={styles.time}>{formatDate(last_active)}</div>
    </div>
  );
};
