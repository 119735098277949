import styles from "./ReplyMessage.module.scss";

export const ReplyMessage = ({ originalMessage }) => {
  if (!originalMessage) return null;

  // Check if the original message is a link (possible image)
  const isImageReply = originalMessage.startsWith("https://");

  return (
    <div className={styles.replyMessageContainer}>
      <div className={styles.replyHeader}>Reply to:</div>
      {isImageReply ? (
        <img
          src={originalMessage}
          alt="Replied Image"
          className={styles.replyImage}
        />
      ) : (
        <div className={styles.replyText}>{originalMessage}</div>
      )}
    </div>
  );
};
