export const Faq = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_0_3599)">
        <mask
          id="mask0_0_3599"
          
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="50"
          height="50"
        >
          <path d="M0 0.000144958H49.9999V50H0V0.000144958Z" fill="white" />
        </mask>
        <g mask="url(#mask0_0_3599)">
          <path
            d="M46.3193 37.5889L47.4755 44.5342L40.5302 43.3779C38.1308 44.7861 35.3378 45.5938 32.3544 45.5938C26.0654 45.5938 20.6142 42.0059 17.9365 36.7647C26.7392 36.6094 33.8261 29.4258 33.8261 20.587C33.8261 17.9405 33.1904 15.4415 32.0634 13.2354C32.1601 13.2335 32.2568 13.2325 32.3544 13.2325C41.2909 13.2325 48.5351 20.4766 48.5351 29.4131C48.5351 32.3955 47.7274 35.1895 46.3193 37.5889Z"
            stroke="#384157"
            strokeWidth="2.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M40.0042 29.4131H31.3054"
            stroke="#384157"
            strokeWidth="2.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M40.0042 35.8466H24.7124"
            stroke="#384157"
            strokeWidth="2.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M40.0041 22.9796H33.7964"
            stroke="#384157"
            strokeWidth="2.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M33.8261 20.587C33.8261 29.4258 26.7392 36.6094 17.9365 36.7646C17.8398 36.7666 17.7431 36.7676 17.6455 36.7676C14.6621 36.7676 11.8691 35.96 9.4697 34.5518L2.52441 35.708L3.68066 28.7627C2.27246 26.3633 1.46484 23.5694 1.46484 20.587C1.46484 11.6505 8.70896 4.40635 17.6455 4.40635C23.9345 4.40635 29.3857 7.99423 32.0634 13.2354C33.1903 15.4415 33.8261 17.9405 33.8261 20.587Z"
            stroke="#384157"
            strokeWidth="2.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M13.9602 15.9873C13.9602 13.9519 15.6102 12.302 17.6454 12.302C19.6808 12.302 21.3308 13.9519 21.3308 15.9873C21.3308 18.0226 19.6808 19.6725 17.6454 19.6725V23.989"
            stroke="#384157"
            strokeWidth="2.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M17.6455 30.3369C18.4117 30.3369 19.1458 29.6632 19.1104 28.8721C19.0748 28.0784 18.4667 27.4072 17.6455 27.4072C16.8793 27.4072 16.1452 28.081 16.1807 28.8721C16.2162 29.6657 16.8243 30.3369 17.6455 30.3369Z"
            fill="#384157"
            stroke="#384157"
            strokeWidth="2.5"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_0_3599">
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
