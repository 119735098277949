import React, { useState } from "react";
import { useSelector } from "react-redux";
import classNames from "classnames";
import { FaExternalLinkAlt } from "react-icons/fa";
import { FaRegTrashAlt } from "react-icons/fa";
import { FiAlertCircle } from "react-icons/fi";
import { selectAllNotifications } from "../../../../store/slices/notificationsSlice";
import NotificationModal from "../NotificationModal/NotificationModal";
import useNotificationData from "../../useNotificationData";

import styles from "./NotificationsTableBody.module.scss";

const NotificationsTableBody = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedNotificationId, setSelectedNotificationId] = useState(null);
  const { removeNotification, markAsRead } = useNotificationData();
  const notifications = useSelector(selectAllNotifications);

  return (
    <div className={styles.bodyWrapper}>
      {notifications?.map((notification) => (
        <div className={styles.rowWrapper} key={notification.id}>
          <div className={styles.newIconCell}>
            {notification.status === "new" ? (
              <FiAlertCircle
                className={styles.newIcon}
                title="Mark as seen"
                onClick={async () => {
                  await markAsRead(notification.id);
                }}
              />
            ) : null}
          </div>
          <div
            className={classNames(styles.dataContainer, {
              [styles.new]: notification.status === "new",
            })}
          >
            <div className={styles.cell}>
              <p>{notification.message}</p>
            </div>
            <div className={styles.cell}>
              <p>{notification.type}</p>
            </div>
            <div className={styles.cell}>
              <p>{notification.status}</p>
            </div>
            <div className={styles.cell}>
              <p>{new Date(notification.created_at).toLocaleString()}</p>
            </div>
            <div className={styles.actionsCell}>
              <FaExternalLinkAlt
                className={styles.icon}
                onClick={() => {
                  setIsModalOpen(true);
                  setSelectedNotificationId(notification.id);
                }}
                title="View details"
              />
              <FaRegTrashAlt
                className={styles.icon}
                onClick={() => [removeNotification(notification.id)]}
                title="Remove notification"
              />
            </div>
          </div>
        </div>
      ))}
      <NotificationModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
        notificationData={notifications?.find(
          (notification) => notification.id === selectedNotificationId
        )}
      />
    </div>
  );
};

export default NotificationsTableBody;
