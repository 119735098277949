export const Salesdrive = ({ width, height }) => (
  <svg
    version="1.1"
    id="Слой_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="225.667px"
    height="204px"
    viewBox="0 0 225.667 204"
    enableBackground="new 0 0 225.667 204"
    color="#205e9"
  >
    <g id="Слой_2" display="none">
      <rect
        x="63.5"
        y="-26.5"
        display="inline"
        fill="#456FE5"
        stroke="#205e90"
        strokeMiterlimit="10"
        width="1319"
        height="629"
      />
    </g>
    <g id="Слой_1_1_">
      <g>
        <path
          fill="none"
          stroke="#205e90"
          strokeWidth="15"
          strokeMiterlimit="10"
          d="M216.248,39.554
			c0,18.922-46.016,28.851-102.779,28.851S10.69,58.476,10.69,39.554c0-18.921,46.017-28.489,102.779-28.489
			C170.233,11.065,216.248,20.633,216.248,39.554z"
        />
        <path
          fill="none"
          stroke="#205e90"
          strokeWidth="15"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="M24.178,86.436
			c0,0,3.967,15.868,89.291,15.868c76.418,0,89.221-15.868,89.221-15.868"
        />
        <path
          fill="none"
          stroke="#205e90"
          strokeWidth="15"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="M45.815,127.368
			c0,0,2.886,8.54,67.654,8.54c62.895,0,66.862-8.24,66.862-8.24"
        />
        <path
          fill="none"
          stroke="#205e90"
          strokeWidth="15"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="M78.633,163.587
			c0,0,8.655,3.273,34.836,3.273c25.028,0,33.323-2.894,33.323-2.894"
        />
        <path
          fill="none"
          stroke="#205e90"
          strokeWidth="15"
          strokeLinecap="round"
          strokeMiterlimit="10"
          d="M92.337,193.014
			c0,0,5.29,1.637,21.132,1.637c18.297,0,21.904-1.637,21.904-1.637"
        />
      </g>
    </g>
  </svg>
);
