export const RulesAndTerms = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_0_3619)">
        <path
          d="M11.9521 16.1895H35.8652C36.8896 16.1895 37.7207 15.3584 37.7207 14.334C37.7207 13.3096 36.8896 12.4785 35.8652 12.4785H11.9521C10.9277 12.4785 10.0967 13.3096 10.0967 14.334C10.0967 15.3584 10.9277 16.1895 11.9521 16.1895ZM28 25.9004C28 24.876 27.1689 24.0449 26.1445 24.0449H11.9521C10.9277 24.0449 10.0967 24.876 10.0967 25.9004C10.0967 26.9248 10.9277 27.7559 11.9521 27.7559H26.1445C27.1689 27.7559 28 26.9248 28 25.9004Z"
          fill="#384157"
          stroke="white"
        />
        <path
          d="M47.5225 31.6123C47.7217 29.4316 47.8555 26.8906 47.8584 23.9287C47.8525 18.3555 47.3848 14.2744 46.8857 11.2754C46.0127 6.33398 41.5244 1.8457 36.583 0.972656C33.584 0.473633 29.5029 0.00585938 23.9287 0C18.3555 0.00585938 14.2734 0.473633 11.2744 0.972656C6.33398 1.8457 1.8457 6.33398 0.972656 11.2754C0.473633 14.2744 0.00683594 18.3555 0 23.9287C0.00683594 29.502 0.473633 33.584 0.972656 36.583C1.8457 41.5244 6.33398 46.0127 11.2754 46.8857C14.2744 47.3848 18.3555 47.8516 23.9297 47.8584C26.8906 47.8555 29.4316 47.7217 31.6113 47.5225C33.5488 49.0723 36.0059 50 38.6797 50C44.9316 50 50.001 44.9316 50.001 38.6797C50.001 36.0059 49.0732 33.5488 47.5234 31.6123H47.5225ZM23.9297 44.1475C19.5332 44.1426 15.5996 43.8418 11.9053 43.2285C10.2637 42.9346 8.60059 42.0137 7.22266 40.6357C5.84473 39.2578 4.9248 37.5957 4.62988 35.9531C4.0166 32.2627 3.7168 28.3291 3.71094 23.9287C3.71582 19.5303 4.0166 15.5967 4.62988 11.9043C4.92383 10.2627 5.84473 8.60059 7.22266 7.22266C8.60059 5.84473 10.2627 4.9248 11.9053 4.62988C15.5977 4.0166 19.5312 3.7168 23.9297 3.71094C28.3262 3.71582 32.2598 4.0166 35.9541 4.62988C37.5957 4.92383 39.2578 5.84473 40.6367 7.22266C42.0146 8.60059 42.9346 10.2627 43.2295 11.9053C43.8428 15.5996 44.1426 19.5322 44.1484 23.9297C44.1465 25.5801 44.1016 27.1631 44.0156 28.6943C42.4258 27.8428 40.6094 27.3604 38.6797 27.3604C32.4277 27.3604 27.3584 32.4287 27.3584 38.6807C27.3584 40.6104 27.8418 42.4258 28.6924 44.0156C27.1611 44.1016 25.5781 44.1465 23.9287 44.1475H23.9297ZM38.6797 46.2891C34.4834 46.2891 31.0693 42.876 31.0693 38.6797C31.0693 34.4834 34.4834 31.0703 38.6797 31.0703C42.876 31.0703 46.29 34.4834 46.29 38.6797C46.29 42.876 42.876 46.2891 38.6797 46.2891Z"
          fill="#384157"
          stroke="white"
        />
        <path
          d="M39.6758 40.8438C39.4395 40.8047 39.1182 40.7676 38.6797 40.7676C38.2412 40.7676 37.9199 40.8047 37.6836 40.8438C37.2949 40.9121 36.9414 41.2656 36.8721 41.6553C36.833 41.8916 36.7959 42.2129 36.7959 42.6514C36.7959 43.0898 36.833 43.4111 36.8721 43.6475C36.9404 44.0361 37.2939 44.3897 37.6836 44.459C37.9199 44.498 38.2412 44.5352 38.6797 44.5352C39.1182 44.5352 39.4395 44.498 39.6758 44.459C40.0645 44.3906 40.418 44.0371 40.4873 43.6475C40.5264 43.4111 40.5635 43.0898 40.5635 42.6514C40.5635 42.2129 40.5264 41.8916 40.4873 41.6553C40.4189 41.2666 40.0654 40.9131 39.6758 40.8438ZM38.6787 39.6123C39.7031 39.6123 40.5342 38.7813 40.5342 37.7568V34.6611C40.5342 33.6367 39.7031 32.8057 38.6787 32.8057C37.6543 32.8057 36.8232 33.6367 36.8232 34.6611V37.7568C36.8232 38.7813 37.6543 39.6123 38.6787 39.6123Z"
          fill="#384157"
          stroke="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_0_3619">
          <rect width="50" height="50" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
