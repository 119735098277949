import { Handle, Position } from "reactflow";
import styles from "./BranchNode.module.scss";
import { NodeHeader } from "../NodeHeader/NodeHeader";
import { NodeBody } from "../NodeBody/NodeBody";

export function BranchNode({ id, data }) {
  return (
    <div className={styles.container}>
      <NodeHeader name={data.label} id={id} />
      <NodeBody>{data.prompt}</NodeBody>
      <Handle type="target" position={Position.Top} className={styles.handle} />
      <Handle
        type="source"
        position={Position.Bottom}
        className={styles.handle}
      />
    </div>
  );
}
