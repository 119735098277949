import { useEffect, useRef, useState } from 'react'
import { DateRange } from 'react-date-range'

import format from 'date-fns/format'
import { addDays } from 'date-fns'
import IconCalendar from '../../assets/icons/icon_calendar.svg'
import IconChevronDown from '../../assets/icons/icon_chevron_down.svg'

import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'
import styles from "./DatePicker.module.scss";
import './DatePicker.css'


export const DatePicker = ({range, setRange, open, setOpen}) => {

  const refOne = useRef(null)

  const [activeItem, setActiveItem] = useState(4)

  const date = new Date()

  const listOfSettings =[
    {id: 1, title: '24 Hours', value: -1},
    {id: 2, title: '7 Days', value: -7}, 
    {id: 3, title: '30 Days', value: -30},
    {id: 4, title: 'Custom', value: 0},
  ]

  useEffect(() => {
    document.addEventListener("keydown", hideOnEscape, true)
    document.addEventListener("click", hideOnClickOutside, true)
  }, [])

  useEffect(() => {
    if(activeItem === 4) return
    const item = listOfSettings.find(el => el.id === activeItem)
    if (!item) return 
    setRange([{...range[0], endDate:date, startDate: addDays(new Date(), item.value)}])
  }, [activeItem]);

  const hideOnEscape = (e) => {
    if( e.key === "Escape" ) {
      setOpen(false)
    }
  }

  const hideOnClickOutside = (e) => {
    if( refOne.current && !refOne.current.contains(e.target) ) {
      setOpen(false)
    }
  }

  const iconClasses = [styles['icon_chevron_down']]

  if (open) {
    iconClasses.push(styles.active)
  }

  return (
    <div className={styles.calendarWrap}>
      <div className={styles["input-box"]} onClick={ () => setOpen(open => !open) }>
        <svg  className={styles['icon-calendar']}>
          <use href={IconCalendar + '#icon-calendar'}/>
        </svg>
        <svg  className={iconClasses.join(' ')}>
          <use href={IconChevronDown + '#icon_chevron_down'}/>
        </svg>
        <input
          value={`${format(range[0].startDate, "MM/dd/yyyy")} - ${format(range[0].endDate, "MM/dd/yyyy")}`}
          readOnly
          className={styles.input}
          
        />
      </div>
      <div ref={refOne}>
        {open && 
          <div className={styles.calendarElement}>
            <div>
              <ul className={styles.listOfSettings}>
                {listOfSettings.map(item => {
                   const elClasses = [styles.listOfSettingsItem]

                   if (item.id === activeItem) {
                    elClasses.push(styles.active)
                   }
                return(
                <li className={elClasses.join(' ')} key={item.id}>
                  <button type='button' onClick={() => setActiveItem(item.id)}>{item.title}</button>
                </li>)})}
            </ul>
            <DateRange
            onChange={item => setRange([item.selection])}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
            ranges={range}
            months={1}
            direction="horizontal"
            rangeColors={['rgb(241, 245, 248)']}
            
          />
          </div></div>
        }
      </div>

    </div>
  )
}
