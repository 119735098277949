import { Typography } from "../../../../components";
import InviteByEmail from "../InviteByEmail/InviteByEmail";
import InviteViaLink from "../InviteViaLink/InviteViaLink";
import SendTokens from "../SendTokens/SendTokens";

import styles from "./ReferralActionsBlock.module.scss";

const ReferralActionsBlock = () => {
  return (
    <div className={styles.container}>
      <Typography variant="h2">
        Give <span className={styles.number}>100</span> message credits
      </Typography>
      <InviteByEmail />
      <InviteViaLink />
      <SendTokens />
    </div>
  );
};

export default ReferralActionsBlock;
