import { BaseEdge, EdgeLabelRenderer, getStraightPath } from "reactflow";
import classNames from "classnames";
import { MdOutlineEdit } from "react-icons/md";

import styles from "./EdgeNode.module.scss";
import { Typography } from "../../../../../../../components/Typography/Typography";
import { useFollowUpPageContext } from "../../../../../context/useFollowUpPageContext";
import { useMemo } from "react";
import { EDGE_CONDITION_TYPES } from "../../../const";

export function EdgeNode({ id, sourceX, sourceY, targetX, targetY }) {
  const { setIsEditEdgeModalOpen, setSelectedEdgeId, edges } =
    useFollowUpPageContext();
  const edge = useMemo(() => edges.find((edge) => edge.id === id), [edges]);
  const edgeData = useMemo(() => edge?.data, [edge]);
  const [edgePath, labelX, labelY] = getStraightPath({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  const edgeLabel = useMemo(() => {
    if (edgeData?.condition && edgeData?.value) {
      const isSilence = edgeData?.condition === EDGE_CONDITION_TYPES.silence;
      const edgeValue = isSilence
        ? `${edgeData?.value?.hours}h ${edgeData?.value?.minutes}m ${edgeData?.value?.seconds}s`
        : null;

      return (
        <>
          <div>{`${edgeData?.condition}${isSilence ? ":" : ""}`}</div>
          {edgeValue ? <div>{edgeValue}</div> : null}
        </>
      );
    } else {
      return "New Edge";
    }
  }, [edgeData]);

  return (
    <>
      <BaseEdge id={id} path={edgePath} />
      <EdgeLabelRenderer>
        <button
          style={{
            position: "absolute",
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            pointerEvents: "all",
          }}
          className={classNames("nodrag nopan", styles.edgeButton)}
          onClick={() => {
            setIsEditEdgeModalOpen(true);
            setSelectedEdgeId(id);
          }}
        >
          <Typography variant="caption" className={styles.edgeLabel}>
            {edgeLabel}
          </Typography>
          <div className={styles.iconWrapper}>
            <MdOutlineEdit size={16} />
          </div>
        </button>
      </EdgeLabelRenderer>
    </>
  );
}
