import styles from "./NotificationsTableHeader.module.scss";
import { Typography } from "../../../../components";

const NotificationsTableHeader = () => {
  return (
    <div className={styles.headerContainer}>
      <div />
      <div className={styles.headerWrapper}>
        <Typography variant="h4" className={styles.headerCell}>
          Notification
        </Typography>
        <Typography variant="h4" className={styles.headerCell}>
          Type
        </Typography>
        <Typography variant="h4" className={styles.headerCell}>
          Status
        </Typography>
        <Typography variant="h4" className={styles.headerCell}>
          Time
        </Typography>
        <div />
      </div>
    </div>
  );
};

export default NotificationsTableHeader;
