export const Viber = ({width, height}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_36_1687)">
                <path
                    d="M48.2395 28.9437C49.7312 16.3875 47.5228 8.46041 43.5395 4.87499L43.5416 4.87291C37.1124 -1.25001 15.3999 -2.15626 7.70826 5.14791C4.25409 8.72082 3.03742 13.9646 2.90409 20.4562C2.77075 26.95 2.61242 39.1146 13.9478 42.4146H13.9583L13.9478 47.4542C13.9478 47.4542 13.8708 49.4958 15.1749 49.9062C16.6666 50.3896 17.3416 49.4417 21.9812 43.9C29.7395 44.5729 35.6978 43.0312 36.3749 42.8062C37.9416 42.2812 46.8062 41.1083 48.2395 28.9437ZM22.7458 40.3542C22.7458 40.3542 17.8353 46.4771 16.3083 48.0667C15.8083 48.5833 15.2603 48.5354 15.2687 47.5104C15.2687 46.8375 15.3062 39.1437 15.3062 39.1437C5.69575 36.3896 6.26242 26.0312 6.36659 20.6125C6.47075 15.1917 7.46242 10.7521 10.3874 7.76249C17.1353 1.43541 36.1708 2.84999 41.0208 7.40832C46.9499 12.6646 44.8395 27.5146 44.852 28.0208C43.6333 38.175 36.4499 38.8187 35.1291 39.2583C34.5645 39.4458 29.3249 40.7937 22.7458 40.3542Z"
                    fill="#8E24AA"/>
                <path
                    d="M25.4624 8.95209C24.6603 8.95209 24.6603 10.2021 25.4624 10.2125C31.6853 10.2604 36.8103 14.5979 36.8666 22.5542C36.8666 23.3938 38.0957 23.3833 38.0853 22.5438C38.0187 13.9708 32.4207 9 25.4624 8.95209Z"
                    fill="#8E24AA"/>
                <path
                    d="M33.6476 21.2354C33.6289 22.0646 34.856 22.1041 34.8664 21.2646C34.9685 16.5375 32.0539 12.6437 26.5768 12.2333C25.7747 12.175 25.6914 13.4354 26.4914 13.4937C31.2414 13.8542 33.7435 17.0958 33.6476 21.2354Z"
                    fill="#8E24AA"/>
                <path
                    d="M32.3353 26.6125C31.3061 26.0166 30.2582 26.3875 29.8249 26.9729L28.9186 28.1458C28.4582 28.7416 27.5978 28.6625 27.5978 28.6625C21.3186 27.0021 19.6395 20.4312 19.6395 20.4312C19.6395 20.4312 19.5624 19.5416 20.1374 19.0646L21.2707 18.1271C21.8374 17.6771 22.1957 16.5937 21.6186 15.5291C20.077 12.7437 19.0415 11.7833 18.5145 11.0458C17.9603 10.3521 17.127 10.1958 16.2603 10.6646H16.2415C14.4395 11.7187 12.4665 13.6916 13.0978 15.7229C14.1749 17.8646 16.154 24.6916 22.4624 29.85C25.427 32.2896 30.1186 34.7896 32.1103 35.3666L32.129 35.3958C34.0915 36.05 35.9999 34 37.0186 32.1437V32.1291C37.4707 31.2312 37.3207 30.3812 36.6603 29.825C35.4895 28.6833 33.7228 27.4229 32.3353 26.6125Z"
                    fill="#8E24AA"/>
                <path
                    d="M27.4353 16.8833C29.4374 17 30.4082 18.0458 30.5124 20.1937C30.5499 21.0333 31.7686 20.975 31.7311 20.1354C31.5978 17.3312 30.1353 15.7687 27.502 15.6229C26.6999 15.575 26.6249 16.8354 27.4353 16.8833Z"
                    fill="#8E24AA"/>
            </g>
            <defs>
                <clipPath id="clip0_36_1687">
                    <rect width="50" height="50" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    );
};
