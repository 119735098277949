import React, { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import Switch from "react-switch";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { contentStyle } from "../../../constants/popups.js";
import "../../../assets/scss/modal.scss";
import styles from "./SetAssistantMetadataModal.module.css";

import { updateData } from "../../../service/supabase";
import { fetchInstructions } from "../../../store/slices/assistantSlice";

export const SetAssistantMetadataModal = ({
  isOpen,
  onClose,
  metadata,
  assistantId,
}) => {
  const dispatch = useDispatch();
  const [switchStopWord, setSwitchStopWord] = useState("");
  const [silentSwitchToOperator, setSilentSwitchToOperator] = useState(false);

  useEffect(() => {
    setSwitchStopWord(metadata.switch_stop_word || "");
    setSilentSwitchToOperator(metadata.silent_switch_to_operator || false);
  }, [metadata]);

  const handleSave = async () => {
    const newMetadata = {};

    if (switchStopWord) {
      newMetadata.switch_stop_word = switchStopWord;
    }

    newMetadata.silent_switch_to_operator = silentSwitchToOperator;

    try {
      await updateData(
        "assistants",
        { metadata: newMetadata },
        { id: assistantId }
      );
      dispatch(fetchInstructions());
      toast.success(
        "The settings for switching to the operator have been successfully saved!"
      );
    } catch (error) {
      toast.error("Failed to save settings for switching to the operator.");
    }

    onClose();
  };

  return (
    <Popup
      open={isOpen}
      closeOnDocumentClick
      onClose={onClose}
      {...{
        contentStyle,
      }}
    >
      <div className="modal">
        <h2 className="modalTitle">Switch to Operator</h2>
        <label htmlFor="switchStopWords" className="modalLabel">
          Keyword to switch to operator
        </label>
        <input
          type="text"
          className="modalInput"
          id="switchStopWords"
          placeholder="Enter switch stop word"
          value={switchStopWord}
          onChange={(e) => setSwitchStopWord(e.target.value)}
        />
        <div className={styles.modalToggle}>
          <label htmlFor="silentSwitchToOperator" className="modalLabel">
            Silent switching to the operator
          </label>

          <Switch
            id="silentSwitchToOperator"
            onChange={(checked) => setSilentSwitchToOperator(checked)}
            checked={silentSwitchToOperator}
            uncheckedIcon={false}
            checkedIcon={false}
            height={27}
            offColor="#FF0000"
            onColor="#19CE36"
          />
        </div>
        <div className="btns">
          <button type="button" className="modalBtn" onClick={onClose}>
            Close
          </button>
          <button
            type="button"
            onClick={handleSave}
            className="modalBtn saveBtn"
          >
            Save
          </button>
        </div>
      </div>
    </Popup>
  );
};
