import React from "react";

import styles from "./Button.module.scss";
import classNames from "classnames";

export const Button = ({
  type = "button",
  title,
  tooltip,
  className,
  icon,
  color,
  variant,
  isSelected,
  ...otherProps
}) => {
  return (
    <button
      type={type}
      title={tooltip}
      className={classNames(
        {
          [styles[variant]]: variant,
          [styles[color]]: color,
          [styles.selected]: isSelected,
          [styles.disabled]: otherProps.disabled,
        },
        className || "",
      )}
      {...otherProps}
    >
      {icon && icon} {title}
    </button>
  );
};
