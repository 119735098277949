import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLogin: false,
  isLoading: true,
  user: {
    id: null,
    account_id: null,
    role: null,
  },
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login: (state, action) => {
      state.isLogin = action.payload.isLogin;
      state.user = action.payload.user;
    },
    checkAuth: (state, action) => {
      state.isLogin = action.payload.isLogin;
      state.user = action.payload.user;
    },
    logout: (state, action) => {
      state.isLogin = action.payload;
    },
    loading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { login, logout, loading } = authSlice.actions;
export const selectUser = (state) => state.auth.user;

export default authSlice.reducer;
