import { Button } from "../../../../components/Button";
import { Loader } from "../../../../components/Loader";
import styles from "./Card.module.scss";

export const Card = ({
  children,
  icon,
  title,
  description,
  selectedId,
  btnText,
  isLoading,
  clickHandler,
  id,
  subscriptionPlan,
}) => {
  return (
    <div className={styles.card}>
      {isLoading ? (
        <div className={styles.refreshLoader}>
          <Loader width={40} height={40} />
        </div>
      ) : (
        <>
          <div className={styles.iconBox}>{icon ? icon : children}</div>
          <div className={styles.title}>{title}</div>
          {description && (
            <div className={styles.description}>{description}</div>
          )}
          <Button
            style={{ width: "100%", boxShadow: "none" }}
            className={styles.titleButton}
            title={btnText}
            onClick={() => clickHandler(id)}
            disabled={id === selectedId || subscriptionPlan === null}
          />
        </>
      )}
    </div>
  );
};
