import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toast } from "react-toastify";
import classNames from "classnames";
import { CHANNELS } from "../../../../../constants/channels";
import search from "../../../../../assets/img/search.png";
import { UserChat } from "../../../../../components/UserChat";
import { getChannelIcon } from "../../../../../utils";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import supabase from "../../../../../supabase";
import { useDispatch, useSelector } from "react-redux";
import { selectUser } from "../../../../../store/slices/authSlice";
import {
  fetchInstructions,
  setSelectedAssistants,
  _selectedAssistantId,
  _updateListOfAssistants,
} from "../../../../../store/slices/assistantSlice";
import { FaEllipsisV } from "react-icons/fa";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { useChatContext } from "../../context/ChatContextProvider";
import { Input } from "../../../../../components/Input";
import { BsFunnel } from "react-icons/bs";
import { Typography } from "../../../../../components";
import styles from "./ChatList.styles.module.scss";
import { FaChevronRight } from "react-icons/fa";

const customStyles = {
  option: (provided) => ({
    ...provided,
    fontSize: "1.5rem",
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: "1.5rem",
  }),
  placeholder: (provided) => ({
    ...provided,
    fontSize: "1.5rem",
    color: "#384157",
  }),
  control: (provided) => ({
    ...provided,
    fontSize: "1.5rem",
    overflow: "visible",
  }),
};

const chatsPerPage = 15; // Number of chats per page

const ChatList = () => {
  const animatedComponents = makeAnimated();
  const dispatch = useDispatch();

  const user = useSelector(selectUser);
  const selectedAssistants = useSelector(
    (state) => state.assistant.selectedAssistants
  );
  const selectedAssistantId = useSelector(_selectedAssistantId);
  const updateListOfAssistants = useSelector(_updateListOfAssistants);

  const { clientId, pageId } = useParams();
  const normalizedPageId = pageId && pageId !== "null" ? pageId : null;
  const { activeTab, setActiveTab } = useChatContext();

  const [isShowChatsList, setIsShowChatsList] = useState(false);
  const [buttonFilterChatsList, setButtonFilterChatsList] = useState("All");
  const [searchValue, setSearchValue] = useState("");
  const [unreadCount, setUnreadCount] = useState(0);

  const [chats, setChats] = useState([]);
  const [totalChatCount, setTotalChatCount] = useState(0);
  const [page, setPage] = useState(1);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [showFunnelStatusSelect, setShowFunnelStatusSelect] = useState(false);
  const [funnelStatuses, setFunnelStatuses] = useState([]);
  const [selectedFunnelStatuses, setSelectedFunnelStatuses] = useState([]);
  const [clientFunnelStatusMap, setClientFunnelStatusMap] = useState({});

  const dropdownRef = useRef(null);
  const firstRender = useRef(true);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const fetchChatsPage = useCallback(
    async (pageNumber) => {
      let query = supabase
        .from("chats")
        .select("*", { count: "exact" })
        .eq("account_id", user.account_id)
        .order("last_active", { ascending: false })
        .range((pageNumber - 1) * chatsPerPage, pageNumber * chatsPerPage - 1);

      if (activeTab === 1) {
        query = query.eq("needs_operator", true);
      }
      if (buttonFilterChatsList !== "All") {
        query = query.eq("communication_channel", buttonFilterChatsList);
      }

      const { data, error, count } = await query;

      if (error) {
        toast.error("Error fetching chats: " + error.message);
        return { data: [], count: 0 };
      }
      return { data: data || [], count };
    },
    [user.account_id, activeTab, buttonFilterChatsList]
  );

  const loadInitialChats = useCallback(async () => {
    const { data: initialChats, count: totalCount } = await fetchChatsPage(1);

    if (!initialChats) {
      setChats([]);
      setTotalChatCount(0);
      return;
    }

    // Adding funnel_status to the chat
    const finalChats = initialChats.map((chat) => ({
      ...chat,
      funnel_status: clientFunnelStatusMap[chat.client_id] || null,
    }));

    setChats(finalChats);
    setPage(1);
    setUnreadCount(
      finalChats.reduce((acc, { unread_count }) => acc + unread_count, 0)
    );
    setTotalChatCount(totalCount);
  }, [fetchChatsPage, clientFunnelStatusMap]);

  useEffect(() => {
    loadInitialChats();
  }, [loadInitialChats]);

  const handleClickChat = async (chatPartner) => {
    try {
      if (chatPartner.unread_count) {
        const { error } = await supabase
          .from("chats")
          .update({ unread_count: 0 })
          .match({ id: chatPartner.id });

        setChats((prevChats) =>
          prevChats.map((chat) =>
            chat.id === chatPartner.id ? { ...chat, unread_count: 0 } : chat
          )
        );

        setUnreadCount((prevCount) => prevCount - chatPartner.unread_count);
      }

      setIsShowChatsList(false);

      if (chatPartner.page_id) {
        navigate(`/chats/${chatPartner.client_id}/${chatPartner.page_id}`);
      } else {
        navigate(`/chats/${chatPartner.client_id}`);
      }
    } catch (error) {
      toast.error("Unexpected error: " + error.message);
    }
  };

  const handleLoadMore = useCallback(async () => {
    const nextPage = page + 1;
    const { data: moreChats, count: totalCount } = await fetchChatsPage(
      nextPage
    );

    if (moreChats.length > 0) {
      const updatedMoreChats = moreChats.map((chat) => ({
        ...chat,
        funnel_status: clientFunnelStatusMap[chat.client_id] || null,
      }));

      setChats((prev) => [...prev, ...updatedMoreChats]);
      setPage(nextPage);
      setUnreadCount(
        (prevCount) =>
          prevCount +
          updatedMoreChats.reduce(
            (acc, { unread_count }) => acc + unread_count,
            0
          )
      );
      setTotalChatCount(totalCount);
    } else {
      toast.info("No more chats");
    }
  }, [fetchChatsPage, page, clientFunnelStatusMap]);

  const toggleDropdown = () => {
    setIsDropdownVisible(!isDropdownVisible);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("touchstart", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("touchstart", handleClickOutside);
    };
  }, []);

  const getColor = (id) => {
    return updateListOfAssistants.find(({ value }) => value === id)?.bgColor;
  };

  // Fetch clients and funnel statuses
  useEffect(() => {
    const fetchClientsAndFunnelStatuses = async () => {
      try {
        const { data: clientsData, error } = await supabase
          .from("clients")
          .select("client_id, funnel_status")
          .eq("account_id", user.account_id);

        if (error) throw error;

        // Create a map of client_id to funnel_status
        const funnelStatusMap = {};
        clientsData.forEach((client) => {
          funnelStatusMap[client.client_id] = client.funnel_status;
        });
        setClientFunnelStatusMap(funnelStatusMap);

        // Get unique funnel statuses
        const uniqueStatuses = [
          ...new Set(clientsData.map((c) => c.funnel_status).filter(Boolean)),
        ];

        setFunnelStatuses(
          uniqueStatuses.map((status) => ({
            value: status,
            label: status,
          }))
        );
      } catch (error) {
        toast.error("Error fetching clients: " + error.message);
      }
    };

    fetchClientsAndFunnelStatuses();
  }, [user.account_id]);

  const unread_needs_operator = useMemo(
    () => chats?.filter((el) => el.needs_operator && el.unread_count).length,
    [chats]
  );

  useEffect(() => {
    if (chats && chats.length > 0) {
      setUnreadCount(
        chats.reduce((acc, { unread_count }) => acc + unread_count, 0)
      );

      if (!clientId && (firstRender.current || pathname === "/chats")) {
        if (chats[0]?.client_id) {
          if (chats[0]?.page_id) {
            navigate(`/chats/${chats[0].client_id}/${chats[0].page_id}`);
          } else {
            navigate(`/chats/${chats[0].client_id}`);
          }
        }
        firstRender.current = false;
      }

      if (clientId) {
        const chatExists = chats.some((chat) => {
          if (chat.client_id !== clientId) return false;

          return chat.page_id;
        });

        /* if (!chatExists) {
          console.log("Chat does not exist, redirecting to /chats");
          navigate("/chats", { replace: true });
        } */
      }
    }
  }, [
    chats,
    clientId,
    normalizedPageId,
    pathname,
    selectedAssistantId,
    navigate,
  ]);

  useEffect(() => {
    dispatch(fetchInstructions(user.account_id));
  }, [dispatch, user.account_id]);

  const handleMarkAllRead = async () => {
    try {
        const { error } = await supabase
        .from("chats")
        .update({ unread_count: 0 })
        .match({ account_id: user.account_id });

      if (error) {
        toast.error("Error marking all as read: " + error.message);
        return;
      }

      setChats((prevChats) =>
        prevChats.map((chat) => ({ ...chat, unread_count: 0 }))
      );

      setUnreadCount(0);
    } catch (error) {
      console.log("Unexpected error: " + error.message);
    }
  };

  const applyFilters = useCallback(
    (chatList) => {
      let filtered = [...chatList];

      // Search filter
      if (searchValue) {
        filtered = filtered.filter((chat) => {
          const clientName = chat.client_name || "";
          const page_id = chat.page_id || "";
          return (
            clientName.toLowerCase().includes(searchValue.toLowerCase()) ||
            page_id.toLowerCase().includes(searchValue.toLowerCase())
          );
        });
      }

      // Funnel Status filter
      if (selectedFunnelStatuses?.length > 0) {
        const selectedValues = selectedFunnelStatuses.map((s) => s.value);
        filtered = filtered.filter(
          (chat) =>
            chat.funnel_status && selectedValues.includes(chat.funnel_status)
        );
      }

      return filtered;
    },
    [searchValue, selectedFunnelStatuses]
  );

  const filteredChats = useMemo(() => {
    return applyFilters(chats);
  }, [chats, applyFilters]);

  const showMoreButton =
    !searchValue &&
    selectedFunnelStatuses.length === 0 &&
    filteredChats.length < totalChatCount;

  return (
    chats && (
      <>
        <div
          className={classNames(
            styles.accountChats,
            isShowChatsList && styles.rotated
          )}
          ref={dropdownRef}
        >
          <div className={styles.chatHeader}>
            <div className={`${styles.search} my-row`}>
              <Input
                isMulti={true}
                typeInput={"select"}
                placeholder={"Select an assistant"}
                options={updateListOfAssistants}
                value={selectedAssistants}
                onChange={(selectedOption) =>
                  dispatch(setSelectedAssistants(selectedOption))
                }
              />
            </div>
            <div className={styles.chatSearchList}>
              <button
                className={`${styles.chatSearchItem} ${
                  buttonFilterChatsList === "All" ? styles.active : ""
                }`}
                onClick={() => {
                  setButtonFilterChatsList("All");
                }}
              >
                All
              </button>
              {CHANNELS.map(({ icon, value }, index) => (
                <button
                  key={index}
                  className={`${styles.chatSearchItem} ${
                    buttonFilterChatsList === value ? styles.active : ""
                  }`}
                  onClick={() => {
                    setButtonFilterChatsList(value);
                  }}
                >
                  {icon}
                </button>
              ))}
            </div>
            <>
              <div className={styles.search}>
                <input
                  className={styles.searchInput}
                  placeholder={"Search"}
                  value={searchValue}
                  onChange={({ target: { value } }) => setSearchValue(value)}
                />
                <div className={styles.searchIcon}>
                  <img src={search} alt={""} />
                </div>
                <div className={styles.customDropdown}>
                  <FaEllipsisV
                    className={styles.menuIcon}
                    onClick={() => setIsDropdownVisible(!isDropdownVisible)}
                  />

                  {isDropdownVisible && (
                    <>
                      <div className={styles.dropdownMenu}>
                        <div
                          className={styles.dropdownItem}
                          onClick={() => {
                            setShowFunnelStatusSelect(!showFunnelStatusSelect);
                            setIsDropdownVisible(false);
                          }}
                        >
                          <BsFunnel size={"1.8rem"} />
                          Search By Funnel Status
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              {showFunnelStatusSelect && (
                <div className={styles.funnelStatusSelect}>
                  <Typography variant="h3">Funnel status</Typography>
                  <Select
                    isMulti
                    options={funnelStatuses}
                    value={selectedFunnelStatuses}
                    onChange={(selectedOption) =>
                      setSelectedFunnelStatuses(selectedOption)
                    }
                    components={animatedComponents}
                    placeholder="Select Funnel Statuses"
                    className={styles.funnelSelect}
                    styles={customStyles}
                  />
                </div>
              )}
            </>
          </div>
          <div className={styles.chatList}>
            <div className={styles.tabs}>
              <div
                className={classNames(
                  styles.tab,
                  activeTab === 0 && styles.active
                )}
              >
                <button
                  onClick={() => {
                    setActiveTab(0);
                  }}
                >
                  All chats
                </button>
                {!!unreadCount && (
                  <button
                    className={styles.tabCount}
                    onClick={handleMarkAllRead}
                  >
                    <span className={styles.unreadCount}>{unreadCount}</span>
                    <span className={styles.tooltip}>Read All</span>
                  </button>
                )}
              </div>
              <div className={styles.operator}>
                <button
                  className={classNames(
                    styles.tab,
                    activeTab === 1 && styles.active
                  )}
                  onClick={() => {
                    setActiveTab(1);
                  }}
                >
                  {!!unread_needs_operator && (
                    <div className={styles.tabCount}>
                      {unread_needs_operator}
                    </div>
                  )}
                  Need operator
                </button>
              </div>
            </div>
            <div className={styles.chatListItems}>
              {filteredChats.length > 0 ? (
                filteredChats.map((chatItem) => (
                  <UserChat
                    onClick={() => handleClickChat(chatItem)}
                    key={`${chatItem.client_id}-${chatItem.page_id}`}
                    pageID={chatItem.page_description}
                    assistant_id={chatItem.assistant_id}
                    bgColor={getColor(chatItem.assistant_id)}
                    connection={getChannelIcon(chatItem?.communication_channel)}
                    full_name={
                      chatItem.client_name ||
                      chatItem.client_username ||
                      chatItem.client_id
                    }
                    avatar_url={chatItem?.client_avatar_url}
                    last_active={chatItem?.last_active}
                    is_selected={chatItem.client_id === clientId}
                    unread={chatItem.unread_count}
                  />
                ))
              ) : (
                <div className={styles.preloadMessageList}>
                  <p>No Chats Available at the Moment</p>
                </div>
              )}
              {showMoreButton && (
                <button className={styles.more} onClick={handleLoadMore}>
                  more...
                </button>
              )}
            </div>
          </div>
        </div>

        <div className={styles.toggle}>
          <button
            className={classNames(
              styles.btn,
              isShowChatsList && styles.rotated
            )}
            onClick={() => setIsShowChatsList(!isShowChatsList)}
          >
            <FaChevronRight size={30} />
          </button>
        </div>
        {!clientId && filteredChats?.length > 0 && (
          <div className={styles.preloadMessageList}>
            <p>Please choose any chat</p>
          </div>
        )}
      </>
    )
  );
};

export default ChatList;
