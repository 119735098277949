import { useState, useEffect } from "react";
import Popup from "reactjs-popup";
import { getData, updateData } from "../../../service/supabase.js";
import { toast } from "react-toastify";

import { contentStyle } from "../../../constants/popups.js";

import "../../../assets/scss/modal.scss";

export const FormForAddingNot = ({
  setFormForAddingNot,
  userId,
  assistantId,
  isFormForAddingNot,
} = {}) => {
  const [telegram, setTelegram] = useState("");
  const [viber, setViber] = useState("");

  const [startTelegramValue, setStartTelegramValue] = useState("");
  const [disabled, setDisabled] = useState(true);

  useEffect(() => {
    if (!assistantId || !userId) return;
    getData("assistants", "*", "account_id", userId)
      .then(({ data }) => {
        if (!data || !data?.length) return;

        const { operator_tg, operator_viber } = data?.find(
          ({ id }) => id === assistantId
        );

        if (!operator_tg) return;

        let tg = operator_tg || "";
        setTelegram(tg);
        setStartTelegramValue(tg);

        if (operator_viber) setViber(operator_viber);
      })
      .catch((r) => {
        toast.error(r);
      });
  }, [assistantId, userId]);

  useEffect(() => {
    setDisabled(telegram === startTelegramValue);
  }, [telegram, startTelegramValue]);

  const handleTelegramChange = (event) => {
    // we need to replace all spaces with _
    // and to remove leading @ if there is such
    let telegramVal = event.target.value.replace(/\s/g, "_");
    if (telegramVal[0] === "@") telegramVal = telegram.slice(1);
    setTelegram(telegramVal);
  };

  const handleViberChange = (event) => {
    setViber(event.target.value);
  };

  const onClick = async () => {
    try {
      await updateData(
        "assistants",
        { operator_tg: telegram, operator_viber: viber },
        { account_id: userId, id: assistantId }
      );
      setStartTelegramValue(telegram);
    } catch (error) {
      console.log(error);
    }
  };

  const closeModal = () => {
    setFormForAddingNot(false);
  };

  return (
    <Popup
      open={isFormForAddingNot}
      closeOnDocumentClick
      onClose={closeModal}
      {...{
        contentStyle,
      }}
    >
      <div className="modal">
        <h2 className="modalTitle">Add your account for notification</h2>
        <label htmlFor="telegram" className="modalLabel">
          Telegram
        </label>
        <p className="modalDesc">
          Add @mcbassistant_bot to your group, then press "Save" and then send a
          message /my_id @mcbassistant_bot
        </p>
        <input
          type="text"
          className="modalInput"
          id="telegram"
          placeholder="Name"
          value={telegram}
          onChange={handleTelegramChange}
        />
        <label htmlFor="viber" className="modalLabel">
          Viber (coming soon)
        </label>
        <input
          type="text"
          className="modalInput"
          disabled
          id="viber"
          placeholder="Name"
          value={viber}
          onChange={handleViberChange}
        />
        <div className="btns">
          <button type="button" className="modalBtn" onClick={closeModal}>
            Close
          </button>
          <button
            type="button"
            onClick={onClick}
            className="modalBtn saveBtn"
            disabled={disabled}
          >
            Save
          </button>
        </div>
      </div>
    </Popup>
  );
};
