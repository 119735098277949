import { Modal } from "../../../../../../components/Modal";
import { Button } from "../../../../../../components";
import styles from "../../../../../../components/Integration/Integration.module.scss";

const AuthModal = ({ isOpen, setIsModalOpen, handleUserConsent }) => {
  return (
    <Modal
      isShowModal={isOpen}
      title={"User Consent Required"}
      /*description={'Do you allow third-party AI models to read the data you provided in the required for the integration Google Drive documents?'}*/
      descriptionSegments={[
        "In order to integrate with our services, we require access to certain documents in your Google Drive. We use advanced third-party AI models to process this data exclusively for reading purposes and to enhance conversations with your clients.",
        "Before proceeding, we need your explicit consent for the following:",
        "1. Allow us to access specific Google Drive documents that you designate as necessary for the integration.",
        "2. Permit the use of this data by third-party AI models exclusively for reading and facilitating conversations with your clients, and for no other purpose.",
        "We assure you that:",
        "- Only the data in the documents you specify will be accessed.",
        "- Your data will be used solely for the purposes of enhancing client interactions and not for any other reason.",
        "- You can withdraw your consent and restrict access at any time.",
        "Do you agree to these terms and grant us permission to access and use your data as described above?",
      ]}
      actions={
        <div style={{ display: "flex", flexDirectin: "row", width: "100%" }}>
          <Button
            className={styles.button}
            style={{ margin: "10px 10px 10px 0" }}
            onClick={() => handleUserConsent(true)}
            title={"Yes"}
          />
          <Button
            className={styles.button}
            style={{ margin: "10px 10px 10px 0" }}
            onClick={() => handleUserConsent(false)}
            title={"No"}
          />
        </div>
      }
      setIsShowModal={setIsModalOpen}
    />
  );
};

export default AuthModal;
