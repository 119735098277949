export const WhatsApp = ({width, height}) => {
    return (
        <svg width={width} height={height} viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_36_1675)">
                <path d="M42.75 7.18542C38 2.59375 31.75 0 25.0938 0C5.98958 0 -6.00625 20.6979 3.53333 37.1625L0 50L13.1979 46.5583C18.9479 49.6646 23.6583 49.3896 25.1063 49.5729C47.2583 49.5729 58.2854 22.7729 42.7188 7.26667L42.75 7.18542Z" fill="#ECEFF1"/>
                <path d="M25.1396 45.3146L25.1271 45.3125H25.0938C18.4646 45.3125 14.2292 42.1729 13.8125 41.9917L6 44.0229L8.09375 36.4292L7.59583 35.6479C5.53333 32.3646 4.4375 28.5833 4.4375 24.6896C4.4375 6.37083 26.8229 -2.78959 39.7771 10.1583C52.7 22.9708 43.6271 45.3146 25.1396 45.3146Z" fill="#4CAF50"/>
                <path d="M36.4727 29.8062L36.454 29.9625C35.8269 29.65 32.7727 28.1562 32.204 27.95C30.9269 27.4771 31.2873 27.875 28.8352 30.6833C28.4706 31.0896 28.1081 31.1208 27.4894 30.8396C26.8644 30.5271 24.8581 29.8708 22.4831 27.7458C20.6331 26.0896 19.3915 24.0583 19.0248 23.4333C18.4144 22.3792 19.6915 22.2292 20.854 20.0292C21.0623 19.5917 20.956 19.2479 20.8019 18.9375C20.6456 18.625 19.4019 15.5625 18.881 14.3417C18.381 13.125 17.8665 13.2792 17.481 13.2792C16.281 13.175 15.404 13.1917 14.631 13.9958C11.2685 17.6917 12.1165 21.5042 14.9935 25.5583C20.6477 32.9583 23.6602 34.3208 29.1686 36.2125C30.656 36.6854 32.0123 36.6187 33.0852 36.4646C34.281 36.275 36.7665 34.9625 37.2852 33.4937C37.8165 32.025 37.8165 30.8062 37.6602 30.525C37.506 30.2437 37.0977 30.0875 36.4727 29.8062Z" fill="#FAFAFA"/>
            </g>
            <defs>
                <clipPath id="clip0_36_1675">
                    <rect width="50" height="50" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    );
};
