import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';

export const assistantApi = createApi({
    reducerPath: 'assistantApi',
    baseQuery: fetchBaseQuery({
        baseUrl: process.env.REACT_APP_API_URL,
        credentials: 'include',
    }),
    endpoints: (builder) => ({
            assistantState: builder.mutation({
                query: (body) => ({
                    url: '/assistant-state',
                    method: 'POST',
                    credentials: 'include',
                    body,
                }),
            }),
        }
    ),
});

export const {
    useAssistantStateMutation,
} = assistantApi;
