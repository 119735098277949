import styles from "./ClientCardInfo.module.scss";
import { FaRegClock } from "react-icons/fa";
import { BiPhoneCall } from "react-icons/bi";
import { TbWorldSearch } from "react-icons/tb";
import { getGMTOffset } from "../../../../helpers";
import { formatDate } from "../../../../../../../utils";

const ClientCardInfo = ({ clientCardData: { last_active, phone_number } }) => {
  return (
    <div className={styles.container}>
      <div className={styles.list}>
        {/* <div className={styles.item}>
					<PiCheckSquareBold size={24} className={styles.icon} />
					ClientCardInfo
				</div>
				<div className={styles.item}>
					<FaRobot size={24} className={styles.icon} />
					ClientCardInfo
				</div>
				<div className={styles.item}>
					<RiPencilLine size={24} className={styles.icon} />
					ClientCardInfo
				</div>
				<div className={styles.item}>
					<TbGenderMale size={24} className={styles.icon} />
					ClientCardInfo
				</div>
				<div className={styles.item}>
					<TbGenderFemale size={24} className={styles.icon} />
					ClientCardInfo
				</div>
				<div className={styles.item}>
					<IoLanguage size={24} className={styles.icon} />
					ClientCardInfo
				</div> */}
        {phone_number && (
          <div className={styles.item}>
            <BiPhoneCall size={24} className={styles.icon} />
            {phone_number}
          </div>
        )}
        <div className={styles.item}>
          <TbWorldSearch size={24} className={styles.icon} />
          {getGMTOffset(last_active)}
        </div>
        <div className={styles.item}>
          <FaRegClock size={24} className={styles.icon} />
          {formatDate(last_active)}
        </div>
      </div>
    </div>
  );
};

export default ClientCardInfo;
