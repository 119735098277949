export const SalesScript = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.8786 33.6927L26.7838 12.9766C27.0396 12.2091 26.6249 11.3795 25.8574 11.1236C25.09 10.8679 24.2603 11.2826 24.0044 12.0501L17.0992 32.7662C16.8434 33.5337 17.2582 34.3633 18.0256 34.6191C18.1792 34.6703 18.3355 34.6946 18.489 34.6946C19.1023 34.6947 19.6739 34.3065 19.8786 33.6927ZM30.2279 27.266C32.651 27.266 34.6224 25.2946 34.6224 22.8715C34.6224 20.4483 32.651 18.477 30.2279 18.477C27.8047 18.477 25.8334 20.4483 25.8334 22.8715C25.8334 25.2946 27.8047 27.266 30.2279 27.266ZM30.2279 21.4066C31.0356 21.4066 31.6927 22.0638 31.6927 22.8715C31.6927 23.6792 31.0356 24.3363 30.2279 24.3363C29.4202 24.3363 28.763 23.6792 28.763 22.8715C28.763 22.0638 29.4202 21.4066 30.2279 21.4066ZM13.655 18.477C11.2319 18.477 9.2605 20.4483 9.2605 22.8715C9.2605 25.2946 11.2319 27.266 13.655 27.266C16.0782 27.266 18.0496 25.2946 18.0496 22.8715C18.0496 20.4483 16.0783 18.477 13.655 18.477ZM13.655 24.3363C12.8473 24.3363 12.1902 23.6792 12.1902 22.8715C12.1902 22.0638 12.8473 21.4066 13.655 21.4066C14.4627 21.4066 15.1199 22.0638 15.1199 22.8715C15.1199 23.6792 14.4628 24.3363 13.655 24.3363Z"
        fill="#384157"
      />
      <path
        d="M49.7073 18.1643L44.5991 11.3534L44.7985 9.95862C44.8306 9.73349 44.8099 9.50397 44.738 9.28823C44.6661 9.07248 44.5449 8.87645 44.3841 8.71565L41.2767 5.60823L44.384 2.50081C44.9561 1.92883 44.9561 1.00129 44.384 0.429224C43.812 -0.142748 42.8845 -0.142748 42.3124 0.429224L39.2051 3.53665L36.0977 0.429224C35.9368 0.268515 35.7408 0.147419 35.525 0.0755064C35.3093 0.00359367 35.0798 -0.0171657 34.8547 0.0148688L20.3534 2.08645C20.0397 2.13129 19.7489 2.27668 19.5248 2.50081L1.57092 20.4547C-0.523315 22.5489 -0.523315 25.9565 1.57092 28.0506L16.7626 43.2424C17.0666 43.5463 17.4061 43.8124 17.7737 44.0352V44.6291C17.7737 47.5907 20.1831 50.0002 23.1448 50.0002H44.6291C47.5907 50.0002 50.0002 47.5907 50.0002 44.6291V19.0432C50.0002 18.7263 49.8974 18.4178 49.7073 18.1643ZM3.64251 25.979C2.69055 25.0271 2.69055 23.4782 3.64251 22.5263L21.2511 4.9177L34.5439 3.01877L37.1334 5.60833L35.0618 7.67991L34.026 6.64407C33.454 6.07209 32.5265 6.07209 31.9544 6.64407C31.3823 7.21604 31.3823 8.14358 31.9544 8.71565L36.0977 12.8589C36.3837 13.1449 36.7586 13.288 37.1334 13.288C37.5082 13.288 37.8832 13.1449 38.1692 12.8589C38.7412 12.2869 38.7412 11.3594 38.1692 10.7873L37.1333 9.75149L39.2049 7.67991L41.7945 10.2695L39.8954 23.5622L22.2869 41.1708C21.8258 41.632 21.2127 41.8859 20.5606 41.8859C19.9084 41.8859 19.2953 41.6319 18.8342 41.1708L3.64251 25.979ZM47.0705 44.6291C47.0705 45.9753 45.9753 47.0705 44.6291 47.0705H23.1448C21.8592 47.0705 20.8033 46.0717 20.7107 44.8094C22.0356 44.7727 23.3495 44.2514 24.3584 43.2424L42.3123 25.2885C42.5365 25.0644 42.6818 24.7736 42.7267 24.4599L44.0131 15.455L47.0705 19.5315V44.6291Z"
        fill="#384157"
      />
    </svg>
  );
};
