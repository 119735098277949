import {
  STATISTICS_CARD_DESCRIPTIONS,
  STATISTICS_CARD_TITLE,
} from "../../const";
import { Typography } from "../../../../components";
import { formatNumber } from "../../../../utils";

import styles from "./StatisticsCard.module.scss";

const maxNum = 1000000;

const StatisticsCard = ({ value, title }) => {
  return (
    <div className={styles.container}>
      <Typography variant="h4" className={styles.value}>
        {formatNumber(value, maxNum)}
      </Typography>
      <Typography variant="h5" className={styles.title}>
        {STATISTICS_CARD_TITLE[title]}
      </Typography>
      <Typography variant="body1" className={styles.description}>
        {STATISTICS_CARD_DESCRIPTIONS[title]}
      </Typography>
    </div>
  );
};

export default StatisticsCard;
