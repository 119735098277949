import { useCallback } from "react";
import ReactFlow, { addEdge, Controls, MiniMap } from "reactflow";
import { useNavigate } from "react-router-dom";

import { Button } from "../../../../components/Button";
import { Typography } from "../../../../components/Typography/Typography";
import { AddNewNode } from "./components/AddNewNode/AddNewNode";
import { BranchNode, EdgeNode, StartNode } from "./components/CustomNodes";
import { EditNodeModal } from "./components/EditNodeModal/EditNodeModal";
import {
  FollowUpPageProvider,
  useFollowUpPageContext,
} from "../../context/useFollowUpPageContext";
import { EditEdgeModal } from "./components/EditEdgeModal/EditEdgeModal";
import { NODE_TYPES } from "./const";
import { EditStartNodeModal } from "./components/EditStartNodeModal/EditStartNodeModal";

import styles from "./EditFollowUp.module.scss";
import "reactflow/dist/style.css";
import { getNextNodeId } from "./utils";

const nodeTypes = { start: StartNode, branch: BranchNode };
const edgeTypes = {
  "custom-edge": EdgeNode,
};
const proOptions = { hideAttribution: true };

function EditFollowUpComponent() {
  const {
    nodes,
    setNodes,
    onNodesChange,
    edges,
    setEdges,
    onEdgesChange,
    saveFollowUpSteps,
    isEditStartNodeModalOpen,
    isEditEdgeModalOpen,
    isEditNodeModalOpen,
  } = useFollowUpPageContext();
  const navigate = useNavigate();

  const onConnect = useCallback(
    (params) =>
      setEdges((eds) => addEdge({ ...params, type: "custom-edge" }, eds)),
    [setEdges]
  );

  const addNode = useCallback(() => {
    const maxY = nodes.reduce((acc, node) => Math.max(acc, node.position.y), 0);
    const newNodeId = getNextNodeId(nodes);

    const newNode = {
      id: newNodeId,
      position: {
        x: Math.random() * 400,
        y: maxY + 150,
      },
      data: {
        label: "New Node",
        prompt: "Placeholder instructions for agent to say",
      },
      type: nodes.length === 0 ? NODE_TYPES.start : NODE_TYPES.branch,
      conditions: [],
    };

    setNodes((nds) => [...nds, newNode]);
  }, [nodes, setNodes]);

  const handleSave = () => {
    try {
      saveFollowUpSteps();
      navigate("/follow-up");
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancel = () => {
    navigate("/follow-up");
  };

  return (
    <div className={styles.container}>
      <div className={styles.editFollowUpControls}>
        <Typography>Use the buttons below to add content</Typography>
        <div className={styles.buttonsContainer}>
          <Button
            title="Cancel"
            variant="contained"
            color="edit"
            onClick={handleCancel}
          />
          <Button title="Save" variant="contained" onClick={handleSave} />
        </div>
      </div>
      <div className={styles.canvasContainer}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          proOptions={proOptions}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          fitView
          className={styles.reactFlow}
        >
          <MiniMap />
          <Controls />
        </ReactFlow>
        <AddNewNode onAddNode={addNode} />
      </div>
      {isEditNodeModalOpen ? <EditNodeModal /> : null}
      {isEditEdgeModalOpen ? <EditEdgeModal /> : null}
      {isEditStartNodeModalOpen ? <EditStartNodeModal /> : null}
    </div>
  );
}

export const EditFollowUp = () => (
  <FollowUpPageProvider>
    <EditFollowUpComponent />
  </FollowUpPageProvider>
);
