import React, { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useLongTermTokenMutation } from "../../store/api";
import { Loader } from "../../components/Loader";
import { setOnboarding } from "../../store/slices/onboardingSlice";
import styles from "./CheckMessenger.module.scss";
import { ROUTES } from "../../constants/routes";
import supabase from "../../supabase";

export const CheckMessengerPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const user = useSelector((state) => state.auth.user);
  const step = useSelector((state) => state.onboarding.step);

  const [isLoading, setIsLoading] = useState(false);

  const [longTermToken] = useLongTermTokenMutation();
  const hasRequestedToken = useRef(false);

  const handleError = (
    errorDescription = "No pages found for the user",
    error = "Error",
    assistantId = ""
  ) => {
    navigate(`${ROUTES.channelsPage}?assistantId=${assistantId}`, {
      state: {
        channelTab: "Messenger",
        error,
        errorDescription,
      },
    });
  };

  const saveToken = async (data, assistantId) => {
    try {
      if (!assistantId) {
        throw new Error("Assistant ID is not set");
      }

      // Check if a record exists
      const { data: existingChannel } = await supabase
        .from("channels")
        .select("*")
        .eq("account_id", user.account_id)
        .eq("communication_channel", "Messenger")
        .eq("assistant_id", assistantId)
        .single();

      if (!existingChannel) {
        await supabase.from("channels").insert({
          account_id: user.account_id,
          access_token: data.page_long_lived_token,
          page_id: data.page_id,
          page_description: data.page_description,
          communication_channel: "Messenger",
          assistant_id: assistantId,
          is_on: false,
        });
      }

      if (step === 4) {
        await supabase
          .from("onboardings")
          .update({ step: 5 })
          .match({ account_id: user.account_id });
        dispatch(setOnboarding(5));
      }

      navigate(`${ROUTES.channelsPage}?assistantId=${assistantId}`);
    } catch (error) {
      handleError(error.message, "Error", assistantId);
    }
  };

  const tokenRequest = async () => {
    let assistantId = "";
    try {
      setIsLoading(true);

      const params = new URLSearchParams(window.location.search);
      const code = params.get("code");
      const stateParam = params.get("state");
      const decodedState = decodeURIComponent(stateParam);
      const [stateUserId, assistantIdFromState] = decodedState.split("|");
      assistantId = assistantIdFromState;

      console.log("params", params);

      if (code && user.account_id === stateUserId) {
        if (!assistantId) {
          handleError(
            "Assistant ID not found in state parameter",
            "Error",
            assistantId
          );
          return;
        }

        const { error, data } = await longTermToken({
          code: code,
          //access_token: accessToken,
          //long_lived_token: longLivedToken,
          is_instagram: false,
          assistant_id: assistantId,
        });

        if (error || data.error) {
          handleError(error || data.error, "Error", assistantId);
        } else {
          await saveToken(data, assistantId);
        }
      } else {
        handleError(
          "Invalid access token or state mismatch",
          "Error",
          assistantId
        );
      }
    } catch (error) {
      handleError(error.message, "Error", assistantId);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!hasRequestedToken.current) {
      hasRequestedToken.current = true; // Mark as called
      tokenRequest();
    }
  }, []);

  return (
    isLoading && (
      <div className={styles.loaderContainer}>
        <Loader width={80} height={80} />
      </div>
    )
  );
};
