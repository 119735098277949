import { Button } from "../../../../../../components/Button";
import styles from "./AddNewNode.module.scss";

export function AddNewNode({ onAddNode }) {
  return (
    <div className={styles.container}>
      <Button
        title="Add new node"
        variant="contained"
        color="edit"
        onClick={onAddNode}
      />
    </div>
  );
}
